const crews = [
	{
		Header: 'Crew',
		accessor: 'CrewCode',
		disableFilters: true,
		width: 100
	},
	{
		Header: 'Company Name',
		accessor: 'CompanyName',
		disableFilters: true,
		width: 300
	},
	{
		Header: 'Company Address',
		accessor: 'CompanyAddress',
		disableFilters: true,
		width: 300
	}
];

export default crews;
