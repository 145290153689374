import app from './Realm';

export const activityLogBarData = async (payload={}) => {
	try {
		const data = await app.currentUser.functions.activityLogBarData(payload);
		return data;
	} catch (err) {
		return { error: err };
	}
};

export const activityLogWeeklyBarData = async (payload = {}) => {
	try {
		const data = await app.currentUser.functions.activityLogWeeklyBarData(payload);
		return data;
	} catch (err) {
		return { error: err };
	}
};

export const activityLogRawData = async (payload = {}) => {
	try {
		const data = await app.currentUser.functions.activityLogRawData(payload);
		return data;
	} catch (err) {
		return { error: err };
	}
};
/* 
    FORMAT RESPONSE AS:  { user, types = { type: count } }

    const pipeline = [
	{
		$group: {
			_id: {
				user: '$EditUserID',
				type: '$EditType'
			},
			total: { $sum: 1 }
		}
	},
	{
		$group: {
			_id: '$_id.user',
			types: {
				$push: {
					type: '$_id.type',
					total: '$total'
				}
			}
		}
	},
	{
		$project: {
			_id: 0,
			user: '$_id',
			types: {
				$arrayToObject: [
					{
						$map: {
							input: '$types',
							as: 't',
							in: {
								k: '$$t.type',
								v: '$$t.total'
							}
						}
					}
				]
			}
		}
	}
];

*/



/* 
    FORMAT RESPONSE AS:  { type, users = { user: count } }

const pipeline = [
	{
		$group: {
			_id: {
				user: '$EditUserID',
				type: '$EditType'
			},
			total: {
				$sum: 1
			}
		}
	},
	{
		$group: {
			_id: '$_id.type',
			users: {
				$push: {
					user: '$_id.user',
					total: '$total'
				}
			}
		}
	},
	{
		$project: {
			_id: 0,
			type: '$_id',
			users: {
				$arrayToObject: [
					{
						$map: {
							input: '$users',
							as: 'u',
							in: {
								k: '$$u.user',
								v: '$$u.total'
							}
						}
					}
				]
			}
		}
	}
];

*/