const abbreviations = [
	'AK',
	'AL',
	'AR',
	// 'AS', // American Samoa 
	'AZ',
	'CA',
	'CO',
	'CT',
	// 'DC', // District of Columbia
	'DE',
	'FL',
	// 'FM', // Federated States of Micronesia
	'GA',
	// 'GU', // Guam
	'HI',
	'IA',
	'ID',
	'IL',
	'IN',
	'KS',
	'KY',
	'LA',
	'MA',
	'MD',
	'ME',
	// 'MH', // Marshall Islands
	'MI',
	'MN',
	'MO',
	// 'MP', // Northern Mariana Islands
	'MS',
	'MT',
	'NC',
	'ND',
	'NE',
	'NH',
	'NJ',
	'NM',
	'NV',
	'NY',
	'OH',
	'OK',
	'OR',
	'PA',
	// 'PR', // Puerto Rico
	// 'PW', // Palau
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
    // 'UM', // U.S. Minor Outlying Islands
	'UT',
	'VA',
	// 'VI', // U.S. Virgin Islands
	'VT',
	'WA',
	'WI',
	'WV',
	'WY'
];

export default abbreviations;