import React from 'react'
import {Tooltip, IconButton} from '@material-ui/core'
import {RiFileExcelLine} from 'react-icons/ri'
import {VscFilePdf} from 'react-icons/vsc'
import { downloadXLSX } from '../../../services/xlsx'
import { saveTableAsPDF } from '../../../services/pdf'

export const Pdf = ({docId}) => {
    return (
        <Tooltip title='Download Pdf' onClick={()=>saveTableAsPDF({docId})}>
			<IconButton aria-label='Download Pdf'>
                <VscFilePdf color='#f40f02'/>
			</IconButton>
		</Tooltip>
    )
}


export const Excel = ({data, docId}) => {
    return (
        <Tooltip title='Download Excel' onClick={()=>downloadXLSX(data, docId)}>
            <IconButton aria-label='Download Excel'>
                <RiFileExcelLine color='#1d6f42'/>
            </IconButton>
        </Tooltip>
    )
}
