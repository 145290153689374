import { NativeSelect } from '../components';
import { specializedCapabilities } from '../misc';

const specializedCapabilitiesFields = [
	{
		name: 'specializedCapability',
		label: 'Specialized Capability',
		gridProps: {
			xs: 12
		},
		Input: NativeSelect,
		defaultValue: '',
		options: specializedCapabilities
	}
];

export default specializedCapabilitiesFields;
