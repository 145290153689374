import React, { useState } from 'react';
import { Box, Button, Container, Paper, Typography } from '@material-ui/core';

import Page from '../../components/Page';
import { OutlinedTextField } from '../../components/TextField';
import { SubmitButton } from '../../components/Buttons';

import { resetUserAccount } from '../../services/realm/userAccounts';

const Reset = props => {
	const { history, match } = props;
	const [form, setForm] = useState({});
	const [submitted, setSubmitted] = useState(false);
	const [message, setMessage ] = useState('')
	const [success, setSuccess] = useState(false);

	const handleChange = event => {
		const { name, value } = event.target;
		setForm(curr => ({ ...curr, [`${name}`]: value }));
	};

	const handleReset = async e => {
		e.preventDefault();
		setSubmitted(true);
		let {password, confirmation} = form;
		if (password === confirmation){
			setMessage('');
			let payload = {...match.params, password};
			const response = await resetUserAccount(payload);
			console.log(response)
		if (
			response.status &&
			typeof response.status === 'number' &&
			response.status >= 200 &&
			response.status <= 299
		) {
			setSuccess(true);
		}

		} else {
			setSubmitted(false);
			setMessage('password and confirmation must match')
		}
		

		
		
	};

	const navigateHome = () => {
		history.push('/');
	};

	return (
		<Page>
			<Container
				component='div'
				maxWidth='xs'
				style={{ display: 'flex', margin: 'auto' }}
			>
				<Paper
					align='center'
					justify='center'
					style={{ padding: '24px', margin: 'auto', height: '400px' }}
				>
					{success ? (
						<>
							<Typography component='h1' variant='h5'>
								Password Reset
							</Typography>
							<Box
								style={{ padding: '8px', margin: '24px 4px', height: 'auto' }}
							>
								<Button variant='contained' onClick={navigateHome}>
									Return to Login
								</Button>
							</Box>
						</>
					) : (
						<>
							<Typography component='h1' variant='h5'>
								Reset password
							</Typography>

							<Typography component='p' variant='h6'>
								Enter your new password.
							</Typography>

							<Typography component='p'>
							{message}
							</Typography>

							<Box
								component='form'
								style={{ padding: '8px', margin: '24px 4px', height: 'auto' }}
								onSubmit={handleReset}
								noValidate
							>
								<Box component='div' style={{ margin: '8px auto' }}>
									<OutlinedTextField
										required
										fullWidth
										label='Password'
										name='password'
										autoFocus
										onChange={handleChange}
									/>
								</Box>
								<Box component='div' style={{ margin: '8px auto' }}>
									<OutlinedTextField
										required
										fullWidth
										label='Confirmation'
										name='confirmation'
										onChange={handleChange}
									/>
								</Box>

								<Box component='div' style={{ marginBottom: '4px' }}>
									<SubmitButton
										fullWidth
										disabled={submitted}
										value='Reset Password'
									/>
								</Box>
							</Box>
						</>
					)}
				</Paper>
			</Container>
		</Page>
	);
};

export default Reset;
