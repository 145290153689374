import { parseXML } from '../../../../../utils/xml';

export const TransactionDetails = props => {
	const { value } = props;


	if (typeof value === 'object'){

		if (JSON.stringify(value) === '{}'){
			return <></>
		} 

	} else if ( typeof value === 'string'){
		let jsonData = parseXML(value);
		let obj = JSON.parse(jsonData);

		if ( typeof obj === 'object' ){
			return Object.keys(obj).map((key, index) => {
				return (
					<TransactionDetail
						key={`${key}-${index}`}
						indents={0}
						field={key}
						value={obj[key]}
					/>
				);
			});
		} else {
			return <></>;
		}
	}
	
};

const mp = { margin: '0 0 0 0', padding: '0 0 5px 0' };

const TransactionDetail = props => {
	let { indents, ...details } = props;
	let borderBottom = indents === 0 ? '1px solid #e0e0e0' : 'none';
	let textIndent = `${indents * 10}px`;
	details.style = { borderBottom, ...mp, textIndent };

	const { value } = details;

	if (typeof value === 'string') {
		return <T1 {...details} />;
	}

	if (typeof value === 'object') {
		return <NestedTransactionDetails {...details} indents={indents} />;
	}
};

const NestedTransactionDetails = ({ field, value, indents }) => {
	let borderBottom = indents === 0 ? '1px solid #e0e0e0' : 'none';

	const t1 = { borderBottom, ...mp };
	const t2 = { margin: 0, display: 'inline-block', textIndent: '10px' };
	const t3 = { ...t2, color: '#999' };

	return (
		<p style={t1}>
			<Field>{field}</Field>
			<br />

			{Object.keys(value).map((key, index) => {
				return (
					<>
						<Field style={t2}>{key}</Field>

						{typeof value[key] === 'string' && <span>{value[key]}</span>}
						{typeof value[key] === 'object' &&
							Object.keys(value[key]).map((k, i) => {
								return (
									<>
										<span style={t3} key={`${k}-${i}`}>
											<Field style={t3}>{k}</Field>
											{value[key][k]}
										</span>
									</>
								);
							})}
						<br />
					</>
				);
			})}
		</p>
	);
};

const Field = props => (
	<span style={props.style}>
		<b>{props.children}:</b>&ensp;
	</span>
);

const T1 = ({ field, style, value }) => {
	return (
		<p style={style}>
			<Field>{field}</Field>
			<span>{value}</span>
		</p>
	);
};


export default TransactionDetails;