import { NativeSelect } from '../components';
import { thirdPartySystems } from '../misc';

const thirdPartySystemFields = [
	{
		name: 'thirdPartySystem',
		label: 'Third Party System',
		gridProps: {
			xs: 12
		},
		Input: NativeSelect,
		defaultValue: '',
		options: thirdPartySystems
	}
];

export default thirdPartySystemFields;
