import { NativeSelect } from '../components';
import { militaryBases } from '../misc';

const militaryBaseFields = [
	{
		name: 'militaryBase',
		label: 'Military Base',
		gridProps: {
			xs: 12
		},
		Input: NativeSelect,
		defaultValue: '',
		options: militaryBases
	}
];

export default militaryBaseFields;
