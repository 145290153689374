import { useEffect, useMemo, useState } from 'react';
import { Box, FormControl, InputLabel, NativeSelect } from '@material-ui/core';

const ColumnFilter = ({
	column: { filterValue, preFilteredRows, setFilter }
}) => {
	const count = preFilteredRows.length;

	return (
		<div className='input-group mb-3'>
			<input
				value={filterValue || ''}
				onChange={e => setFilter(e.target.value || undefined)}
				placeholder={`Search ${count} records...`}
			/>
		</div>
	);
};

export const SelectColumnFilter = ({
	column: { filterValue, setFilter, Header, preFilteredRows, id }
}) => {
	const options = useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach(row => {
			options.add(row.values[id]);
		});
		return [...options.values()];
	}, [id, preFilteredRows]);

	return (
		<>
			<Box sx={{ minWidth: 120 }}>
				<FormControl fullWidth>
					<InputLabel variant='standard' htmlFor={`${Header}-select`}>
						{Header}
					</InputLabel>
					<NativeSelect
						value={filterValue}
						onChange={e => {
							setFilter(e.target.value || undefined);
						}}
						inputProps={{
							name: id,
							id: `${Header}-select`
						}}
					>
						<option value=''></option>
						{options.map((option, i) => (
							<option key={i} value={option}>
								{option}
							</option>
						))}
					</NativeSelect>
				</FormControl>
			</Box>
		</>
	);
};

export const MultiSelectColumnFilter = props => {
	const {
		column: { filterValue, setFilter, Header, preFilteredRows, id }
	} = props;

	const [filtered, setFiltered] = useState([]);

	const handleChange = value => {
		let filters = filtered;
		if (filters.includes(value)) {
			let index = filters.indexOf(value);
			filters.splice(index, 1);
			setFiltered(filters);
		} else if ( value === '' || !value.length) {
			setFilter(undefined);
			setFiltered([]);
		} else {
			filters.push(value);
			setFiltered(filters);
		}

		if (filtered.length === 0) {
			setFilter(undefined);
		} else {
			setFilter(filtered);
		}
	};

	const options = useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach(row => {
			options.add(row.values[id]);
		});
		return [...options.values()];
	}, [id, preFilteredRows]);


	useEffect(()=>{
		if(filterValue===undefined && filtered.length>0){
			setFiltered([]);
		}
	},[filterValue, filtered])

	return (
		<>
			<Box>
				<FormControl fullWidth>
					<InputLabel shrink variant='standard' htmlFor={`${Header}-select`}>
						{Header}
					</InputLabel>
					<NativeSelect
						value={filtered}
						onChange={e => handleChange(e.target.value)}
						inputProps={{
							multiple: true,
							name: id,
							id: `${Header}-select`
						}}
					>
						<option value=''></option>
						{options.map((option, i) => (
							<option key={i} value={option}>
								{option}
							</option>
						))}
					</NativeSelect>
				</FormControl>
			</Box>
		</>
	);
};

export default ColumnFilter;
