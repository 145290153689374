import React, { useState, useEffect } from "react";
import {
  getAllUsers,
  updateUserPassword,
} from "../../services/realm/employees";
import { Select, Button, TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

// const defaultValue = { _id: "", UserID: "" };

const AdminCrewPasswordReset = () => {
  // console.log('Getting users!!', getAllUsers())

  const [usersList, setUsersList] = useState(null);
  const [state, setState] = useState({
    id: "",
    newPassword: "",
  });

  const handleChange = (e, id) => {
    setState({
      ...state,
      id: e.target.value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const allUsers = await getAllUsers();
      // console.log(allUsers[0]);
      setUsersList(allUsers);
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      id: state.id,
      newPassword: state.newPassword,
    };

    const succes = await updateUserPassword(payload);
    if (!succes.error) {
      setState({ ...state, succesful: true });
    }
  };

  const handlePassword = (e) => {
    let password = e.target.value;
    setState({ ...state, newPassword: password });
  };

  return (
    <>
      <h2>Change Crew Password</h2>
      {usersList ? (
        <>
          <div>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "space-around",
                margin: "5px",
              }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Select
                defaultValue={null}
                value={state.id}
                onChange={(e) => handleChange(e)}

              >
                <option
                    value={null}
                >
                    Select a user
                </option>
                {usersList.map((eachUser, index) => (
                  // <User
                  // eachUser={eachUser}
                  // key={index}
                  // />

                  <option
                    // onClick={}
                    id={eachUser._id}
                    key={index}
                    value={`${eachUser._id}`}
                  >
                    {`${eachUser.UserID}`}
                  </option>
                ))}
              </Select>
              <TextField
                type="password"
                id="textField"
                onChange={(e) => handlePassword(e)}
                value={state.newPassword}
              ></TextField>

              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Reset Password
              </Button>
            </form>
            {state.succesful ? <p>Password Saved</p> : <></>}
          </div>
        </>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </>
  );
};

export default AdminCrewPasswordReset;
