import { useEffect, useState } from 'react';
import { trackUploads } from '../../../services/realm/vendors';
import { uploadWOFile } from '../../../services/frApi/';
import { useUser } from '../../../context/user';
import {
	FlexContainer,
	UploadStatus,
	UploadButton,
	UploadInput
} from '../../../components';

const mb = 1048576;
const maxFileSize = 5 * mb;

const acceptedMimeTypes = [
	'image/jpeg',
	'image/png',
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];


export const UploadFile = props => {
	let { original, updateData } = props;
	let { smiWO, storeName, uploads, lastUpload } = original;
	// console.log('outside:',props)
	const { currentUser } = useUser();
	const { entityRecId, RecId, userName, CrewCode } = currentUser;
	// console.log(currentUser)

	const [uploadState, setUploadState] = useState({
		selected: false,
		submitting: false,
		submitted: false,
		updated: false,
		failed: false,
		file: {},
		uploadError: '',
		message: ''
	});

	const [lastFile, setLastFile] = useState('');

	useEffect(() => {
		if (lastUpload) {
			setLastFile(lastUpload);
		}
	}, [lastUpload]);


	const handleUploadChangeEvent = event => {
		const { name, files } = event.target;
		const file = files[0];

		if (file.size > maxFileSize) {
			setUploadState({
				...uploadState,
				uploadError: 'File must be smaller than 5MB.'
			});
			return false;
		}

		if (!acceptedMimeTypes.includes(file.type)) {
			setUploadState({
				...uploadState,
				uploadError: 'File type not supported.'
			});
			return false;
		}

		if (/\.[A-Z]+$/.test(file.name)) {
			let ext = file.name.match(/\.[A-Z]+$/)[0];
			setUploadState({
				...uploadState,
				uploadError: `File extension must be lower cased. Please change '${ext}' to '${ext.toLowerCase()}' and try again.`
			});
			return false;
		}

		setUploadState({ ...uploadState, [name]: file });
	};

	const begin = { submitting: true, failed: false };
	const success = { submitting: false, submitted: true };
	const error = { submitting: false, failed: true };

	const handleTrackerPayload = (VendorCode, SMI_WO, file, message, uploads) => {
		let payload = { query: { VendorCode, SMI_WO } };
		let upload = { file: file.name, message };

		if (lastUpload) {
			payload.changes = { '$push': { uploads: upload } };
			
		} else {
			payload.setting = { uploads: [upload] };
		}

		original.lastUpload = file.name;
		updateData(original);
		return payload;
	};

	const handleUploadFile = async e => {
		e.preventDefault();

		setUploadState({ ...uploadState, ...begin });

		const file = e.target[0].files[0];

		const data = await uploadWOFile(
			file,
			smiWO,
			CrewCode,
			RecId,
			entityRecId,
			userName,
			'workorderDocument', //DocType
			storeName
		);

		if (data) {
			setUploadState({ ...uploadState, ...success, message: data });

			let payload = handleTrackerPayload(
				CrewCode,
				smiWO,
				file,
				data,
				uploads
			);
			trackUploads(payload);
		} else {
			setUploadState({
				...uploadState,
				...error,
				message: 'Error uploading file'
			});
		}
	};


	return (
		<>
			<FlexContainer row flexWrap='nowrap' justifyContent='space-evenly'>
				<FlexContainer
					col
					flexWrap='nowrap'
					justifyContent='space-around'
					minWidth='200px'
				>
					<div>{lastFile !== '' && 'Last Upload ' + lastFile}</div>
					<form
						onSubmit={e => handleUploadFile(e)}
						style={{
							display: 'flex',
							flexDirection: 'column',
							flexWrap: 'nowrap',
							alignItems: 'center',
							justifyContent: 'space-around',
							margin: '30px'
						}}
						name='UploadDoc'
					>
						<UploadInput
							id={`file-upload-button-${smiWO}`}
							onChange={handleUploadChangeEvent}
						/>

						<UploadButton {...uploadState} />
					</form>
					<UploadStatus {...uploadState} />
				</FlexContainer>
			</FlexContainer>
		</>
	);
};
