export const bases = [
	'',
	'Andrews AFB',
	'Brooks City Base',
	'Cannon AFB',
	'Clovis',
	'Fort Belbeir',
	'Fort Campbell',
	'Fort Chatfee',
	'Fort DeRussy',
	'Fort Drum',
	'Fort Hamilton Army Base',
	'Fort Knox',
	'Fort Lewis',
	'Fort Meade',
	'Fort Pendelton',
	'Fort Sam',
	'Fort Sam Houston',
	'Fort Shafter',
	'Fort Tinker',
	'Hickham AFB',
	'JBSA - Randolph',
	'JBSA - Lackland',
	'Kilauea Military Camp',
	'Lackland, TX',
	'Langley AFB',
	'MacDill AFB',
	'McConnell',
	'McCord AFB',
	'Mead AFB',
	'NASA',
	'Navy Yard',
	'NSB New London',
	'Parris Island MCRD',
	'Patrick AFB',
	'Pohakuloa Training Center',
	'Randolph',
	'USCG Academy'
];

export default bases;
