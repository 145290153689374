import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const Title = props => {
	const { children, ...other } = props;

	return (
		<Typography 
			{...other } 
			component='h2' 
			variant='h6' 
			color='primary' 
			gutterBottom
		>
			{props.children}
		</Typography>
	);
}

Title.propTypes = {
	children: PropTypes.node
};

export const SubTitle = props => {
	return(
		<Typography variant='caption' color='textPrimary' gutterBottom>
			{props.children}
		</Typography>
	)
}

export const H1 = props => {
	const {children, ...other} = props;
	return(
		<Typography {...other} component='h1'>
			{children}
		</Typography>
	)
}

export default Title