import React from 'react'
import {Tooltip, IconButton, Modal, Box} from '@material-ui/core'
import UpdateIcon from '@material-ui/icons/Update';
import { useState } from 'react';
import UpdateWOSchedule from '../../../pages/employee/UpdateWOSchedule'


const WOScheduleDateUpdate = (props) => {
    const [viewModal, setViewModal] = useState(false);
    const test = () => {
        setViewModal(true)
    }

    const deselectRow = () => {
        setViewModal(false)
    }

    
const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'clamp(200px, 60% ,660px)',
	height: 'clamp(200px, 60% ,500px)',
	backgroundColor: '#fff',
	borderRadius: '10px',
	boxShadow: '0px 0px 10px #000',
	padding: '10px'
};
    
    
    return (
        <>
        <Tooltip title='Shedule Date Update' onClick={()=>test()}>
            <IconButton aria-label='Shedule Date Update'>
                <UpdateIcon/>
            </IconButton>
        </Tooltip>
        <Modal open={viewModal} onClose={() => deselectRow()}>
            <Box 
            
            style={modalStyle}
            >
                <UpdateWOSchedule {...props}/>

            </Box>

        </Modal>
    
        </>
        
    )
}

export {WOScheduleDateUpdate}

