// import { useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { FilterListOutlined } from '@material-ui/icons';
import { ContainedButton } from '../../Buttons';

import {
	Grid,
	Paper,
	FormControl,
	FormGroup,
	FormLabel
} from '@material-ui/core';

const Filters = props => {
	const {setFiltersOpen} = props;
	// const [anchor, setAnchor] = useState(null);
	// const open = Boolean(anchor);

	return (
		<>
			<Tooltip title='Filter List' onClick={e => setFiltersOpen(curr => !curr)}>
				<IconButton aria-label='Filter List'>
					<FilterListOutlined />
				</IconButton>
			</Tooltip>
			{/* <Popover
				open={open}
				anchorEl={anchor}
				onClose={() => setAnchor(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<CustomFilters {...props} />
			</Popover> */}
		</>
	);
};

export default Filters;

export const ColumnFilters = props => {
	const { allColumns, setAllFilters } = props;

	return (
		<Paper elevation={0}>
			<FormControl component='fieldset' variant='standard'>
				<Grid container>
					<Grid item xs={4}>
						<FormLabel component='legend' style={{ textAlign: 'center' }}>
							Filters
						</FormLabel>
					</Grid>
					<Grid item xs={4} />
					<Grid item xs={4}>
						<ContainedButton
							color='primary'
							size='small'
							style={{ position: 'relative', right: '0', padding: '0' }}
							onClick={() => {
								setAllFilters([]);
							}}
						>
							Reset Filters
						</ContainedButton>
					</Grid>
				</Grid>
				<FormGroup>
					<Grid container style={{ margin: '10px 2px' }}>
						{allColumns.map(column => {
							const { canFilter} = column;
							if (canFilter) {
								return (
									<Grid item style={{ margin: '2px'}}>
										{column.render('Filter')}
									</Grid>
								);
							} else {
								return '';
							}
						})}
					</Grid>
				</FormGroup>
			</FormControl>
		</Paper>
	);
};

export const CustomFilters = props => {
	const { allColumns, setAllFilters } = props;

	return (
		<Paper
			elevation={0}
			style={{
				width: '200px',
				height: 'auto',
				margin: '10px 20px 10px 10px',
				display: 'grid',
				gridTemplateColumns: '1fr',
				position: 'relative',
				zIndex: 10
			}}
		>
			<ContainedButton
				color='primary'
				size='small'
				style={{
					position: 'absolute',
					right: '0',
					top: '0',
					zIndex: 10,
					padding: '0'
				}}
				onClick={() => {
					setAllFilters([]);
				}}
			>
				Reset
			</ContainedButton>
			<FormControl
				component='fieldset'
				variant='standard'
				style={{ display: 'flex', justifyContent: 'space-between' }}
			>
				<FormLabel component='legend' style={{ textAlign: 'center' }}>
					Filters
				</FormLabel>

				<FormGroup>
					{allColumns.map(column => {
						const { canFilter } = column;
						if (canFilter) {
							return column.render('Filter');
						} else {
							return '';
						}
					})}
				</FormGroup>
			</FormControl>
		</Paper>
	);
};
