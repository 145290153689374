import { useState } from 'react';
import { IconButton, Tooltip, Popover } from '@material-ui/core';
import { ViewColumnOutlined } from '@material-ui/icons';
import VisibilitySwitch from '../../switches/Visibility';
import {
    Paper,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
} from '@material-ui/core';

const Columns = props => {
	const [anchor, setAnchor] = useState(null);
	const open = Boolean(anchor);

	return (
		<>
			<Tooltip title='View Columns' onClick={e => setAnchor(e.currentTarget)}>
				<IconButton aria-label='View Columns'>
					<ViewColumnOutlined />
				</IconButton>
			</Tooltip>
			<Popover
				open={open}
				anchorEl={anchor}
				onClose={() => setAnchor(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<SwitchesGroup {...props}/>
			</Popover>
		</>
	);
};

export default Columns;

const SwitchesGroup = props => {
    const { allColumns, neverShowColumns } = props;

	return (
        <Paper
            elevation={0}
            style={{
                width: '150px',
                height: 'auto',
                margin: '10px 20px 10px 10px',
                display: 'grid',
                gridTemplateColumns: '1fr',
                position: 'relative',
                zIndex: 10,
            }}
        >
            <FormControl component='fieldset' variant='standard' >
                <FormLabel component='legend' style={{textAlign: 'center'}}>Columns</FormLabel>
                <FormGroup >
                    { allColumns.map( ({ id, Header, getToggleHiddenProps }) => {
                        if ( !neverShowColumns.includes(id) ){
                            return <FormControlLabel
                                key={id}
                                style={{display: 'flex', justifyContent: 'space-between'}}
                                control={
                                    <VisibilitySwitch
                                        getToggleHiddenProps={getToggleHiddenProps}
                                        name={id}
                                    />
                                }
                                label={Header}
                                labelPlacement='start'
                            />
                        }
                        return ""
                    })}
                </FormGroup>
            </FormControl>
        </Paper>
	);
};
