import React, { useState } from 'react';
import {
	Avatar,
	Box,
	Checkbox,
	Container,
	FormControlLabel,
	Link,
	Paper,
	Typography
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Page from '../../components/Page';
import { OutlinedTextField } from '../../components/TextField';
import { SubmitButton } from '../../components/Buttons';
import { loginCustomFunction } from '../../services/realm/Realm';
import { useUser } from '../../context/user';


const Login = props => {
	const { logIn } = useUser();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [warningMsg, setWarningMsg] = useState(null);

	const handleLogIn = async e => {
		e.preventDefault();
		if (!username || !password) {
			if (!username && password) {
				setWarningMsg('Username is required');
			} else if (username && !password) {
				setWarningMsg('Password is required');
			} else {
				setWarningMsg('Username and password are required');
			}
		} else {
			setWarningMsg(null);
			const { user, error } = await loginCustomFunction({ username, password });
			if (!error) {
				// console.log(user);
				// setCurrentUser(user);
				// setLogged(true);
				logIn(user)
			
			} else {
				setWarningMsg(error);
			}
			return true
		}
	};

	return (
		<Page>
			<Container
				component='div'
				maxWidth='xs'
				style={{ display: 'flex', margin: 'auto' }}
			>
				<Paper
					align='center'
					justify='center'
					style={{ padding: '24px', margin: 'auto', height: '520px' }}
				>
					<Avatar style={{ margin: '16px auto' }}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component='h1' variant='h5'>
						Sign in
					</Typography>
					{warningMsg !== null && (
						<Typography component='h1' variant='body1' color='error'>
							* {warningMsg} *
						</Typography>
					)}
					<Box
						component='form'
						style={{ padding: '8px', margin: '24px 4px', height: 'auto' }}
						onSubmit={e => handleLogIn(e)}
						noValidate
					>
						<Box component='div' style={{ margin: '8px auto' }}>
							<OutlinedTextField
								required
								fullWidth
								id='username'
								label='Username'
								name='username'
								autoComplete='username'
								autoFocus
								onChange={e => setUsername(e.target.value)}
							/>
							<OutlinedTextField
								required
								fullWidth
								id='password'
								label='Password'
								name='password'
								type='password'
								autoComplete='current-password'
								onChange={e => setPassword(e.target.value)}
							/>
						</Box>

						<Box component='div' style={{ margin: '8px auto' }}>
							<FormControlLabel
								control={<Checkbox value='remember' color='primary' />}
								label='Remember me'
							/>
						</Box>

						<Box component='div' style={{ marginBottom: '4px' }}>
							<SubmitButton fullWidth value='Sign In' />
						</Box>

						<Box 
							display='flex' 
							justifyContent='end' 
							style={{ margin: '16px auto' }}
						>

							<Link 
								href='#/recover' 
								variant='body2'
							>
								Forgot password?
							</Link>

						</Box>

					</Box>
				</Paper>
			</Container>
		</Page>
	);
};

export default Login;
