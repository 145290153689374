export const types = [
	'',
	'Administration',
	'AR/AP',
	'Controller',
	'Emergency',
	'General Manager',
	'Manager',
	'Office Manager',
	'Operations',
	'Owner',
	'President',
	'Project Manager',
	'Quality Control',
	'SendInvoiceTo',
	'Vice President'
];

export const methods = [
	{ value: 0, label: '' },
	{ value: 1, label: 'Phone' },
	{ value: 2, label: 'Fax' },
	{ value: 3, label: 'Email' },
	{ value: 4, label: 'Mail' }
];

export default methods;
