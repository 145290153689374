export const specialized = [
	'',
	'Burnishing Floors',
	'Carpentry',
	'Ceilings',
	'Column Repairs',
	'Disinfectant Cleaning',
	'Electrical',
	'Electrostatic Fogging',
	'Epoxy & Polished concrete systems',
	'Equipment Setup & Monitoring',
	'Fence',
	'Flooring Repairs',
	'Gates',
	'General Repairs',
	'Graffiti Removal',
	'Hi-Rise Window Cleaning (above 5 floors)',
	'Hi-Rise Window Cleaning (below 5 floors)',
	'Landscaping',
	'Locks',
	'Painting',
	'Photos',
	'Plumbing',
	'Sewage / Drainage',
	'Snow Removal',
	'Storm Prep - Board Up',
	'Storm Prep - Sand Bags',
	'ULV Fogging',
	'Upholstery',
	'Video',
	'Wall Repairs'
];

export default specialized;
