import { FormControl, InputLabel, Select } from '@material-ui/core';

export const NativeSelect = props => {
	let {
		formatControlProps,
		labelProps,
		selectProps,
		onChange,
		error,
		id,
		label,
		name,
		options,
		defaultValue,
		InputProps,
		value,
		disabled
	} = props;

	if (InputProps && InputProps.readOnly) {
		disabled = InputProps.readOnly;
	}
	return (
		<FormControl {...formatControlProps}>
			<InputLabel {...labelProps} shrink htmlFor={id}>
				{label}
			</InputLabel>
			<Select
				{...selectProps}
				native
				id={id}
				name={name}
				defaultValue={defaultValue}
				value={value}
				onChange={onChange}
				error={error}
				disabled={disabled}
			>
				{options.map((opt, index) => {
					return (
						<>
							{typeof opt === 'object' && (
								<option id={opt.id} key={`${id}-${opt.id}`} value={opt.value}>
									{opt.label}
								</option>
							)}
							{typeof opt === 'string' && <option value={opt}>{opt}</option>}
						</>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default NativeSelect;
