import * as Realm from 'realm-web';

export const app = new Realm.App({ id: process.env.REACT_APP_REALM_API_ID });
//---------------------------------- Login -----------------------------------//

//used to authenticate current user.
export const loginCustomFunction = async payload => {
	const credentials = await Realm.Credentials.function(payload);
	try {
		const { id } = await app.logIn(credentials);
		if (id === app.currentUser.id) {
			const { user, error } = await getUserDetails(payload.username);
			if (!error) {
				return { user: user };
			} else {
				throw Error(error);
			}
		}
	} catch (err) {
		return { error: 'The username or password is incorrect' };
	}
};

//used to retrieve relevant user details.
export const getUserDetails = async username => {
	try {
		const { user } = await app.currentUser.functions.getUser({ username });
		return { user };
	} catch (err) {
		return { error: 'No match found.' };
	}
};

export const uploadFile = async file => {
	try {
		const  data  = await app.currentUser.functions.uploadFile(file);
		return { data };
	} catch (err) {
		return { error: 'No match found.' };
	} 
};

export const clearUserDetails = () => {
	app.currentUser.logOut();
}

export default app;
