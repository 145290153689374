// eslint-disable-next-line
import { useState } from 'react';
import Routes from './router/Routes';

const App = props => {
	const [vendor, setVendor] = useState({});

	return (
		<div className='App'>
			<Routes
				vendor={vendor}
				setVendor={setVendor}
			/>
		</div>
	);
};

export default App;
