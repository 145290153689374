import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../../context/user';
import { getWorkOrderPDF } from '../../../services/frApi/fetch';
const text = { fontSize: '12px' };

const err = {
	fontSize: '11px',
	color: '#ED4337'
};

const OpensPDF = props => {
	const [ errorMsg, setError] = useState(null);
	const { row: { original: { custWO, smiWO } } } = props;
	const { currentUser } = useUser();

	const handleClick = async () => {
		const response = await getWorkOrderPDF(currentUser.CrewCode, smiWO);
		if (response.error){
			setError(response.error);
		} else {
			setError(null);
		}
	}

	return (
		<>
			<span style={text}>{custWO}</span>
			<br />
			<Link style={text} to='#' onClick={handleClick} >
				{smiWO}
			</Link>
			<br/>
			{ errorMsg && <b style={err}>{errorMsg}</b>}
		</>
	);
};

export default OpensPDF;
