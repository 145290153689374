// eslint-disable-next-line
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel
} from '@material-ui/core';

export const SortableTable = props => {
	const {
		canSortColumns, //true false
		getTableProps,
		selectRow,
		headerGroups,
		page,
		prepareRow,
		docId
	} = props;

	return (
		<TableContainer
			style={{
				height: '70%',
				maxHeight: 800,
				borderRadius: '4px',
				boxShadow:
					'0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
			}}
		>
			<Table {...getTableProps()} stickyHeader id={docId}>
				<TableHead>
					{headerGroups.map(headerGroup => (
						<TableRow {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, index) => (
								<TableCell
									key={`${column.id}-${index}`}
									{...column.getHeaderProps(column.getSortByToggleProps())}
									style={{
										border: 'none',
										margin: '0',
										padding: '0',
										height: '100%',
										textAlign: 'center',
										minWidth: column.minWidth,
										width: column.width,
										maxWidth: column.maxWidth,
									}}
								>
									<div style={column.headerStyle}>{column.render('Header')}</div>
									{canSortColumns && (
										<TableSortLabel
											active={column.isSorted}
											direction={column.isSortedDesc ? 'desc' : 'asc'}
										/>
									)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableHead>
				<TableBody>
					{page.map((row, i) => {
						// row.currentUser = currentUser;
						prepareRow(row);
						return (
							<TableRow {...row.getRowProps()} hover>
								{row.cells.map((cell, index) => {
									return (
										<TableCell
										key={`${cell.column.id}-${index}`}
											{...cell.getCellProps()}
											component='th'
											padding='none'
											style={{
												minWidth: cell.column.minWidth,
												width: cell.column.width,
												maxWidth: cell.column.maxWidth,
												textAlign: cell.column.textAlign
												// border: '1px solid #e0e0e0',
											}}
											onMouseEnter={e => {
												e.target.style.cursor = 'pointer';
											}}
											onMouseLeave={e => {
												e.target.style.cursor = 'default';
											}}
											onClick={() => {
												if (
													typeof selectRow === 'function' &&
													cell.column.id !== 'custWO' &&
													cell.column.id !== 'form'
												) {
													return selectRow(row);
												}
												return;
											}}
										>
											{cell.render('Cell')}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const BaseTable = props => {
	const { getTableProps, headerGroups, rows, prepareRow } = props;
	return (
		<Table {...getTableProps()}>
			<TableHead>
				{headerGroups.map(headerGroup => (
					<TableRow {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => {
							return (
								<TableCell {...column.getHeaderProps()}>
									<div style={{ width: column.width }}>
										{column.render('Header')}
									</div>
								</TableCell>
							);
						})}
					</TableRow>
				))}
			</TableHead>
			<TableBody>
				{rows.map((row, i) => {
					prepareRow(row);
					return (
						<TableRow {...row.getRowProps()}>
							{row.cells.map(cell => {
								return (
									<TableCell {...cell.getCellProps()}>
										{cell.render('Cell')}
									</TableCell>
								);
							})}
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
};

export default BaseTable;
