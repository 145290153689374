//------------------------------- Dependencies -------------------------------//
import { useState, useEffect } from 'react';

//-------------------------------- Components --------------------------------//
import Dashboard from '../../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../../components/dashboard/Dashboard.style.js';
import { PaddingAngle } from '../../../components/charts/pie';
import ToDoList from '../../../components/dashboard/ToDoList';


const CrewMainPage = props => {
	//----------------------------- User Content -----------------------------//
	const {
		handleNeedSchedule,
		handlePastDue,
		handlePieData,
	} = props;

	//-------------------------- To Do List Content --------------------------//

	useEffect(() => {
		handleNeedSchedule();
	}, [handleNeedSchedule]);

	useEffect(() => {
		handlePastDue();
	}, [handlePastDue]);

	//-------------------------- Pie Chart Content ---------------------------//
	const pieTitle = 'Open Work Orders by Service Type';

	useEffect(() => {
		handlePieData();
	}, [handlePieData]);

	//---------------------------- Error Handling ----------------------------//
	// eslint-disable-next-line
	const [warningMsg, setWarningMsg] = useState(null);

	//------------------------------ UI Context ------------------------------//
	const classes = dashboardStyles();

	return (
		<>
			<Dashboard
				{...props}
				classes={classes}
				warningMsg={warningMsg}
				
			>
				<PaddingAngle
					{...props}
					width={400}
					height={400}
					pieTitle={pieTitle}
					color='blue'
				/>
				<ToDoList {...props} />
			</Dashboard>
		</>
	);
};

export default CrewMainPage;
