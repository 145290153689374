export const licenses = [
	'',
	'AMRT',
	'ASD',
	'CSLB',
	'FSRT',
	'IICRC',
	'IWCA',
	'OCT',
	'OSHA',
	'RRT',
	'WRT'
];

export default licenses;
