//------------------------------- Dependencies -------------------------------//
import { useEffect, useMemo } from 'react';

//-------------------------------- Components --------------------------------//
import Dashboard from '../../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../../components/dashboard/Dashboard.style';
import config from '../../../components/tables/config';
import Table from '../../../components/tables/Table';

const RequestForPrice = props => {
	const { tableData, tableCols, handleRFPs, tableId} = props;
	const columns = useMemo(() => tableCols, [tableCols]);
	const table = config({ columns, data: tableData });

	useEffect(() => {
		handleRFPs();
	}, [handleRFPs]);

	//------------------------------ UI Context ------------------------------//
	const classes = dashboardStyles();

	return (
		<>
			<Dashboard
				{...props}
				classes={classes}
				fullPage={true}
			>
				<Table {...table} docId={tableId} />
			</Dashboard>
		</>
	);
};

export default RequestForPrice;
