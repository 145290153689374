import { TablePagination } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PaginationActions from './PaginationActions';

const Pagination = props => {
	const {
		gotoPage,
		rows,
		setPageSize,
		state: { pageIndex, pageSize }
	} = props;

	const theme = useTheme();

	const handleChangePage = (event, newPage) => {
		gotoPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setPageSize(Number(event.target.value));
	};

	return (
		<TablePagination
			rowsPerPageOptions={[25, 50, 100, 250]}
			style={{ paddingTop: theme.spacing(2.5) }}
			component='div'
			colSpan={3}
			count={rows.length}
			rowsPerPage={pageSize}
			page={pageIndex}
			SelectProps={{
				inputProps: { 'aria-label': 'rows per page' },
				native: true
			}}
			onPageChange={handleChangePage}
			onRowsPerPageChange={handleChangeRowsPerPage}
			ActionsComponent={PaginationActions}
		/>
	);
};

export default Pagination;
