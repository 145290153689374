import { useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { SubmitButton } from '../../../components/Buttons';
import Header from '../../../components/forms/Header';
import { FullLine, HalfLine } from '../../../components/forms/TextFields';
import { usernameValidation } from '../../../services/realm/restrictedCrew';

const GeneralInfoForm = props => {
	const {onSubmit, warningMsg, setWarningMsg} = props;
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [username, setUsername] = useState('');
	const [unique, setUnique] = useState(null);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');

	const handleSignUp = async e => {
		e.preventDefault();
		if (password !== passwordConfirm) {
			setWarningMsg('Passwords do not match');
		} 
		else if(unique) {
			const obj = {
				FirstName: fname,
				LastName: lname,
				UserID: username,
				Password: password
			};
			onSubmit(obj);
		}
	};

	const handleUsername = async e => {
		const valid = await usernameValidation(e.target.value);
		setUnique(valid);
		valid? setWarningMsg(null) : setWarningMsg('Username is already taken');
	}

	return (
		<Paper
			align='center'
			justify='center'
			style={{ padding: '24px', margin: 'auto', height: '50vh' }}
		>
			<Grid
				container
				spacing={2}
				direction='column'
				alignItems='center'
				justify='center'
				style={{ height: '100%' }}
			>
				<Header title='General Information' warningMsg={warningMsg} />
				<Grid
					xs={10}
					item
					container
					direction='column'
					alignItems='center'
					justifyContent='space-around'
					component='form'
					onSubmit={e => handleSignUp(e)}
				>
					<Grid container spacing={1}>
						<HalfLine
							inputProps={{ minLength: 2 }}
							required
							label='First Name'
							onChange={e => setFname(e.target.value)}
							value={fname}
						/>
						<HalfLine
							inputProps={{ minLength: 2 }}
							required
							label='Last Name'
							onChange={e => setLname(e.target.value)}
							value={lname}
						/>
						<FullLine
							required
							label='Email'
							type='email'
							onChange={e => setEmail(e.target.value)}
							value={email}
						/>
						<FullLine
							inputProps={{ minLength: 6 }}
							required
							label='Username'
							type='username'
							onChange={e => setUsername(e.target.value)}
							onBlur={e => handleUsername(e)}
							value={username}
						/>
						<HalfLine
							inputProps={{ minLength: 6 }}
							required
							label='Password'
							type='password'
							onChange={e => setPassword(e.target.value)}
							value={password}
						/>
						<HalfLine
							required
							label='Confirmation'
							type='password'
							onChange={e => setPasswordConfirm(e.target.value)}
							value={passwordConfirm}
						/>
					</Grid>
					<Grid item>
						<SubmitButton fullWidth value='Submit' />
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default GeneralInfoForm;
