export const civilianTime = time => {
	let re = /^(\d{2}):(\d{2})$/;
	if (typeof time === 'string' && re.test(time)) {
		let [hh, mm] = time.split(':');
		if (parseInt(hh) > 12) {
			hh = parseInt(hh) - 12;
			return `${hh}:${mm} PM`;
		} else {
			return `${parseInt(hh)}:${mm} AM`;
		}
	} else {
		return '';
	}
};

// const usCurr = /^\$(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$/gm

export const formatCurrency = amount => {
	if (typeof amount === 'string') {
		
		if (amount.split('')[0] === '$') {
			return amount;
		} else {
			const { format } = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				currencyDisplay: 'narrowSymbol'
			});

			return format(amount);
		}
	} else {
		return '';
	}
};
