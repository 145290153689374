import { NativeSelect } from '../components';
import { tradeLicenses } from '../misc';

const tradeLicenseFields = [
	{
		name: 'tradeLicense',
		label: 'Trade License',
		gridProps: {
			xs: 12
		},
		Input: NativeSelect,
		defaultValue: '',
		options: tradeLicenses
	}
];

export default tradeLicenseFields;
