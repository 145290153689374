import React from 'react';

import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';

let dWidth = 500;
let dMargin = { top: 20, right: 30, left: 20, bottom: 5 };

const Vertical = props => {
	let {
		data,
		width = dWidth,
		height = (width * .60),
		margin = dMargin,
		xAxisProps,
		yAxises,
		bars
	} = props;
	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				borderRadius: '5px',
				boxShadow: '1px 2px 5px #CCCCCC80'
			}}
		>
			<ResponsiveContainer width={width} height={height}>
				<BarChart width={width} height={height} data={data} margin={margin}>
					<CartesianGrid strokeDasharray='10 10' />
					<XAxis {...xAxisProps}/>
					{yAxises && yAxises.map(yAxis => <YAxis {...yAxis} />)}
					<Tooltip />
					<Legend verticalAlign='top' height={48} filterNull={true} />
					{bars && bars.map(bar => <Bar {...bar} />)}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default Vertical;
