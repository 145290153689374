import { useState } from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Page';
import GeneralInfoForm from './GeneralInfoForm';
import { userUpdate } from '../../../services/realm/restrictedCrew';

import { useUser } from '../../../context/user';

const General = props => {
	const { setGeneralInfo, history } = props;

	const { currentUser, updateUser } = useUser();
	const { id } = currentUser;
	const [warningMsg, setWarningMsg] = useState(null);

	const onSubmit = async obj => {
		obj['required.generalInfo'] = true;

		const { data } = await userUpdate({ id, changes: obj });
		if (data) {
			console.log('userUpdate:', data);
			setWarningMsg(null);

			updateUser(data);
			setGeneralInfo(data.required.generalInfo);
			history.push('/registration/companyInfo');
		} else {
			setWarningMsg('Error submitting form');
		}
	};

	return (
		<Page>
			<Container
				component='div'
				style={{ display: 'flex', margin: 'auto', width: '600px' }}
			>
				<GeneralInfoForm
					{...props}
					warningMsg={warningMsg}
					setWarningMsg={setWarningMsg}
					onSubmit={onSubmit}
				/>
			</Container>
		</Page>
	);
};

export default General;
