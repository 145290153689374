import {
	Button,
	Grid,
} from '@material-ui/core';


export const TextButton = ({ children, ...other }) => {
	return (
		<Button {...other} variant='text' size='small' color='secondary'>
			<b>{children}</b>
		</Button>
	);
}

export const EditButton = props => {
	const { onClick, ...other } = props;

	const handleClick = () => {
		onClick(clicked => !clicked);
	};

	return (
		<TextButton {...other} onClick={handleClick}>
			Edit
		</TextButton>
	);
};

export const SubmitButton = props => {
	const { onClick, ...other } = props;

	return (
		<>
			<Grid item xs={10}></Grid>
			<Grid item xs={2}>
				<Button
					{...other}
					type='submit'
					variant='text'
					size='small'
					color='secondary'
				>
					<b>Submit</b>
				</Button>
			</Grid>
		</>
	);
};
