import { Button } from '@material-ui/core';
import { Send } from '@material-ui/icons';

import { Message } from '../index';

const UploadButton = uploadState => {
	const { name } = uploadState.file;
	return (
		<>
			{name && (
				<>
					<Message type='waiting' marginTop='5px'>
						{name.length > 16 ? name.slice(0, 16) + '...' : name}
					</Message>
					<div>
						<Button
							type='submit'
							variant='contained'
							size='small'
							color='primary'
							style={{ marginTop: '10px' }}
							endIcon={<Send />}
						>
							Upload
						</Button>
					</div>
				</>
			)}
		</>
	);
};

export default UploadButton;
