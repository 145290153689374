const Container = props => {
	const { children, ...style } = props;
	return <div style={style}>{props.children}</div>;
};

export const FlexContainer = props => {
	const { children, row, col, ...other } = props;
	let flexDirection = 'row';

	if (row) {
		flexDirection = 'row';
	}
	if (col) {
		flexDirection = 'column';
	}

	return (
		<Container {...other} display='flex' flexDirection={flexDirection}>
			{children}
		</Container>
	);
};

export default Container;
