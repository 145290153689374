import * as React from 'react';
import { styled } from '@material-ui/styles';
import { Switch } from '@material-ui/core';

import { VisibilityOffOutlined,VisibilityOutlined } from '@material-ui/icons'

const thumb = { width: 16, height: 16 };

const Base = styled( props => (
	<Switch 
		focusVisibleClassName='.Mui-focusVisible' 
		disableRipple 
		{...props}
		{...props.getToggleHiddenProps()}
		icon={<VisibilityOffOutlined style={thumb}/>  }
		checkedIcon={<VisibilityOutlined style={thumb}/> } 
	/>
))(({ theme }) => ({
	width: 36,
	height: 20,
	padding: 0,
	margin: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 3,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: '#3070a8',
				opacity: 1,
				border: 0
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5
			}
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff'
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color: theme.palette.grey[200]
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: 0.7
		}
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 16,
		height: 16
	},
	'& .MuiSwitch-track': {
		width: 36,
		height: 20,
		borderRadius: 26 / 2,
		backgroundColor: '#d1d3d5',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500
		})
	}
}));

export default Base
