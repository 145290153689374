export const national = [
	'',
	'AFM',
	'All American Maintenance',
	'American Home Shield',
	'Building Maintenance Svcs',
	'Clean System',
	'Coast to Coast',
	'Cushman Wakefield',
	'DK1',
	'Ellis',
	'Expert NJS',
	'G2',
	'Global',
	'ICI',
	'Infinity99',
	'Marsden',
	"Master Maintenance Nat'l",
	'Metro',
	'Mr Windows',
	'National',
	'Nest',
	'NONE',
	'OnSite',
	'Other/ Not Listed',
	'SMS Assist',
	'Super Clean',
	'Thoreau',
	'True Source',
	'USM',
	'W Services'
];

export default national;
