import { useState } from 'react';

export const useArray = defaultValue => {
    if (!defaultValue instanceof Array){
        defaultValue = [];
    }
	const [array, set] = useState(defaultValue);

	const push = element => {
		set(array => [...array, element]);
	};

	const filter = callBack => {
		set(array => array.filter(callBack));
	};

	const update = ( newElement, index ) => {
		set(array => [
			...array.slice(0, index),
			newElement,
			...array.slice(index + 1, array.length - 1)
		]);
	};

	const remove = index => {
		set(array => [
			...array.slice(0, index),
			...array.slice(index + 1, array.length - 1)
		]);
	};

	const clear = () => {
		set([]);
	};

	return [array, { set, push, filter, update, remove, clear }];
};

export default useArray;