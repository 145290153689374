export const types = [
	'',
	'Air scrubber',
	'Dehumidifier',
	'Fan (Air Movers)',
	'Floor Mat Drying System',
	'Generators',
	'Hygrometer',
	'Injection Drying',
	'Lift',
	'Moisture Meters',
	'Portable extractor',
	'Portable power washer',
	'Scaffolding/ladder',
	'Self-contained power washer',
	'Thermal Imaging',
	'Truck mounted extractor',
	'Water Tank & Wand'
];

export default types;
