import { useEffect, useState } from 'react';
// eslint-disable-next-line
import { updateRFPs } from '../../../services/realm/vendors.js';

const RfpForm = props => {
	const [costPerService, setCostPerService] = useState('');
	const [comment, setComment] = useState('');

	const [submitted, setSubmitted] = useState(false)
	const [edit, setEdit] = useState(false)
	const {
		RecId,
		CostPerService,
		Comment,
		Status,
	} = props.cell.row.original;

	const handleSubmit = async e => {
		e.preventDefault();
		const obj = {
			RecId: parseInt(RecId),
			Comment: comment,
			CostPerService: parseFloat(costPerService),
			Status: "Submitted"
		};
		const {data} = await updateRFPs(obj)
		
		if (data.Status === 'Submitted'){
			setCostPerService(data.CostPerService)
			setComment(data.Comment)
			setSubmitted(true)
			setEdit(false)
		}
	};

	useEffect(()=>{
		if (Status === "Submitted"){
			setSubmitted(true)
		}
	},[Status])

	return (
		<>
			{ submitted && !edit?
				<p>Submitted <span>
					<button onClick={()=>setEdit(true)}>Edit</button>
					</span>
				</p>
				:
				<form onSubmit={e => handleSubmit(e)}>
					<span>
						<strong>$ </strong>
						<input
							placeholder={ edit? CostPerService :'Estimate'}
							name='costPerService'
							value={costPerService}
							style={{ width: '100px' }}
							onChange={e => setCostPerService(e.target.value)}
							type='number'
						/>{' '}
					</span>
					<span>
						<input
							placeholder={ edit? Comment : 'Comment'}
							name='comment'
							value={comment}
							onChange={e => setComment(e.target.value)}
							type='text'
						/>{' '}
					</span>
					<button type='submit'>submit</button>{" "}
					{edit? <button onClick={()=>setEdit(false)}>Close</button>: null }
				</form>
			}
		</>
	);
};

const rfps = [
	{
		Header: 'ServiceType',
		accessor: 'ServiceType',
		width: 200
	},
	{
		Header: 'Task',
		accessor: 'Task',
		width: 325
	},
	{
		Header: 'Area',
		accessor: 'Area',
		width: 375
	},
	{
		Header: 'Cycles',
		accessor: 'Cycles',
		width: 50
	},
	{
		Header: 'Store Name',
		accessor: 'StoreName',
		width: 150
	},
	{
		Header: 'Store Address',
		accessor: 'StoreAddr',
		width: 300
	},
	{
		Header: 'City',
		accessor: 'City',
		width: 75
	},
	{
		Header: '',
		id: 'form',
		width: 450,
		Cell: e => RfpForm(e)
	}
];

export default rfps;
