import tableConfig from './config';
import ToolBar from './toolbar/ToolBar';
import BaseTable, { SortableTable as Table } from './Table';
import Pagination from './Pagination';
import { Box, Paper } from '@material-ui/core';

const EnhancedTable = props => {
	const {
		canFilterRows, //true false
		canHideColumns, //true false
		canExportToExcel, //true false
		canGlobalFilter, //true false
		canScheduleDateUpdate, //true false
		columns, //array
		data, //array
		defaultHiddenColumns = [], //array
		neverShowColumns, //array
		docId, //string
		selectRow,
		tableTitle, //string
		updateData
	} = props;

	const initialState = {
		hiddenColumns: defaultHiddenColumns,
		pageSize: 25
	};
	const table = tableConfig({ columns, data, initialState, updateData });

	const {
		state: { selectedRowIds }
	} = table;

	return (
		<Box
			sx={{
				width: '100%',
				minHeight: '460px',
				height: '100%',
				overflow: 'hidden'
			}}
		>
			<ToolBar
				{...table}
				canFilterRows={canFilterRows}
				canHideColumns={canHideColumns}
				canExportToExcel={canExportToExcel}
				canGlobalFilter={canGlobalFilter}
				canScheduleDateUpdate={canScheduleDateUpdate}
				neverShowColumns={neverShowColumns}
				numSelected={Object.keys(selectedRowIds).length}
				tableTitle={tableTitle}
				docId={docId}
			/>
			<Table 
				{...table} 
				selectRow={selectRow} 
				docId={docId} />
			<Pagination {...table} />
		</Box>
	);
};

export const BasicTable = props => {
	const { columns, data, docId } = props;

	const table = tableConfig({ columns, data });
	return (
		<Paper
			elevation={0}
			variant='outlined'
			style={{ width: '100%', maxHeight: 1100 }}
		>
			<Paper
				elevation={0}
				style={{ width: '100%', maxHeight: 900, overflow: 'hidden' }}
			>
				<BaseTable {...table} docId={docId} />
			</Paper>
		</Paper>
	);
};

export default EnhancedTable;
