import React, { useState } from 'react';
import { Grid, Paper } from '@material-ui/core';

import { SubmitButton } from '../../../components/Buttons';
import Header from '../../../components/forms/Header';

import { HalfLine, ThirdLine } from '../../../components/forms/TextFields';

const CompanyInfoForm = props => {
	const {onSubmit, warningMsg} = props;
	const [name, setName] = useState('');
	const [doingBusinessAs, setDoingBusinessAs] = useState('');
	const [address, setAddress] = useState('');
	const [address2, setAddress2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zip, setZip] = useState('');

	const handleSignUp = async e => {
		e.preventDefault();
		const obj = {
			CompanyName: name,
			CompanyDBA: doingBusinessAs,
			B_Addr1: address,
			B_Addr2: address2,
			B_City: city,
			B_State: state,
			B_Zip: zip
		}
		onSubmit(obj);
	};

	return (
		<Paper
			align='center'
			justify='center'
			style={{ padding: '24px', margin: 'auto', height: '50vh' }}
		>
			<Grid
				container
				spacing={2}
				direction='column'
				alignItems='center'
				justifyContent='space-around'
				style={{ height: '100%' }}
			>
				<Header title='Company Information' warningMsg={warningMsg} />
				<Grid
					xs={10}
					item
					container
					direction='column'
					alignItems='center'
					justifyContent='space-around'
					component='form'
					onSubmit={e => handleSignUp(e)}
				>
					<Grid container spacing={1}>
						<HalfLine
							required
							fullWidth
							label='Company Name'
							onChange={e => setName(e.target.value)}
							value={name}
						/>
						<HalfLine
							label='Doing business as'
							onChange={e => setDoingBusinessAs(e.target.value)}
							value={doingBusinessAs}
						/>
						<HalfLine
							required
							label='Address 1'
							onChange={e => setAddress(e.target.value)}
							value={address}
						/>
						<HalfLine
							label='Address 2'
							onChange={e => setAddress2(e.target.value)}
							value={address2}
						/>
						<ThirdLine
							required
							label='City'
							onChange={e => setCity(e.target.value)}
							value={city}
						/>
						<ThirdLine
							required
							label='State'
							onChange={e => setState(e.target.value)}
							value={state}
						/>
						<ThirdLine
							required
							label='Zip'
							onChange={e => setZip(e.target.value)}
							value={zip}
						/>
					</Grid>
					<Grid item>
						<SubmitButton fullWidth value='Submit' />
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default CompanyInfoForm;
