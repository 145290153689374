import { NativeSelect } from '../components';
import { nationalManagementCompanies } from '../misc';

const serviceFields = [
	{
		name: 'otherNMC',
		label: 'National Management Company',
		gridProps: {
			xs: 12
		},
		Input: NativeSelect,
		defaultValue: '',
		options: nationalManagementCompanies
	}
];

export default serviceFields;
