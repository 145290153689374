export const airports = [
	'',
	'ALB: Albany International',
	'AMA: Rick Husband Amarillo International',
	'ANC: Ted Stevens Anchorage International',
	'ANE: Anoka County-Blaine Airport (Janes Field)',
	'APA: Centennial Airport',
	'ARR: Aurora Municipal Airport',
	'ATL: Hartsfield-Jackson Atlanta International Airport',
	'AUS: Austin-Bergstrom International',
	'BCT: Boca Raton',
	'BDL: Bradley International',
	'BED: Laurence G. Hanscom Field',
	'BFI: Boeing Field/King County International',
	'BHM: Birmingham-Shuttlesworth International',
	'BIH: Eastern Sierra Regional',
	'BIL: Billings Logan International',
	'BJC: Rocky Mountain Metropolitan',
	'BKF: Buckley Air Force Base',
	'BKL: Cleveland Burke Lakefront Airport',
	'BLI: Bellingham International',
	'BNA: Nashville International',
	'BOI: Boise Air Terminal/Gowen Field',
	'BOS: General Edward Lawrence Logan International Airport',
	'BTR: Baton Rouge Metropolitan, Ryan Field',
	'BUF: Buffalo Niagara International',
	'BUR: Bob Hope Airport',
	'BVY: Beverly Municipal Airport',
	'BWI: Baltimore-Washington International Thurgood Marshall Airport',
	'BZN: Bozeman Yellowstone International',
	'CAE: Columbia Metropolitan Airport',
	'CAG: Craig-Moffat County',
	'CGF: Cuyahoga County Airport',
	'CHS: Charleston Air Force Base/International',
	'CLE: Cleveland-Hopkins International Airport',
	'CLT: Charlotte Douglas International Airport',
	'CMH: Port Columbus International',
	'COS: City of Colorado Springs Municipal',
	'CRP: Corpus Christi International',
	'CRQ: McClellan-Palomar Airport',
	'CRW: Yeager',
	'CVG: Cincinnati/Northern Kentucky International',
	'DAL: Dallas Love Field',
	'DAY: James M. Cox Dayton International',
	'DCA: Ronald Reagan Washington National Airport',
	'DEN: Denver International Airport',
	'DET: Coleman A. Young Municipal Airport',
	'DFW: Dallas/ Fort Worth International Airport',
	'DLH: Duluth International',
	'DPA: DuPage Airport',
	'DRO: Durango-La Plata County',
	'DSM: Des Moines International',
	'DTO: Denton Municipal Airport',
	'DTW: Detroit Metropolitan Wayne County Airport',
	'DWH: David Wayne Hooks Memorial Airport',
	'EAT: Pangborn Memorial',
	'ELP: El Paso International',
	'EUG: Mahlon Sweet Field',
	'EWR: Newark Liberty International Airport',
	'FAI: Fairbanks International',
	'FAT: Fresno Yosemite International',
	'FLL: Fort Lauderdale-Hollywood International Airport',
	'FMC: Flying Cloud Airport',
	'FNL: Fort Collins-Loveland',
	'FRG: Republic Airport',
	'FTG: Front Range',
	'FTW: Fort Worth Meacham International Airport',
	'FTY: Fulton County Airport-Brown Field',
	'FXE: Fort Lauderdale Executive Airport',
	'GEG: Spokane International',
	'GJT: Grand Junctional Regional',
	'GKY: Arlington Municipal Airport',
	'GPI: Glacier Park International',
	'GSO: Piedmont Triad International Airport',
	'GSP: Greenville Spartanburg International',
	'GTF: Great Falls International',
	'GUC: Gunnison-Crested Butte Regional',
	'GUM: Guam International',
	'GXY: Greeley-Weld County',
	'GYY: Gary/Chicago International',
	'HAF: Half Moon Bay Airport',
	'HDN: Yampa Valley Regional',
	'HEF: Manassas Regional Airport-Harry P. Davis Field',
	'HHR: Hawthorne Municipal Airport',
	'HND: Henderson Executive Airport',
	'HNL: Daniel K Inouye International Airport',
	'HOU: William P. Hobby Airport',
	'HPN: Westchester County Airport',
	'HRL: Valley International',
	'HWD: Hayward Executive Airport',
	'HYA: Barnstable Municipal-Boardman/Polando Field',
	'IAD: Washington Dulles International Airport',
	'IAH: Houston - George Bush Intercontinental Airport',
	'ICT: Wichita Mid-Continent',
	'IDA: Idaho Falls Regional',
	'ILG: New Castle Airport',
	'IND: Indianapolis International',
	'ISM: Kissimmee Gateway Airport',
	'ISP: Long Island MacArthur Airport',
	'IWA: Phoenix-Mesa Gateway Airport',
	'JAC: Jackson Hole Airport',
	'JAN: Jackson-Medgar Wiley Evers International',
	'JAX: Jacksonville International',
	'JFK: New York - John F. Kennedy International Airport',
	'JNU: Juneau International Airport',
	'JQF: Concord Regional Airport',
	'KOA: Kona International at Keahole',
	'LAS: Las Vegas - McCarran International Airport',
	'LAX: Los Angeles International Airport',
	'LBB: Lubbock Preston Smith International',
	'LCK: Rickenbacker International',
	'LGA: New York - LaGuardia Airport',
	'LGB: Long Beach Airport',
	'LIH: Lihue',
	'LIT: Bill and Hillary Clinton National/Adams Field',
	'LWS: Lewiston-Nez Perce County',
	'MCI: Kansas City International',
	'MCO: Orlando International Airport',
	'MDW: Chicago Midway International Airport',
	'MEM: Memphis International Airport',
	'MFR: Rogue Valley International — Medford',
	'MHT: Manchester-Boston Regional Airport',
	'MIA: Miami International Airport',
	'MKE: General Mitchell International',
	"MLB - Melbourne Int'l Airport",
	'MMU: Morristown Municipal Airport',
	'MRY: Monterey Regional',
	'MSN: Dane County Regional-Truax Field',
	'MSO: Missoula International',
	'MSP: Minneapolis-Saint Paul International/ Wold-Chamberlain Airport',
	'MSY: Louis Armstrong New Orleans International Airport',
	'MTJ: Montrose Regional Airport',
	'MTN: Martin State Airport',
	"MVY: Martha's Vineyard",
	'MWH: Grant County International',
	'MYF: Montgomery Field',
	'Myrtle Beach',
	'NKX: Miramar MCAS',
	'OAK: Metropolitan Oakland International',
	'OGG: Kahului',
	'OKC: Will Rogers World Airport',
	'OLV: Olive Branch Airport',
	'OMA: Eppley Airfield',
	'ONT: Ontario International Airport',
	'OPF: Opa-locka Executive Airport',
	"ORD: Chicago O'Hare International Airport",
	'ORF: Norfolk International',
	'ORL: Orlando Executive Airport',
	'OTH: Southwest Oregon Regional',
	'OWD: Norwood Memorial Airport',
	'OXR: Oxnard Airport',
	'PAE: Snohomish County Airport (Paine Field)',
	'PAO: Palo Alto Airport of Santa Clara County',
	'PBI: Palm Beach International Airport',
	'PDK: DeKalb-Peachtree Airport',
	'PDX: Portland International (OR)',
	'PHL: Philadelphia International Airport',
	'PHX: Sky Harbor International Airport',
	'PIE: St. Petersburg-Clearwater International Airport',
	'PIT: Pittsburgh International',
	'PNE: Northeast Philadelphia Airport',
	'PRC: Ernest A. Love Field',
	'PSC: Tri-Cities',
	'PSP: Palm Springs International Airport',
	'PTK: Oakland County International Airport',
	'PUW: Pullman/Moscow Regional',
	'PVD: Theodore Francis Green State',
	'PWK: Chicago Executive Airport',
	'PWM: Portland International Jetport (ME)',
	'RDM: Roberts Field',
	'RDU: Raleigh-Durham International',
	'RFD: Chicago/Rockford International',
	'RIC: Richmond International',
	'RIL: Garfield County Regional',
	'RNO: Reno/Tahoe International',
	'ROC: Greater Rochester International',
	'RYY: Cobb County Airport-McCollum Field',
	'SAN: San Diego International Airport',
	'SAT: San Antonio International',
	'SAV: Savannah/Hilton Head International',
	'SBS: Steam Boat Springs/Bob Adams Field',
	'SCC: Deadhorse',
	'SDF: Louisville International-Standiford Field',
	'SDL: Scottsdale',
	'SEA: Seattle-Tacoma International Airport',
	'SEF: Sebring Regional',
	'SFB: Orlando Sanford International Airport',
	'SFO: San Francisco International Airport',
	'SGR: Sugar Land Regional Airport',
	'SJC: Norman Y. Mineta San Jose International Airport',
	'SJU: Luis Munoz Marin International',
	'SLC: Salt Lake City International Airport',
	'SMF: Sacramento International Airport',
	'SMO: Santa Monica Municipal Airport',
	'SNA: John Wayne Airport-Orange County',
	'SRQ: Sarasota/Bradenton International Airport',
	'STL: Lambert-St. Louis International',
	'STP: St. Paul Downtown Holman Field',
	'STS: Charles M. Schulz - Sonoma County Airport',
	'SUN: Friedman Memorial',
	'SWF: Stewart International Airport',
	'SYR: Syracuse Hancock International',
	'TEB: Teterboro',
	'TEX: Telluride Regional',
	'TIW: Tacoma Narrows Airport',
	'TKI: McKinney National Airport',
	'TMB: Kendall-Tamiami Executive Airport',
	'TPA: Tampa International Airport',
	'TTN: Trenton Mercer',
	'TUL: Tulsa International',
	'TUS: Tucson International',
	'TYS: McGhee Tyson Airport',
	'UGN: Waukegan Regional Airport',
	'VGT: North Las Vegas Airport',
	'VNY: Van Nuys Airport',
	'YIP: Willow Run Airport',
	'YKM: Yakima Air Terminal/ McAllister Field'
];

export default airports;
