import { Box, Container } from '@material-ui/core';
import Copyright from './Copyright';

const Footer = () => {
	return (
		<Box
			component='footer'
			sx={{
				py: 3,
				px: 2
			}}
		>
			<Container maxWidth='sm'>
				{/* <Typography variant='body1'></Typography> */}
				<Copyright />
			</Container>
		</Box>
	);
};

export default Footer;
