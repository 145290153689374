const groupMatchingTags = string => {
	const reg = /<(\w+)>(.*?)<\/(\1)>/g;
    return string.match(reg);
}

const containsChildTags = string => {
    const reg = /<(\w+)>(<(.*?)>(.*?)<\/(.*?)>)<\/(\1)>/g;
    return reg.test(string);
}

const getKeyValuePairs = string => {
    const reg = /<(\w+)>(.*?)<\/(\1)>/g;
    return string.replace(reg, '"$1":"$2"')
}

export const parseXML = xmlStr => {
    let keys = groupMatchingTags(xmlStr);
    keys = keys.map( key => {
        const keyValuePairs = getKeyValuePairs(key);
        if ( containsChildTags(key) ) {
			let [k, v] = keyValuePairs.split(':');
			return`${k}: ${parseXML(v)}`;
        }
        return keyValuePairs;
    })
    return `{${(keys.join(','))}}`;
};
