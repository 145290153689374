import  { useState } from 'react';
// eslint-disable-next-line
import { Container, Paper, Grid } from '@material-ui/core';
// eslint-disable-next-line
import Page from '../../components/Page';
import { SubmitButton } from '../../components/Buttons';

import { loginCustomFunction } from '../../services/realm/Realm';
import Header from '../../components/forms/Header';
import { Dash, ThreeDigitInput } from '../../components/forms/SpacedValues';
import { useUser } from '../../context/user';

const Register = props => {
	const { invitation } = props.match.params
	// const { setCurrentUser, setLogged } = props;
	const { logIn } = useUser();
    const [one, setOne] = useState('');
	const [two, setTwo] = useState('');
	const [three, setThree] = useState('');
	const [warningMsg, setWarningMsg] = useState(null);

	const handleAccessCode = async e => {
		e.preventDefault();
		const inv = invitation.toUpperCase();

		const obj = {
			username: `TEMP-${inv.slice(1, 4)}-${inv.slice(4, 7)}-${inv.slice(7,10)}`,
			password: `${one}-${two}-${three}`
		};

		const { user, error } = await loginCustomFunction(obj);
		if (!error) {
			setWarningMsg(null);
			logIn(user);
			// setCurrentUser(user);
			// setLogged(true);
		} else {
			setWarningMsg('Access Denied');
		}
	};
	return (
		<Page>
			<Container
				component='div'
				style={{ display: 'flex', margin: 'auto', width: '600px' }}
			>
				<Paper
					align='center'
					justify='center'
					style={{ padding: '24px', margin: 'auto', height: '520px' }}
				>
					<Grid
						container
						spacing={2}
						direction='column'
						alignItems='center'
						justify='center'
						style={{ height: '100%' }}
					>
						<Header title='Please Enter Access Code' warningMsg={warningMsg} />
						<Grid
							item
							container
							direction='column'
							alignItems='center'
							justifyContent='space-around'
							component='form'
							onSubmit={e => handleAccessCode(e)}
							style={{ height: '40%' }}
						>
							<Grid
								item
								container
								spacing={1}
								direction='row'
								alignContent='center'
								justifyContent='center'
							>
								<ThreeDigitInput
									onChange={e => setOne(e.target.value.toUpperCase())}
									value={one}
								/>
								<Dash />
								<ThreeDigitInput
									onChange={e => setTwo(e.target.value.toUpperCase())}
									value={two}
								/>
								<Dash />
								<ThreeDigitInput
									onChange={e => setThree(e.target.value.toUpperCase())}
									value={three}
								/>
							</Grid>
							<Grid item>
								<SubmitButton fullWidth value='Submit' />
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Page>
	);
};

export default Register;
