import { useState } from 'react';

const useToggle = ( initialState = false ) => {
	const [ value , setValue ] = useState(initialState);

	const toggleValue = value => { 
		setValue( currentValue => 
			typeof value === 'boolean' ? value : !currentValue 
		);
	};

	return [ value, toggleValue ];
};

export default useToggle;
