// eslint-disable-next-line
import { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useUser } from '../context/user';

import Home from '../pages/crew/restricted/Home';
import General from '../pages/crew/restricted/General';
import Company from '../pages/crew/restricted/Company';
// import FourOFour from '../pages/errors/FourOFour';

const NewCrewRoutes = props => {
	// const { required } = props.currentUser;
	const { currentUser } = useUser();
	const { required } = currentUser;
	//----------------------------- General Info -----------------------------//
	const [generalInfo, setGeneralInfo] = useState(false);

	useEffect(() => {
		setGeneralInfo(required.generalInfo);
	},[required.generalInfo])

	//----------------------------- Company Info -----------------------------//
	const [companyInfo, setCompanyInfo] = useState(false);

	useEffect(() => {
		setCompanyInfo(required.companyInfo);
	},[required.companyInfo])

	//----------------------------- Smi Reviewed -----------------------------//
	// eslint-disable-next-line
	const [smiReviewed, setSmiReviewed] = useState(false);

	useEffect(() => {
		setSmiReviewed(required.smiReviewed);
	},[required.smiReviewed])

	//------------------------------ Paperwork -------------------------------//
	// eslint-disable-next-line
	const [paperwork, setPaperwork] = useState(false);

	useEffect(() => {
		setPaperwork(required.signDocs);
	},[required.signDocs])

	return(
		<>
			{ !generalInfo &&
				<Route 
					path='/' 
					render={p => (
						<General 
							{...p} 
							{...props}
							generalInfo={generalInfo}
							setGeneralInfo={setGeneralInfo} 
						/>
					)} 
				/>
			}
			{ generalInfo &&
				<> 
					<Route
						exact
						path='/'
						pageTitle='Home'
						render={p => 
							<Home 
								{...p} 
								{...props}
								generalInfo={generalInfo}
								companyInfo={companyInfo}
								smiReviewed={smiReviewed}
								paperwork={paperwork}
							/>
						}
					/>
					<Route 
						exact 
						path='/registration/companyInfo' 
						render={p => 
							<Company
								{...p} 
								{...props}
								companyInfo={companyInfo}
								setCompanyInfo={setCompanyInfo} 
							/>
						} 
					/>
				</>
			}
			{/* <Route path="*" component={FourOFour} /> */}
			
		</>
	)
	
};

export default NewCrewRoutes