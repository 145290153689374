
import {
	Grid,
	Typography
} from '@material-ui/core';

const Header = props => {
    const {title, warningMsg} = props;
    return (
        <Grid
            container
			spacing={2}
			direction='column'
			alignItems='center'
			justify='center'
        >
            <Grid item >
                <Typography component='h1' variant='h5'>
                    {title}
                </Typography>
            </Grid>
            <Grid item style={{ width: '100%', height: '50px' }}>
                {warningMsg !== null && (
                    <Typography component='h1' variant='body1' color='error'>
                        * {warningMsg} *
                    </Typography>
                )}
            </Grid>
        </Grid>
    )
}

export default Header;