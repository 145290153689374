import React, { useContext, useReducer, useEffect } from 'react';
import { getUserDetails, clearUserDetails } from '../services/realm/Realm';

const initialState = {
	currentUser: {
		CrewCode: null
	},
	logged: false,
	authorization: undefined
};

const actions = {
	SET_USER: 'SET_USER',
	UPDATE_USER: 'UPDATE_USER',
	REMOVE_USER: 'REMOVE_USER'
};

const reducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case actions.SET_USER:
			return payload;
		case actions.REMOVE_USER:
			return initialState;
		case actions.UPDATE_USER:
			let currentUser = { ...state.currentUser, ...payload };
			let authorization = payload.userType
			return { ...state, currentUser, authorization };
		default:
			return state;
	}
};

const UserContext = React.createContext();

export const UserContextProvider = props => {
	const [state, dispatch] = useReducer(reducer, initialState);

	// console.log(state)

	const setUser = currentUser => {
		dispatch({
			type: actions.SET_USER,
			payload: {
				currentUser,
				logged: true,
				authorization: currentUser.userType
			}
		});
	};

	const updateUser = payload => {
		dispatch({ type: actions.UPDATE_USER, payload });
		let currentUser = { ...state.currentUser, ...payload };
		sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
	};

	const removeUser = () => {
		dispatch({ type: actions.REMOVE_USER });
	};

	const logIn = currentUser => {
		sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
		setUser(currentUser);
	};

	const logOut = () => {
		clearUserDetails();
		sessionStorage.removeItem('currentUser');
		removeUser();
	};

	const minute = 1000 * 5;

	const isTempCrew = CrewCode => {
		let regExp = /^TEMP/;
		return regExp.test(CrewCode);
	}
	
	const checkForUpdates = async( userName, crewCode ) => {
		let interval = setInterval(async()=>{
			// console.log('checking')
			const { user, error } = await getUserDetails(userName);
			if (error){
				console.error(error);
			} else if (crewCode !== user.CrewCode){
				// console.log('updating') 
				updateUser(user)
				clearInterval(interval)
			} //else {
			// 	console.log('no changes')
			// }
		}, minute)
	}


	useEffect(() => {
		if (sessionStorage.currentUser) {
			const storedUser = JSON.parse(sessionStorage.getItem('currentUser'));
			if (storedUser.CrewCode) {
				if ( isTempCrew(storedUser.CrewCode) ){
					checkForUpdates(storedUser.userName, storedUser.CrewCode)
				} 
				setUser(storedUser);
			} else {
				logOut();
			}
		}
		// eslint-disable-next-line
	}, []);

	const value = { ...state, logIn, logOut, updateUser };

	return (
		<UserContext.Provider value={value}>{props.children}</UserContext.Provider>
	);
};

export const useUser = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUser must be used within a UserContextProvider');
	}
	return context;
};
