import { Grid, Typography } from '@material-ui/core';
import { SixthLine } from './TextFields';

export const Dash = props => {
	return (
		<Grid
			item
			sm={1}
			container
			spacing={0}
			direction='column'
			alignItems='center'
			justifyContent='center'
		>
			<Typography component='h1' variant='h5'>
				-
			</Typography>
		</Grid>
	);
};

export const ThreeDigitInput = props => {
	return (
		<SixthLine 
            {...props}
			inputProps={{ maxLength: 3 }}
			required
        />
	);
};

export const FourDigitInput = props => {
	return (
        <SixthLine 
            {...props}
			inputProps={{ maxLength: 4 }}
			required
        />
	);
};
