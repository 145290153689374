const transactions = [
    {
        Header: 'User',
        columns: [
            {
                Header: 'User',
                accessor: 'user',
                disableFilters: true,
                width: 100,
            },
            {
                Header: 'User Type',
                accessor: 'userType',
                disableFilters: true,
                width: 100,
            },
        ]
    },
    {
        Header: "Login",
        columns: [
            {
                Header: 'Date',
                accessor: 'logDate',
                disableFilters: true,
                width: 100 
            },
            {
                Header: 'Time',
                accessor: 'logTime',
                disableFilters: true,
                width: 100 
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableFilters: true,
                width: 100
            }
        ]
    }
];

export default transactions;