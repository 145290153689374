import React, { useState } from 'react';
import {
	Box,
	Button,
	Container,
	Paper,
	Typography
} from '@material-ui/core';

import Page from '../../components/Page';
import { OutlinedTextField } from '../../components/TextField';
import { SubmitButton } from '../../components/Buttons';

import { recoverUserAccount } from '../../services/realm/userAccounts';



const Recover = props => {
	const {history} = props;
	const [form, setForm] = useState({});
	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(false);

	const handleChange = event =>{
		const { name, value } = event.target;
		setForm(curr => ({...curr, [`${name}`]: value }))
	}

	const handleRecover = async e => {
		e.preventDefault();
		setSubmitted(true);
		const response = await recoverUserAccount(form);
		if (
			response.status && 
			typeof response.status === 'number' && 
			response.status >= 200 &&
			response.status <= 299
		){
			setSuccess(true)
		}
	};

	const navigateHome = () => {
		history.push('/')
	}

	return (
		<Page>
			<Container
				component='div'
				maxWidth='xs'
				style={{ display: 'flex', margin: 'auto' }}
			>
				<Paper
					align='center'
					justify='center'
					style={{ padding: '24px', margin: 'auto', height: '300px' }}
				>
					{success ? (
						<>
							<Typography component='h1' variant='h5'>
								Account Recovery Email Sent
							</Typography>
							<Box
								style={{ padding: '8px', margin: '24px 4px', height: 'auto' }}
							>
								<Button variant='contained' onClick={navigateHome}>
									Return to Login
								</Button>
							</Box>
						</>
					) : (
						<>
							<Typography component='h1' variant='h5'>
								Recover Account
							</Typography>

							<Typography component='p' variant='h6'>
								Enter your registered e-mail.
							</Typography>

							<Box
								component='form'
								style={{ padding: '8px', margin: '24px 4px', height: 'auto' }}
								onSubmit={e => handleRecover(e)}
								noValidate
							>
								<Box component='div' style={{ margin: '8px auto' }}>
									<OutlinedTextField
										required
										fullWidth
										id='email'
										label='Email'
										name='email'
										autoComplete='email'
										autoFocus
										onChange={handleChange}
									/>
								</Box>

								<Box component='div' style={{ marginBottom: '4px' }}>
									<SubmitButton
										fullWidth
										disabled={submitted}
										value='Send Request'
									/>
								</Box>
							</Box>
						</>
					)}
				</Paper>
			</Container>
		</Page>
	);
};

export default Recover;
