import { Grid, TextField } from '@material-ui/core';

export const FullLine = props => {
	return (
		<Grid item xs={12} sm={12}>
			<TextField {...props} variant='outlined' margin='normal' fullWidth />
		</Grid>
	);
};
export const HalfLine = props => {
	return (
		<Grid item xs={6} sm={6}>
			<TextField {...props} variant='outlined' margin='normal' fullWidth />
		</Grid>
	);
};

export const ThirdLine = props => {
	return (
		<Grid item xs={4} sm={4}>
			<TextField {...props} variant='outlined' margin='normal' fullWidth />
		</Grid>
	);
};

export const QuarterLine = props => {
	return (
		<Grid item xs={3} sm={3}>
			<TextField {...props} variant='outlined' margin='normal' fullWidth />
		</Grid>
	);
};

export const SixthLine = props => {
	return (
		<Grid item xs={2} sm={2}>
			<TextField {...props} variant='outlined' margin='normal' fullWidth />
		</Grid>
	);
};
