export const systems = [
	'',
	'Accruent',
	'Ariba',
	'Big Sky',
	'Corrigo',
	'Coupa',
	'Ecotrak',
	'Facility Source v1.0',
	'Facility Source v2.0',
	'FEXA',
	'M-No 3rd Pty',
	'OfficeTrax',
	'Service Channel',
	'SM-No 3rd Pty',
	'SMS Assist',
	'Tango',
	'Verisae'
];

export default systems;
