//----------------------------------------- Dependencies -----------------------------------------//
// eslint-disable-next-line
import { useState, useEffect, useMemo } from 'react';

//------------------------------------------ Components ------------------------------------------//
import Dashboard from '../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../components/dashboard/Dashboard.style';
import loginCols from './columns/logins.js';
import config from '../../components/tables/config'
import Table from '../../components/tables/Table'

const UserLogs = props => {
    const {logData, handleLogData} = props;
	const columns = useMemo(()=> loginCols, [])
	const table = config({ columns, data: logData})
    
    useEffect(()=>{
        handleLogData()
    }, [handleLogData])

	//-------------------------------------- Error Handling --------------------------------------//
	// eslint-disable-next-line
	const [warningMsg, setWarningMsg] = useState(null);

	//---------------------------------------- UI Context ----------------------------------------//
	const classes = dashboardStyles();

	return (
		<>
			<Dashboard
				{...props}
				classes={classes}
				warningMsg={warningMsg}
				fullPage={true}
			>
				<Table
					{...table}
					docId={'logs'}
				/>
			</Dashboard>
		</>
	);
};

export default UserLogs;