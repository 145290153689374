import TransactionDetails from "./components/TransactionDetails";
import { civilianTime } from "../../../../helpers/stringFormat";

const inbound = [
	{
		Header: 'Transaction',
		columns: [
			{
				Header: 'Date',
				accessor: 'TransDate',
				disableFilters: true,
				width: 100,
				textAlign: 'center'
			},
			{
				Header: 'Time',
				accessor: 'TransTime',
				disableFilters: true,
				width: 100,
				textAlign: 'center',
				Cell: ({ value }) => civilianTime(value)
			},
			{
				Header: 'Type',
				accessor: 'TransType',
				disableFilters: true,
				width: 100,
				textAlign: 'center'
			},
			{
				Header: 'Details',
				accessor: 'TransDetails',
				disableFilters: true,
				width: 100,
				textAlign: 'left',
				Cell: ({ cell }) => TransactionDetails(cell)
			}
		]
	}
];

export default inbound;
