import React, { useState } from 'react';
import Dashboard from '../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../components/dashboard/Dashboard.style.js';
import { vendorGenerator } from '../../services/realm/employees.js';
import {  Button } from '@material-ui/core';


const InviteCrew = props => {
	const [generated, setGenerated] = useState(null);

	const handleGenerate = async () => {
		const { data } = await vendorGenerator();
		if (data) {
			setGenerated(data);
		}
	};

	const classes = dashboardStyles();
	const credentials = { margin: 0, padding: 0 };
	return (
		<>
			<Dashboard {...props} classes={classes} fullPage>
				<div>
					{generated ? (
						<>
							<h2>Invite Credentials Generated</h2>
							<p>
								these credentials are temporary and will allow a new vendor to
								login to the crew portal, create a profile, and submit
								documents.
							</p>
							<div style={{ margin: '10px', padding: '10px' }}>
								<h3 style={credentials}>Invitation: {generated.Invitation}</h3>
								<h3 style={credentials}>AccessCode: {generated.AccessCode}</h3>
							</div>
						</>
					) : (
						<>
							<h2>Generate Invite Credentials</h2>
							<Button
								variant='contained'
								component='span'
								size='small'
								color='primary'
								onClick={() => handleGenerate()}
							>
								Generate
							</Button>
						</>
					)}
				</div>
			</Dashboard>
		</>
	);
};

export default InviteCrew;
