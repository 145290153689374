import { Box, CssBaseline } from '@material-ui/core';
import Footer from './Footer';

const Page = props => {
	return (
		<Box
			sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
			{...props}
		>
			<CssBaseline />
			{props.children}
			<Footer />
		</Box>
	);
};

export default Page;
