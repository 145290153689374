//------------------------------- Dependencies -------------------------------//
// eslint-disable-next-line
import { useState, useEffect, useMemo } from 'react';

//-------------------------------- Components --------------------------------//
import Dashboard from '../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../components/dashboard/Dashboard.style';
// import config from '../../components/tables/config'
import Table from '../../components/tables/EnhancedTable'

const Transactions = props => {
    const {
		// transData, 
		pageTitle, 
		handleData, 
		// transCols, 
		...otherProps 
	} = props;
	// const columns = useMemo(()=> transCols, [transCols])
	// const table = config({ columns: transCols, data: transData})
    
    useEffect(()=>{
        handleData()
    }, [handleData])

	//---------------------------- Error Handling ----------------------------//
	// eslint-disable-next-line
	// const [warningMsg, setWarningMsg] = useState(null);

	//------------------------------ UI Context ------------------------------//
	const classes = dashboardStyles();

	return (
		<>
			<Dashboard
				classes={classes}
				pageTitle={pageTitle}
				// warningMsg={warningMsg}
				fullPage={true}
			>
				<Table
					// {...table}
					{...otherProps}
					docId={'transactions'}
				/>
			</Dashboard>
		</>
	);
};

export default Transactions;