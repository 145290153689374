import { Container, Divider, Paper, Typography } from '@material-ui/core';
import Page from '../../components/Page';
import Link from '../../components/Link';

const FourOFour = props => {
	const { history } = props;
	return (
		<Page>
			<Container
				component='div'
				maxWidth='xl'
				style={{ display: 'flex', margin: 'auto' }}
			>
				<Paper
					align='center'
					justify='center'
					style={{
						padding: '24px',
						margin: 'auto',
						height: '40vh',
						width: '60vw'
					}}
				>
					<Typography
						variant='h1'
						align='center'
						style={{ margin: '50px 0 0 0' }}
					>
						Oops!
					</Typography>
					<Divider style={{ margin: '100px 0 25px 0' }} light />
					<Typography variant='h4'>
						We can't seem to find the page you're looking for.
					</Typography>
					<Typography variant='h5'>
						Please check the URL or go back to the home page.
					</Typography>
					<div style={{ margin: '25px 0 0 0' }}>
						<Link variant='h6' onClick={() => history.push('/')}>
							Go to Home Page
						</Link>
					</div>
				</Paper>
			</Container>
		</Page>
	);
};

export default FourOFour;
