import { useState, useCallback } from 'react';
import { Route } from 'react-router-dom';
import {
	loginLogs,
	findCrews,
	onboardingApprovalList
} from '../services/realm/employees';
import {
	activityLogBarData,
	activityLogWeeklyBarData,
	activityLogRawData
} from '../services/realm/activityLogs';
import { downloadXLSX } from '../services/xlsx';

import crewCols from '../pages/employee/columns/crews';
import crewApprovalCols from '../pages/employee/columns/crewApproval';
import {
	AddCrewsPage,
	AdminCrewPasswordResetPage,
	DataTablePage,
	HomePage,
	InviteCrewPage,
	// ManagementPage,
	ResetCrewPasswordPage,
	UserLogsPage,
	VerticalBarChartPage
} from '../pages/employee';
import TransactionRoutes from './TransactionRoutes';
import vertical1 from '../pages/employee/charts/vertical1';
import weeklyActivity from '../pages/employee/charts/weeklyActivity';
import { useUser } from '../context/user';

const formatData = (data, dataKeys) => {
	let chartData = data.map(rec => {
		let recData = {};
		recData.x = rec.x;
		dataKeys.forEach(key => {
			recData[`${key}`] = rec.ks[`${key}`] ? rec.ks[`${key}`] : 0;
		});
		return recData;
	});
	return chartData;
};

const randomColor = () => {
	return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

const formatBars = (dataKeys, yAxisId = 'y-axis', stackId = '') => {
	let bars = [];
	dataKeys.forEach(dataKey => {
		let bar = {
			yAxisId,
			dataKey,
			fill: randomColor()
		};
		if (stackId !== '') {
			bar.stackId = stackId;
		}
		bars.push(bar);
	});
	return bars;
};

const EmployeeRoutes = props => {
	//------------------------------ User Logs -------------------------------//
	const [logData, setLogData] = useState([]);
	const { currentUser } = useUser();

	const handleLogData = useCallback(async () => {
		const { data, error } = await loginLogs();
		if (!error) {
			setLogData(data);
		} else {
			console.error(error);
		}
	}, []);

	//----------------------------- Crews Table ------------------------------//
	const [crewData, setCrewData] = useState([]);

	const handleCrewData = useCallback(async () => {
		const { data, error } = await findCrews();
		if (!error) {
			setCrewData(data);
		} else {
			console.error(error);
		}
	}, []);

	//---------------------- Onboarding Approval Table -----------------------//
	const [approvalData, setApprovalData] = useState([]);

	const handleApprovalData = useCallback(async () => {
		const { data, error } = await onboardingApprovalList();
		if (!error) {
			setApprovalData(data);
		} else {
			console.error(error);
		}
	}, []);

	//----------------------- Stacked User Bar Graph  ------------------------//
	let now = new Date();
	let day = 24 * 60 * 60 * 1000;

	let past24 = new Date(new Date() - day);
	const [eaQuery, setEAQuery] = useState({
		EditedAt: {
			$gte: past24,
			$lte: now
		}
	});

	const [empActivityData, setEmpActivityData] = useState([]);
	const [empEditTypes, setEmpEditTypes] = useState([]);
	const [empUsers, setEmpUsers] = useState([]);

	const [eaGraph1, setEAGraph1] = useState(vertical1);

	const updateEAGraph1 = props => {
		setEAGraph1(curr => ({ ...curr, ...props }));
	};

	const [eaGraph2, setEAGraph2] = useState(vertical1);

	const updateEAGraph2 = props => {
		setEAGraph2(curr => ({ ...curr, ...props }));
	};

	const handleEmpEditedAt = event => {
		const { name, value } = event.target;
		let obj = { [`${name}`]: new Date(value) };
		setEAQuery(curr => ({
			...curr,
			EditedAt: {
				...curr.EditedAt,
				...obj
			}
		}));
	};

	const handleEmpChange = (name, value) => {
		let obj = { [`${name}`]: value };
		setEAQuery(curr => ({
			...curr,
			...obj
		}));
	};

	// eslint-disable-next-line
	const handleChange = event => {
		const { name, value } = event.target;
		let obj = { [`${name}`]: new Date(value) };
		setEAQuery(curr => ({
			...curr,
			EditedAt: {
				...curr.EditedAt,
				...obj
			}
		}));
	};

	const handleEmployeeActivity = useCallback(async () => {
		const { data, dataKeys, EditTypes, EditUserIDs } = await activityLogBarData(
			{ query: eaQuery }
		);

		if (EditTypes && EditTypes.length > 0) {
			setEmpEditTypes(['', ...EditTypes]);
		}

		if (EditUserIDs && EditUserIDs.length > 0) {
			setEmpUsers(['', ...EditUserIDs]);
		}

		let chartData = formatData(data, dataKeys);
		setEmpActivityData(chartData);

		const bars1 = formatBars(dataKeys, 'y-axis', 'a');
		updateEAGraph1({ bars: bars1 });

		const bars2 = formatBars(dataKeys);
		updateEAGraph2({ bars: bars2 });
	}, [eaQuery]);

	const handleEmployeeRawData = async () => {
		const { data } = await activityLogRawData({query:eaQuery});
		downloadXLSX(data, 'raw-employee-activity');
	};

	//------------------------ Weekly User Bar Graph  ------------------------//
	let past7Days = new Date(new Date() - 7 * day);

	const [waQuery, setWAQuery] = useState({
		EditedAt: {
			$gte: past7Days,
			$lte: now
		},
		AutoProc: 0
	});

	const [weeklyActivityData, setWeeklyActivityData] = useState([]);
	const [weeklyEditTypes, setWeeklyEditTypes] = useState([]);
	const [weeklyUsers, setWeeklyUsers] = useState([]);

	const [waGraph, setWAGraph] = useState(weeklyActivity);

	const updateWAGraph = props => {
		setWAGraph(curr => ({ ...curr, ...props }));
	};

	const handleWeeklyEditedAt = event => {
		const { name, value } = event.target;
		let obj = { [`${name}`]: new Date(value) };
		setWAQuery(curr => ({
			...curr,
			EditedAt: {
				...curr.EditedAt,
				...obj
			}
		}));
	};

	const handleWeeklyActivityChange = (name, value) => {
		let obj = { [`${name}`]: value };
		setWAQuery(curr => ({
			...curr,
			...obj
		}));
	};
	// const handleWeeklyAutoProc = () => {
	// 	if ()
	// }

	const handleWeeklyActivity = useCallback(async () => {
		const { data, dataKeys, EditTypes, EditUserIDs } =
			await activityLogWeeklyBarData({ query: waQuery });


		if (weeklyEditTypes.length === 0) {
			if (EditTypes && EditTypes.length > 0) {
				setWeeklyEditTypes(['', ...EditTypes]);
			}
		}

		if (weeklyUsers.length === 0) {
			if (EditUserIDs && EditUserIDs.length > 0) {
				setWeeklyUsers(['', ...EditUserIDs]);
			}
		}

		let chartData = formatData(data, dataKeys);
		setWeeklyActivityData(chartData);

		/* Stacked 
		const bars = formatBars(dataKeys, 'y-axis', 'a');
		*/

		/* Grouped*/
		const bars = formatBars(dataKeys);

		updateWAGraph({ bars });

		// eslint-disable-next-line
	}, [waQuery]);

	const handleWeeklyRawData = async() => {
		const {data} =  await activityLogRawData({query:waQuery});
		downloadXLSX(data, 'raw-weekly-activity');
	}

	return (
		<>
			<Route
				exact
				path='/'
				render={p => <HomePage {...p} {...props} pageTitle='Home' />}
			/>
			<Route
				exact
				path='/crews'
				render={p => (
					<DataTablePage
						{...p}
						{...props}
						pageTitle='Crews List'
						tableTitle='Crews'
						id='crews'
						columns={crewCols}
						data={crewData}
						handleData={handleCrewData}
					/>
				)}
			/>
			<Route
				exact
				path='/crews/create-logins'
				render={p => <AddCrewsPage {...p} {...props} pageTitle='Add Crews' />}
			/>
			<Route
				exact
				path='/crews/reset-password'
				render={p => (
					<ResetCrewPasswordPage {...p} {...props} pageTitle='Reset Password' />
				)}
			/>
			<Route
				exact
				path='/invite-crew'
				render={p => (
					<InviteCrewPage {...p} {...props} pageTitle='Invite Crew' />
				)}
			/>
			<Route
				exact
				path='/approve-crew'
				render={p => (
					<DataTablePage
						{...p}
						{...props}
						pageTitle='Approve Crews'
						tableTitle='Onboarding Approval'
						id='crews'
						columns={crewApprovalCols}
						data={approvalData}
						handleData={handleApprovalData}
					/>
				)}
			/>
			<TransactionRoutes path='/transactions' {...props} />
			<Route
				exact
				path='/logs'
				render={p => (
					<UserLogsPage
						{...p}
						{...props}
						pageTitle='User Logs'
						logData={logData}
						handleLogData={handleLogData}
					/>
				)}
			/>
			<Route
				exact
				path='/resetCrewPassword'
				render={p => <AdminCrewPasswordResetPage />}
			/>
			{/* <Route
				exact
				path='/management'
				render={p => (
					<ManagementPage
						{...p}
						{...props}
						pageTitle='Employee Activity (Stacked)'
						data={empActivityData}
						query={eaQuery}
						graph={eaGraph1}
						handleChange={handleChange}
						handleData={handleEmployeeActivity}
					/>
				)}
			/> */}
			{currentUser.smi_admin && (
				<>
					<Route
						exact
						path='/management/stacked'
						render={p => (
							<VerticalBarChartPage
								{...p}
								{...props}
								pageTitle='Employee Activity (Stacked)'
								fromTo={true}
								data={empActivityData}
								query={eaQuery}
								graph={eaGraph1}
								handleEditedAt={handleEmpEditedAt}
								handleChange={handleEmpChange}
								handleData={handleEmployeeActivity}
								filterEditTypes={true}
								EditTypes={empEditTypes}
								filterEditUsers={true}
								EditUsers={empUsers}
								handleRawData={handleEmployeeRawData}
							/>
						)}
					/>
					<Route
						exact
						path='/management/grouped'
						render={p => (
							<VerticalBarChartPage
								{...p}
								{...props}
								pageTitle='Employee Activity (Grouped)'
								fromTo={true}
								data={empActivityData}
								query={eaQuery}
								graph={eaGraph2}
								handleEditedAt={handleEmpEditedAt}
								handleChange={handleEmpChange}
								handleData={handleEmployeeActivity}
								handleRawData={handleEmployeeRawData}
							/>
						)}
					/>
					<Route
						exact
						path='/management/weekly-activity'
						render={p => (
							<VerticalBarChartPage
								{...p}
								{...props}
								pageTitle='Weekly Activity (Grouped)'
								fromTo={true}
								data={weeklyActivityData}
								query={waQuery}
								graph={waGraph}
								handleEditedAt={handleWeeklyEditedAt}
								handleChange={handleWeeklyActivityChange}
								handleData={handleWeeklyActivity}
								filterEditTypes={true}
								EditTypes={weeklyEditTypes}
								filterEditUsers={true}
								EditUsers={weeklyUsers}
								filterAutoProc={true}
								handleRawData={handleWeeklyRawData}
							/>
						)}
					/>
				</>
			)}

			{/* <Route path='*' render={() => <FourOFour />} /> */}
		</>
	);
};

export default EmployeeRoutes;
