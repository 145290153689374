import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
	AssessmentOutlined,
	Assignment,
	AssignmentIndOutlined,
	/* Assistant, */
	Dashboard,
	ExitToApp,
	GroupOutlined,
	GroupAddOutlined,
	PeopleOutlined,
	SettingsOutlined,
	WorkOutlined
} from '@material-ui/icons';

import { useDrawer } from '../../context/drawer';
import { useUser } from '../../context/user';

//--------------------------- Reusable Components ----------------------------//
// eslint-disable-next-line
const Item = ({ path, label, icon, onClick, strong }) => {
	let history = useHistory();
	const { pathname } = history.location;
	const [canRedirect, setCanRedirect] = useState(true);

	const redirect = () => {
		path && canRedirect && history.push(path);
	};

	const clickHandler = () => {
		onClick ? onClick() : redirect();
	};

	useEffect(() => {
		if (pathname === path) {
			setCanRedirect(false);
		}
	}, [path, pathname]);

	return (
		<ListItem button onClick={() => clickHandler()}>
			<ListItemIcon>{icon}</ListItemIcon>
			<ListItemText primary={strong ? <strong>{label}</strong> : label} />
		</ListItem>
	);
};

//-------------------------------- Employees ---------------------------------//
export const EmployeeItems = props => {
	let history = useHistory();
	let { currentUser } = useUser();
	const { 
		drawerOpen, 
		toggleTrans, 
		setToggleTrans,
		toggleCrews, 
		setToggleCrews, 
		toggleOnboarding, 
		setToggleOnboarding,
		toggleManagement,
		setToggleManagement 
	} = useDrawer();

	const handleTrans = () => {
		drawerOpen ? setToggleTrans() : history.push('/transactions/outbound');
	};

	const handleCrews = () => {
		drawerOpen ? setToggleCrews() : history.push('/crews');
	};

	const handleOnboarding = () => {
		drawerOpen ? setToggleOnboarding() : history.push('/approve-crew');
	};

	const handleManagement = () => {
		drawerOpen ? setToggleManagement() : history.push('/management/stacked');
	}

	return (
		<>
			<Item
				strong
				{...props}
				label='Crews'
				icon={<GroupOutlined />}
				onClick={handleCrews}
			/>
			{toggleCrews && (
				<>
					<Item {...props} path='/crews' label='Crews List' />
					<Item
						{...props}
						path='/crews/create-logins'
						label='Create User Logins'
					/>
					<Item
						{...props}
						path='/crews/reset-password'
						label='Reset Password'
					/>
				</>
			)}

			<Item
				strong
				{...props}
				label='Crew Onboarding'
				icon={<GroupAddOutlined />}
				onClick={handleOnboarding}
			/>
			{toggleOnboarding && (
				<>
					<Item {...props} path='/invite-crew' label='Invite Crew' />
					<Item {...props} path='/approve-crew' label='Approve Crews' />
				</>
			)}

			<Item
				strong
				{...props}
				label='Transactions'
				icon={<AssessmentOutlined />}
				onClick={handleTrans}
			/>
			{toggleTrans && (
				<>
					<Item {...props} path='/transactions/inbound' label='To Portal' />
					<Item {...props} path='/transactions/outbound' label='From Portal' />
				</>
			)}
			<Item
				strong
				{...props}
				path='/logs'
				label='User Logs'
				icon={<AssignmentIndOutlined />}
			/>
			{currentUser.smi_admin && (
				<>
					<Item
						strong
						{...props}
						label='Management'
						icon={<WorkOutlined />}
						onClick={handleManagement}
					/>
					{toggleManagement && (
						<>
							<Item
								{...props}
								label='Activity (Stacked)'
								path='/management/stacked'
							/>
							<Item
								{...props}
								label='Activity (Grouped)'
								path='/management/grouped'
							/>
							<Item
								{...props}
								label='Weekly Activity'
								path='/management/weekly-activity'
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

//---------------------------- Unrestricted Crews ----------------------------//
export const VerifiedCrewItems = props => {
	let history = useHistory();
	const { drawerOpen, toggleWOs, setToggleWOs } = useDrawer();
	const { currentUser:{ CrewCode } } = useUser();

	const handleWOs = () => {
		drawerOpen ? setToggleWOs() : history.push('/workOrders/open');
	};

	return (
		<>
			<Item
				strong
				{...props}
				label='Work Orders'
				icon={<Assignment />}
				onClick={handleWOs}
			/>
			{toggleWOs && (
				<>
					<Item {...props} path='/workOrders/schedule' label='To Schedule' />
					<Item {...props} path='/workOrders/close' label='To Close' />
					<Item {...props} path='/workOrders/open' label='View Open' />
					<Item {...props} path='/workOrders/all' label='View All' />
				</>
			)}
			{/* <Item strong {...props} path='/rfp' label='RFPs' icon={<Assistant />} /> */}
			<Item
				strong
				{...props}
				path='/profile'
				label={`My Profile (${CrewCode})`}
				icon={<PeopleOutlined />}
			/>
		</>
	);
};

export const Navigation = props => {
	const { authorization } = useUser();
	return (
		<List>
			<Item strong {...props} path='/' label='Home' icon={<Dashboard />} />
			{authorization === 'S' && <EmployeeItems {...props} />}
			{authorization === 'V' && <VerifiedCrewItems {...props} />}
		</List>
	);
};

export const Options = props => {
	const { logOut } = useUser();
	let history = useHistory();
	const { pathname } = history.location;
	
	const handleLogOut = () => {
		logOut();
		if (pathname !== '/') {
			history.push('/');
		}
	}

	const settings = false;
	return (
		<List>
			{ settings && (
				<Item label='Settings' icon={<SettingsOutlined />} />
			)}
			<Item
				strong
				label='Log Out'
				icon={<ExitToApp />}
				onClick={handleLogOut}
			/>
		</List>
	);
};
