//----------------------------------------- Dependencies -----------------------------------------//
import { useEffect, useState } from 'react';

//------------------------------------------ Components ------------------------------------------//
import Dashboard from '../../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../../components/dashboard/Dashboard.style';
import Table from '../../../components/tables/EnhancedTable';
import {
	Modal,
	Box,
	Grid,
	Button,
	Tab,
	Tabs,
	Typography
} from '@material-ui/core';
import { newNote } from '../../../services/realm/vendors.js';
import { ScheduleWOForm } from '../columns/ScheduleWOForm.js';
import { CloseWOForm } from '../columns/closeWOForm.js';
import { UploadFile } from '../columns/uploadFile.js';
import { useUser } from '../../../context/user';
// close work order form will be imported here

const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'clamp(200px, 60% ,660px)',
	height: 'clamp(200px, 60% ,400px)',
	backgroundColor: '#fff',
	borderRadius: '10px',
	boxShadow: '0px 0px 10px #000',
	padding: '10px'
};

const TabPanel = props => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 1 }}>{children}</Box>}
		</div>
	);
};

const WorkOrders = props => {
	// eslint-disable-next-line
	const { updateData, pageTitle, ...otherProps } = props;
	const { currentUser } = useUser();
	// console.log(currentUser.CrewCode)

	const [viewModal, setViewModal] = useState(false);
	const [activeRow, setActiveRow] = useState({});
	const [activeTab, setActiveTab] = useState(0);
	const [noteText, setNoteText] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');

	const selectRow = row => {
		setActiveRow(row);
		setViewModal(true);
		setNoteText('');
	};

	const deselectRow = () => {
		setActiveRow({});
		setViewModal(false);
		setNoteText('');
	};

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const sendNote = async () => {
		setError('');
		setMessage('');
		if (noteText.length > 0) {
			const { Message, Error } = await newNote({
				CrewCode: currentUser.CrewCode,
				UserID: currentUser.userName,
				UserRecId: currentUser.RecId,
				smiWO: activeRow.original.smiWO,
				StoreName: activeRow.original.storeName,
				noteText
			});
			if (!Error) {
				setMessage(Message);
				setNoteText('');
			} else {
				setMessage('');
				setError(Error);
			}
		} else {
			setError('Please Enter a Note First.');
		}
	};

	// useEffect(() => {
	// 	handleWOs();
	// }, [handleWOs]);

	useEffect(() => {
		if (message.length > 0) {
			setTimeout(() => {
				setMessage('');
				setError('');
			}, 5000);
		}
	}, [message]);

	useEffect(() => {
		if (error.length > 0) {
			setTimeout(() => {
				setError('');
			}, 5000);
		}
	}, [error]);

	const classes = dashboardStyles();

	return (
		<>
			<Dashboard classes={classes} pageTitle={pageTitle} fullPage={true}>
				<Table
					{...otherProps}
					updateData={updateData}
					selectRow={selectRow}
				/>
			</Dashboard>
			<Modal open={viewModal} onClose={() => deselectRow()}>
				<Box style={modalStyle}>
					<Grid container>
						{activeRow.original && (
							<>
								<Grid item xs={12}>
									<Typography variant='h5' align='center'>
										WO# {activeRow.original.smiWO || 'N/A'}
									</Typography>
									<Typography variant='subtitle1' align='center'>
										{' '}
										{activeRow.original.custWO || ''}
									</Typography>
								</Grid>
								<Box>
									<Box>
										<Tabs
											value={activeTab}
											onChange={handleTabChange}
											aria-label='basic tabs example'
										>
											<Tab label='Send SMI a Note' />
											<Tab label='Schedule' />
											<Tab label='Close' />
											<Tab label='Upload' />
										</Tabs>
									</Box>
									<TabPanel value={activeTab} index={0}>
										<Box style={{ margin: '10px' }}>
											<Typography variant='body2'>Note:</Typography>
											<textarea
												value={noteText}
												style={{
													margin: '5px',
													width: '100%',
													height: '100px'
												}}
												onChange={e => setNoteText(e.target.value)}
											/>
											<br />
											{message && <p>{message}</p>}
											{error && (
												<p style={{ color: 'red' }}>
													<strong>{error}</strong>
												</p>
											)}
										</Box>
										<Button variant='contained' onClick={() => sendNote()}>
											Submit
										</Button>
									</TabPanel>

									<TabPanel value={activeTab} index={1}>
										<ScheduleWOForm {...activeRow} />
									</TabPanel>

									<TabPanel value={activeTab} index={2}>
										<CloseWOForm {...activeRow} />
									</TabPanel>

									<TabPanel value={activeTab} index={3}>
										<UploadFile {...activeRow} updateData={updateData} />
									</TabPanel>
								</Box>
							</>
						)}
					</Grid>
				</Box>
			</Modal>
		</>
	);
};

export default WorkOrders;
