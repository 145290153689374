import React, { useContext, useReducer, useEffect, useCallback } from 'react';
import { getAllUsers } from '../services/realm/employees';

const initialState = [];

const actions = {
	SET_CREW_USERS: 'SET_CREW_USERS',
	REMOVE_CREW_USERS: 'REMOVE_CREW_USERS'
};

const reducer = (state, action) => {
	switch (action.type) {
		case actions.SET_CREW_USERS:
			return action.payload;
		case actions.REMOVE_CREW_USERS:
			return [];
		default:
			return state;
	}
};

const CrewUsersContext = React.createContext();

export const CrewUsersContextProvider = props => {
	const [crewUsers, dispatch] = useReducer(reducer, initialState);

	const setCrewUsers = crewUsers => {
		dispatch({
			type: actions.SET_CREW_USERS,
			payload: crewUsers
		});
	};

	const removeCrewUsers = () => {
		dispatch({
			type: actions.REMOVE_CREW_USERS
		});
	};

	const handleCrewUsers = useCallback(async () => {
		const data = await getAllUsers();
		setCrewUsers(data);
	}, []);

	useEffect(() => {
		handleCrewUsers();
        return () => {
            removeCrewUsers();
        }
	}, [handleCrewUsers]);

	const value = { crewUsers, setCrewUsers, removeCrewUsers };

	return (
		<CrewUsersContext.Provider value={value}>
			{props.children}
		</CrewUsersContext.Provider>
	);
};

export const useCrewUsersContext = () => {
	const context = useContext(CrewUsersContext);
	if (!context) {
		throw new Error(
			'useCrewUsersContext must be used within a CrewUsersContextProvider'
		);
	}
	return context;
};
