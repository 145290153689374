import axios from 'axios';
/* DEVELOPMENT URL */
// const baseURL = 'http://localhost:3000';

/* PRODUCTION URL */
const baseURL = process.env.REACT_APP_SMI_FR_API;
const smiFrApiKey = process.env.REACT_APP_SMI_FR_API_KEY;

const newAPI = axios.create({ baseURL });

export const postDocument = async (CrewCode, form) => {
	const headers = { 'Content-Type': 'multipart/form-data' };
	const data = await newAPI.post(`/documents/${CrewCode}`, form, headers);
	return data;
};

export const getWorkOrderPDF = async ( CrewCode, smiWO ) => {
	const filename = `${smiWO}.pdf`

	const { data, error } = await newAPI.get( 
		`/woPDFs/${CrewCode}/${filename}`, 
		{ 
			responseType: 'blob', 
			headers: {
				'api-key': smiFrApiKey
			} 
		} 
	);

	if (error){
		return error;
	}

	if (data.type === 'application/json'){
		const rawData = await data.text();
		const json = typeof rawData === 'string' ? JSON.parse(rawData) : rawData;
		return json;
	}

	if (data.type === 'application/pdf'){
		return downloadOnClick( data, filename);
	}

}


export const downloadOnClick = (blob, filename) => {
	let fn = 'downloadOnClick'
	try{
		if (!blob instanceof Blob){
			throw new Error(`fn: ${fn}, First argument must be  a Blob.`)
		} else {
			let a = document.createElement('a');
			a.style = 'display: none';
			document.body.appendChild(a);
			let url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = filename;
			a.click();
			window.URL.revokeObjectURL(url);
			a.remove();
			return { message: `Successfully downloaded ${filename}` }
		}
	} catch (error){
		return { error }
	}
}