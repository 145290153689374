import { postDocument } from './index';

const isPic = file => {
	let ext = file.name.split('.').pop();
	let pic = ext === 'jpg' || ext === 'jpeg' || ext === 'png';
	return pic;
};

export const uploadWOFile = async (
	file,
	smiWO,
	CrewCode,
	RecId,
	entityRecId,
	userName,
	DocType,
	StoreName
) => {

	let picture = isPic(file);

	console.log('uploadFile:', file)

	let form = new FormData();
	form.append('File', file);
	form.append('SourceTable', 'Workorders');
	form.append('SourceRecId', smiWO);
	form.append('DocPath', `/crew_doc/${CrewCode}/`);
	form.append('DocTitle', 'crew_upload');
	form.append('DocType', DocType ||'workorderDocument');
	form.append('Comments', '');
	form.append('IsPic', picture);
	form.append('SiteUse', 1);
	form.append('UserRecId', RecId);
	form.append('UserID', userName);
	form.append('VendorId', entityRecId);
	form.append('StoreName', StoreName);

	// for (var value of form.values()) {
	// 	console.log(value);
	// }

	const { data } = await postDocument(CrewCode, form);
	return data;
};


export const uploadCrewFile = async (
	file,
	CrewCode,
	RecId,
	entityRecId,
	userName,
	DocType
) => {

	let picture = isPic(file);

	let form = new FormData();
	form.append('File', file);
	form.append('SourceTable', 'Vendors');
	form.append('SourceRecId', entityRecId);
	form.append('DocPath', `/crew_doc/${CrewCode}/`);
	form.append('DocTitle', 'crew_upload');
	form.append('DocType', DocType || 'crewDocument');
	form.append('Comments', '');
	form.append('IsPic', picture);
	form.append('SiteUse', 1);
	form.append('UserRecId', RecId);
	form.append('UserID', userName);
	form.append('VendorId', entityRecId);
	// for (var value of form.values()) {
	// console.log(value);
	// }

	const { data } = await postDocument(CrewCode, form);
	return data;
};