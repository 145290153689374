import {
	FormControl,
	Grid,
	InputLabel,
	Select,
	TextField
} from '@material-ui/core';

export const NativeSelect = props => {

	let {
		formatControlProps,
		gridProps,
		labelProps,
		selectProps,
		onChange,
		error,
		id,
		label,
		name,
		options,
		defaultValue,
		InputProps,
		value,
		style,
		disabled
	} = props;


	if (InputProps.readOnly){
		disabled = InputProps.readOnly;
	}
	return (
		<Grid 
			{...gridProps} 
			item 
			container 
			direction='row' 
			justifyContent='center'
		>
			<FormControl 
				{...formatControlProps} 
				style={style} 
			>
				<InputLabel {...labelProps} shrink htmlFor={id}>
					{label}
				</InputLabel>
				<Select
					{...selectProps}
					native
					id={id}
					name={name}
					defaultValue={defaultValue}
					value={value}
					onChange={onChange}
					error={error}
					disabled={disabled}
				>
					{options.map((opt, index) => {
						return (
							<>
								{typeof opt === 'object' && (
									<option id={opt.id} key={`${id}-${opt.id}`} value={opt.value}>
										{opt.label}
									</option>
								)}
								{typeof opt === 'string' && <option value={opt}>{opt}</option>}
							</>
						);
					})}
				</Select>
			</FormControl>
		</Grid>
	);
};


export const Input = props => {
	let { gridProps, ...other } = props;

	return (
		<Grid item {...gridProps}>
			<TextField
				{...other}
				variant='outlined'
				size='small'
				margin='dense'
				InputLabelProps={{
					shrink: true
				}}
				fullWidth
			/>
		</Grid>
	);
};
