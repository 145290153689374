import axios from 'axios';

const baseURL = process.env.REACT_APP_REALM_API_URL;

const smiApi = axios.create({ baseURL });

const smiApiPost = async (endpoint, payload) => {
	try {
		const headers = { 'Content-Type': 'application/json' };
		const data = await smiApi.post(endpoint, payload, headers);
		return data;
	} catch (error) {
		return { error };
	}
};

const RECOVERY = '/account/recovery';
const RESET = '/account/reset';

export const recoverUserAccount = async payload => {
	try {
		const data = await smiApiPost(RECOVERY, payload);
		return data ;
	} catch (err) {
		return { error: 'Failed to update user info' };
	}
};

export const resetUserAccount = async payload => {
	try {
		const data = await smiApiPost(RESET, payload);
		return data;
	} catch (err) {
		return { error: 'Failed to update user info' };
	}
};
