export const types = [
	{
		label: '',
		value: ''
	},
	// {
	// 	label: 'Crew Packet',
	// 	value: 'crewPacket'
	// },
	{
		label: 'Code of Conduct',
		value: 'codeOfConduct'
	},
	// {
	// 	label: 'Crew Information',
	// 	value: 'crewInformation'
	// },
	// {
	// 	label: 'Crew Qualifications',
	// 	value: 'crewQualifications'
	// },
	{
		label: 'Certificate of Liability',
		value: 'certificateOfLiability'
	},
	// {
	// 	label: 'Subcontractor Agreement',
	// 	value: 'subcontractorAgreement'
	// },
	{
		label: 'W-9',
		value: 'w9'
	},
	// {
	// 	label: 'Worker’s Compensation COEW',
	// 	value: 'workersCompensation'
	// }
	{
		label: 'Canada Replacement for W9',
		value: 'canadaW9'
	},
	{
		label: 'MSA',
		value: 'msa'
	}
];

export const newDownloads = [
	// {
	// 	file: 'Crew_Onboarding_Packet.pdf',
	// 	label: 'Crew Packet (AllDocs)',
	// 	docType: 'crewPacket'
	// },
	{
		file: 'Code_of_Conduct.pdf',
		label: 'Code of Conduct',
		docType: 'codeOfConduct'
	},
	// {
	// 	file: 'Crew_Information.pdf',
	// 	label: 'Crew Information',
	// 	docType: 'crewInformation'
	// },
	// {
	// 	file: 'Crew_Qualifications.pdf',
	// 	label: 'Crew Qualifications',
	// 	docType: 'crewQualifications'
	// },
	{
		file: 'Sample_COI.pdf',
		label: 'Sample Certificate of Insurance',
		docType: 'certificateOfLiability'
	},
	// {
	// 	file: 'Subcontractor_Agreement.pdf',
	// 	label: 'Subcontractor Agreement',
	// 	docType: 'subcontractorAgreement'
	// },
	{
		file: 'W-9.pdf',
		label: 'W-9',
		docType: 'w9'
	},
	// {
	// 	file: "Worker's_Compensation_Certificate_of_Exemption_and_Waiver.pdf",
	// 	label: 'Worker’s Compensation Certificate of Exemption and Waiver',
	// 	docType: 'workersCompensation'
	// }
	{
		file: 'Canada_Replacement_for_W9.pdf',
		label: 'Canada Replacement for W9',
		docType: 'canadaW9'
	},
	{
		file: 'MSA.pdf',
		label: 'MSA',
		docType: 'msa'
	}
];

export default types;
