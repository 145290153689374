import React, { useState, useLayoutEffect } from 'react';

import { Grid, TextField, Tooltip, IconButton } from '@material-ui/core';
import Dashboard from '../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../components/dashboard/Dashboard.style.js';
import Vertical from '../../components/charts/Bar/Vertical';
import {NativeSelect} from '../../components/forms/NativeSelect'
import { Excel } from '../../components/tables/toolbar/Downloads.js';

import { RiFileExcelLine } from 'react-icons/ri';









let twoDigit = num => {
	if (typeof num === 'string') {
		num = parseInt(num);
	}
	return num < 10 ? `0${num}` : `${num}`;
};

const formatDateTime = date => {
	let d = new Date(date);
	let yyyy = d.getFullYear();
	let MM = twoDigit(d.getMonth() + 1);
	let dd = twoDigit(d.getDate());
	let hh = twoDigit(d.getHours());
	let mm = twoDigit(d.getMinutes());

	return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
};

const Management = props => {
	const {
		data,
		graph,
		handleEditedAt,
		handleChange,
		handleData,
		query,
		fromTo,
		filterEditTypes,
		EditTypes,
		filterEditUsers,
		EditUsers,
		filterAutoProc,
		handleRawData
	} = props;

	const [filteredEditTypes, setFilteredEditTypes] = useState([]);
	const [filteredEditUsers, setFilteredEditUsers] = useState([]);
	const [autoProc, setAutoProc ] = useState('No');



	const handleSelect = (value, filters, setFilter) => {
		let array = [];

		if ( filters.includes(value) ) {
			array = filters.filter(filter => filter !== value);
		} else if ( value !== '' ) {
			array = [...filters, value];
		} 

		setFilter(array)
		return array;

	}

	//---------------------------- Error Handling ----------------------------//
	// eslint-disable-next-line
	const [warningMsg, setWarningMsg] = useState(null);

	//------------------------------ UI Context ------------------------------//
	const classes = dashboardStyles();

	useLayoutEffect(() => {
		handleData();
		return () => {};
	}, [handleData]);

	const selectChange = (event, filters, setFilters) => {
		const { name, value } = event.target;
		let changes = handleSelect( value, filters, setFilters )
		handleChange(name, changes)
	};

	const autoProcChange = (event) => {
		const { name, value } = event.target;
		setAutoProc(value);
		let change = value === 'No' ? 0 : { $gte: 0 };
		console.log(name, change)
		handleChange(name, change);
	}


	return (
		<>
			<Dashboard {...props} classes={classes} warningMsg={warningMsg} fullPage>
				<div>
					<Grid container direction='row-reverse'>
						<Grid item>
							<Tooltip
								title='Download Raw Data to Excel'
								onClick={handleRawData}
							>
								<IconButton aria-label='Download Raw Data to Excel'>
									<RiFileExcelLine color='#8eb7a1' />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item>
							<Excel data={data} docId={'activity'} />
						</Grid>
					</Grid>
					<Grid
						container
						direction='row'
						spacing={3}
						justifyContent='center'
						alignContent='center'
					>
						{fromTo && (
							<>
								<Grid item>
									<TextField
										type='datetime-local'
										name='$gte'
										label='From'
										value={formatDateTime(query.EditedAt.$gte)}
										InputLabelProps={{ shrink: true }}
										onChange={handleEditedAt}
									/>
								</Grid>
								<Grid item>
									<TextField
										type='datetime-local'
										name='$lte'
										label='To'
										value={formatDateTime(query.EditedAt.$lte)}
										InputLabelProps={{ shrink: true }}
										onChange={handleEditedAt}
									/>
								</Grid>
							</>
						)}
						{filterEditTypes && (
							<Grid item>
								<NativeSelect
									selectProps={{ multiple: true, style: { width: '175px' } }}
									id='EditType'
									name='EditType'
									label='Edit Type'
									options={EditTypes}
									value={filteredEditTypes}
									onChange={e =>
										selectChange(e, filteredEditTypes, setFilteredEditTypes)
									}
								/>
							</Grid>
						)}
						{filterEditUsers && (
							<Grid item>
								<NativeSelect
									selectProps={{ multiple: true, style: { width: '125px' } }}
									id='EditUserID'
									name='EditUserID'
									label='Edit Users'
									options={EditUsers}
									value={filteredEditUsers}
									onChange={e =>
										selectChange(e, filteredEditUsers, setFilteredEditUsers)
									}
								/>
							</Grid>
						)}
						{filterAutoProc && (
							<Grid item>
								<NativeSelect
									selectProps={{ style: { width: '75px' } }}
									id='AutoProc'
									name='AutoProc'
									label='Auto Proc'
									options={['No', 'Yes']}
									value={autoProc}
									onChange={autoProcChange}
								/>
							</Grid>
						)}
					</Grid>

					<Vertical {...graph} data={data} />
				</div>
			</Dashboard>
		</>
	);
};

export default Management;
