import {
	useTable,
	useGlobalFilter,
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
} from 'react-table';

import DefaultColumnFilter from './filters/column'

export const AsyncConfig = ({
	columns,
	data,
	defaultColumn={ Filter: DefaultColumnFilter }, 
	initialState={ pageSize: 25},
	updateData,
}) => {
	const table = useTable(
		{
			columns,
			data,
			defaultColumn,
			initialState,
			updateData,
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
	);
	return table;
};

export default AsyncConfig;
