import Button from '@material-ui/core/Button';

export const ContainedButton = ({children,...props}) => {
	return (
		<Button variant="contained" {...props}>
		{children}
		</Button>
	);
};

export const SubmitButton = props => {
	const { value, ...other } = props;
	return (
		<ContainedButton
			{...other} 
			type='submit' 
			color='primary'
		>
			{value}
		</ContainedButton>
	);
};
