import React from 'react';
import clsx from 'clsx';
import { Drawer, Divider, IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import { Navigation, Options, } from './listItems';

import { useDrawer } from '../../context/drawer';

const AppDrawer = props => {
	const { classes } = props;
	const { drawerOpen, setDrawerOpen } = useDrawer();
	return (
		<>
			<Drawer
				variant='permanent'
				classes={{
					paper: clsx(
						classes.drawerPaper,
						!drawerOpen && classes.drawerPaperClose
					)
				}}
				open={drawerOpen}
			>
				<div className={classes.toolbarIcon}>
					<IconButton onClick={() => setDrawerOpen()}>
						<ChevronLeft />
					</IconButton>
				</div>
				<Divider />
				<Navigation />
				<Divider />
				<Options />
			</Drawer>
		</>
	);
};

export default AppDrawer;
