import { useEffect, useState } from 'react';
import axios from 'axios';
import { MultiSelectColumnFilter } from '../../../components/tables/filters/column';
import { StatusCell } from '../../../components/tables/cells/StatusCell';
import { closeWO, trackUploads } from '../../../services/realm/vendors';
import { formatCurrency } from '../../../helpers/stringFormat';
import DownloadPDF from '../../../components/tables/cells/DownloadsPDF';
import { TextField, Input, Button, Typography } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { useUser } from '../../../context/user';

/* DEVELOPMENT URL */
// const newUrl = 'http://localhost:3000/documents'

/* PRODUCTION URL */
const smiUploadApi = process.env.REACT_APP_SMI_FR_API;
const newUrl = smiUploadApi + '/documents';

const mb = 1048576;
const maxFileSize = 5 * mb;

const acceptedMimeTypes = [
	'image/jpeg',
	'image/png',
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const newAPI = axios.create({
	baseURL: newUrl
});

const postDocument = async (CrewCode, newForm) => {
	const headers = { 'Content-Type': 'multipart/form-data' };
	const newData = await newAPI.post(`/${CrewCode}`, newForm, headers);
	return newData; //|| oldData;
};

const formInputs = [
	{
		name: 'invoiceNumber',
		label: 'INV #',
		type: 'text'
	},
	{
		name: 'invoiceAmount',
		label: 'INV Amount',
		type: 'number'
	},
	{
		name: 'completionDate',
		label: 'Completion Date',
		type: 'date',
		required: true
	}
];

const Container = props => {
	const { children, ...style } = props;

	return <div style={style}>{props.children}</div>;
};

const FlexRow = props => {
	const { children, ...other } = props;
	return (
		<Container {...other} display='flex' flexDirection='row'>
			{children}
		</Container>
	);
};

const FlexColumn = props => {
	const { children, ...other } = props;
	return (
		<Container {...other} display='flex' flexDirection='column'>
			{children}
		</Container>
	);
};

const messageColor = messageType => {
	switch (messageType) {
		case 'waiting':
			return '#555555';
		case 'success':
			return '#4BB543';
		case 'error':
			return '#ED4337';
		default:
			return '#OOOOOO';
	}
};

const FormInput = props => {
	return (
		<span>
			<TextField
				{...props}
				fullWidth
				size='small'
				variant='outlined'
				margin='normal'
				style={{ width: props.width || '150px', marginRight: '10px' }}
				InputLabelProps={{ shrink: true }}
			/>
		</span>
	);
};

const FormMessage = props => {
	const { children, marginTop, strong, type } = props;
	return (
		<div>
			<Typography
				align='center'
				variant='subtitle2'
				style={{
					marginTop: marginTop || '0',
					marginBottom: '1px',
					color: messageColor(type)
				}}
			>
				{strong ? <strong>{children}</strong> : children}
			</Typography>
		</div>
	);
};

const formatDate = date => {
	const [yyyy, mm, dd] = date.split('-');
	return `${mm}/${dd}/${yyyy}`;
};

const CloseForm = props => {
	let { original } = props.row;
	const { smiWO, storeName, uploads, lastUpload } = original;
	const { updateData } = props;
	const { currentUser } = useUser();
	const { entityRecId, RecId, userName, CrewCode } = currentUser;

	const [lastFile, setLastFile] = useState('');
	const [closeDisabled, setCloseDisabled] = useState(true);

	useEffect(() => {
		if (lastUpload) {
			setLastFile(lastUpload);
			setCloseDisabled(false);
		}
	}, [lastUpload]);

	const [formState, setFormState] = useState({
		submitting: false,
		submitted: false,
		updated: false,
		failed: false,
		invoiceNumber: '',
		invoiceAmount: '',
		completionDate: ''
	});

	const handleCloseWOChangeEvent = event => {
		const { name, value } = event.target;
		setFormState({ ...formState, [name]: value });
	};

	const [uploadState, setUploadState] = useState({
		selected: false,
		submitting: false,
		submitted: false,
		updated: false,
		failed: false,
		file: {},
		uploadError: '',
		message: ''
	});

	const handleUploadChangeEvent = event => {
		const { name, files } = event.target;
		const file = files[0];

		if (file.size > maxFileSize) {
			setUploadState({
				...uploadState,
				uploadError: 'File must be smaller than 5MB.'
			});
			return false;
		}

		if (!acceptedMimeTypes.includes(file.type)) {
			setUploadState({
				...uploadState,
				uploadError: 'File type is not supported.'
			});
			return false;
		}

		if (/\.[A-Z]+$/.test(file.name)){
			let ext = file.name.match(/\.[A-Z]+$/)[0];
			setUploadState({
				...uploadState,
				uploadError: `File extension must be lower cased. Please change '${ext}' to '${ext.toLowerCase()}' and try again.`
			});
			return false;
		}

		setUploadState({ ...uploadState, [name]: file, uploadError: '' });
	};

	const begin = { submitting: true, failed: false };
	const success = { submitting: false, submitted: true };
	const update = { submitting: false, updated: true };
	const error = { submitting: false, failed: true };

	const handleUploadFile = async e => {
		e.preventDefault();
		setUploadState({ ...uploadState, ...begin });

		let file = e.target[0].files[0];

		// console.log(file)

		let newForm = new FormData();
		newForm.append('File', file);
		newForm.append('SourceTable', 'Workorders');
		newForm.append('SourceRecId', smiWO);
		newForm.append('DocPath', `/crew_doc/${CrewCode}/`);
		newForm.append('DocTitle', 'crew_upload');
		newForm.append('DocType', 'workorderDocument');
		newForm.append('Comments', '');
		newForm.append('IsPic', 0);
		newForm.append('SiteUse', 1);
		newForm.append('UserRecId', RecId);
		newForm.append('UserID', userName);
		newForm.append('VendorId', entityRecId);
		newForm.append('StoreName', storeName);

		// for (var value of newForm.values()) {
		// 	console.log(value);
		// }

		const response = await postDocument(CrewCode, newForm);
		console.log(response)
		const {data} = response;
		if (data) {
			setUploadState({ ...uploadState, ...success, message: data });
			setLastFile(uploadState.file.name);
			let payload = {
				query: {
					VendorCode: CrewCode,
					SMI_WO: smiWO
				}
			};
			let upload = {
				file: uploadState.file.name,
				message: data
			};
			original.lastUpload = uploadState.file.name;
			if (uploads && uploads.length > 0) {
				payload.changes = { $push: { uploads: upload } };
				original.uploads = [...uploads, upload];
			} else {
				payload.setting = { uploads: [upload] };
				original.uploads = [upload];
			}

			// console.log('original:',original)
			updateData(original);
			trackUploads(payload);
		} else {
			setUploadState({
				...uploadState,
				...error,
				message: 'Error uploading file'
			});
		}
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setFormState({ ...formState, ...begin });

		const { invoiceNumber, invoiceAmount, completionDate, submitted } =
			formState;

		const obj = {
			smiWO,
			vendorCode: CrewCode,
			UserID: userName,
			UserRecId: RecId,
			invoiceNumber,
			StoreName: storeName,
			invoiceAmount: parseFloat(invoiceAmount),
			completionDate: formatDate(completionDate)
		};

		const { data } = await closeWO(obj);

		if (data) {
			if (!submitted) {
				setFormState({ ...formState, ...success });
			} else {
				setFormState({ ...formState, ...update });
			}
		} else {
			setFormState({ ...formState, ...error });
		}
	};

	return (
		<>
			<FlexRow flexWrap='nowrap' justifyContent='space-evenly'>
				{/* Upload File Form */}
				<FlexColumn
					flexWrap='nowrap'
					justifyContent='space-around'
					minWidth='200px'
				>
					{lastFile !== '' && 'Last Upload ' + lastFile}
					<form
						onSubmit={e => handleUploadFile(e)}
						style={{
							display: 'flex',
							flexDirection: 'column',
							flexWrap: 'nowrap',
							alignItems: 'center',
							justifyContent: 'space-around',
							margin: '5px'
						}}
						name='UploadDoc'
					>
						<div>
							<label htmlFor={`file-upload-button-${smiWO}-t`}>
								<Input
									id={`file-upload-button-${smiWO}-t`}
									type='file'
									name='file'
									style={{ display: 'none' }}
									multiple={false}
									onChange={handleUploadChangeEvent}
								/>
								<Button
									variant='contained'
									component='span'
									size='small'
									color='primary'
									// style={{ marginTop: '15px' }}
								>
									Choose File
								</Button>
							</label>
						</div>
							<FormMessage strong type='error'>
								{uploadState.uploadError}
							</FormMessage>


						{uploadState.file.name && (
							<>
								<FormMessage type='waiting' marginTop='5px'>
									{uploadState.file.name.length > 16
										? uploadState.file.name.slice(0, 16) + '...'
										: uploadState.file.name}
								</FormMessage>
								<div>
									<Button
										type='submit'
										variant='contained'
										size='small'
										color='primary'
										style={{ marginTop: '10px' }}
										endIcon={<Send />}
									>
										Upload
									</Button>
								</div>
							</>
						)}
					</form>
					{uploadState.submitting && (
						<FormMessage strong type='waiting'>
							Attempting to upload Image...
						</FormMessage>
					)}
					{!uploadState.submitting &&
						uploadState.submitted &&
						!uploadState.failed && (
							<FormMessage strong type='success'>
								{uploadState.message}
							</FormMessage>
						)}
					{!uploadState.submitting && uploadState.failed && (
						<FormMessage strong type='error'>
							Failed to upload Image
						</FormMessage>
					)}
				</FlexColumn>

				{/* Close WO Form */}
				<FlexColumn
					flexWrap='nowrap'
					justifyContent='space-around'
					width='600px'
				>
					{closeDisabled && (
						<div style={{ marginTop: '4px' }}>
							<FormMessage strong type='waiting'>
								You must first upload a file before you are able to close this
								work order.
							</FormMessage>
						</div>
					)}
					<div>
						<form
							onSubmit={e => handleSubmit(e)}
							style={{
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'nowrap',
								justifyContent: 'space-around'
							}}
						>
							{formInputs.map(input => {
								return (
									<FormInput
										key={input.name}
										{...input}
										value={formState[input.name]}
										disabled={closeDisabled}
										onChange={event => handleCloseWOChangeEvent(event)}
									/>
								);
							})}
							<span>
								<Button
									size='small'
									type='submit'
									variant='contained'
									color='primary'
									style={{ marginTop: '17px' }}
									disabled={closeDisabled}
								>
									{formState.submitted ? 'Update' : 'Submit'}
								</Button>
							</span>
						</form>
					</div>
					{formState.submitting && (
						<FormMessage strong type='waiting'>
							Attempting to submit close request for WO# {smiWO}...
						</FormMessage>
					)}
					{!formState.submitting &&
						formState.submitted &&
						!formState.updated &&
						!formState.failed && (
							<FormMessage strong type='success'>
								WO# {smiWO} close request submitted successfully
							</FormMessage>
						)}
					{!formState.submitting &&
						formState.submitted &&
						formState.updated &&
						!formState.failed && (
							<FormMessage strong type='success'>
								WO# {smiWO} close request updated successfully
							</FormMessage>
						)}
					{!formState.submitting && formState.failed && (
						<FormMessage strong type='error'>
							Failed to submit close request for WO# {smiWO}
						</FormMessage>
					)}
				</FlexColumn>
			</FlexRow>
		</>
	);
};

const closeWOs = [
	{
		// hidden column necessary for filtering
		Header: 'Client Name',
		accessor: 'clientName',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'Month',
		accessor: 'month',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 30,
		textAlign: 'center'
	},
	{
		Header: 'StoreName',
		accessor: 'storeName',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 125,
		textAlign: 'center'
	},
	{
		Header: 'Location',
		accessor: 'location',
		disableFilters: true,
		width: 150
	},
	{
		// hidden column necessary for filtering
		Header: 'City',
		accessor: 'city',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		// hidden column necessary for filtering
		Header: 'State',
		accessor: 'state',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'WO#',
		accessor: 'custWO',
		disableFilters: true,
		width: 50,
		Cell: DownloadPDF,
		textAlign: 'center'
	},
	{
		// hidden column necessary for filtering
		Header: 'SMI-WO#',
		accessor: 'smiWO',
		disableFilters: true,
		width: 50,
		textAlign: 'center'
	},
	{
		Header: 'Status',
		accessor: 'status',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 80,
		textAlign: 'center',
		Cell: e => StatusCell(e)
	},
	{
		// hidden column necessary for filtering //! NOW VISIBLE
		Header: 'Service Type',
		accessor: 'serviceType',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	// { //! REMOVED AND REPLACED WITH SERVICE TYPE PER ROBYN
	// 	Header: 'Task',
	// 	accessor: 'task',
	// 	disableFilters: true,
	// 	width: 95
	// },
	{
		Header: 'Payout',
		accessor: 'payOut',
		disableFilters: true,
		width: 50,
		Cell: ({ value }) => formatCurrency(value),
		textAlign: 'center'
	},
	{
		Header: 'Store Phone#',
		accessor: 'storePhone',
		disableFilters: true,
		width: 70,
		textAlign: 'center'
	},
	{
		Header: 'Schedule Date',
		accessor: 'scheduledDate' || 'TempScheduleDate',
		disableFilters: true,
		width: 70,
		textAlign: 'center'
	},
	{
		Header: '',
		id: 'form',
		width: 600,
		disableFilters: true,
		Cell: e => CloseForm(e),
		textAlign: 'center'
	}
];

export default closeWOs;
