import React, { useState } from 'react';
import Dashboard from '../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../components/dashboard/Dashboard.style.js';
import { insertUsers } from '../../services/realm/vendors.js';
import { Input, Button, Grid, TextField, Typography } from '@material-ui/core';
import { usernameValidation } from '../../services/realm/restrictedCrew.js';
import { sendEmail } from '../../services/realm/employees.js';

const XLSX = require('xlsx');

const MB = 1048576; //Bytes
const maxSize = 5 * MB;

const messageColor = messageType => {
	switch (messageType) {
		case 'waiting':
			return '#555555';
		case 'success':
			return '#4BB543';
		case 'error':
			return '#ED4337';
		default:
			return '#OOOOOO';
	}
};

const FormMessage = props => {
	const { children, marginTop, strong, type } = props;
	return (
		<div>
			<Typography
				align='center'
				variant='subtitle2'
				style={{
					marginTop: marginTop || '0',
					marginBottom: '1px',
					color: messageColor(type)
				}}
			>
				{strong ? <strong>{children}</strong> : children}
			</Typography>
		</div>
	);
};

const AddCrews = props => {
	// const [users, setUsers] = useState(null);

	const [uploadState, setUploadState] = useState({
		file: [],
		rawData: [],
		users: null,
		submitting: false,
		submitted: false,
		error: false,
		message: ''
	});

	const updateUploadState = payload => {
		setUploadState(currState => ({ ...currState, ...payload }));
	};

	const parseXlsxToJson = (file, name) => {
		let reader = new FileReader();
		// let json = null;

		reader.onload = e => {
			let data = e.target.result;

			let workbook = XLSX.read(data, { type: 'binary' });

			workbook.SheetNames.forEach(sheetName => {
				let sheetValues = workbook.Sheets[sheetName];
				let object = XLSX.utils.sheet_to_json(sheetValues);
				Object.keys(object).map((key, value) => {
					if (typeof object[key] === 'object') {
						let obj = object[key];
						Object.keys(obj).map((k, v) => {
							if (typeof obj[k] === 'string') {
								// remove empty spaces surrounding value
								obj[k] = obj[k].trim();
							}
							return true;
						});
						//update original value
						object[key] = obj;
					} else if (typeof object[key] === 'string') {
						//update original value with trimmed string.
						object[key] = object[key].trim();
					}
					return true;
				});
				// json = JSON.stringify(object);
				let rawData= [];
				object.forEach(async(obj, index) => {
					let row = {};
					row.id = index;
					row.data = obj;
					row.valid = false;

					if ( obj.CrewCode && obj.UserID && obj.Password){
						row.valid = true;
					}

					rawData.push(row)

				})
				console.log('rawData:', object)
				updateUploadState({
					rawData,
					[name]: file,
					error: false,
					message: ''
				});
			});
		};
		reader.onerror = e => {
			updateUploadState({
				error: true,
				message: 'Failed to read submitted file.'
			});
		};
		reader.readAsBinaryString(file);
	};

	const handleUploadChangeEvent = event => {
		const { name, files } = event.target;
		const file = files[0];

		if (!file.name.match(/.+\.[xlsx|csv]/)) {
			updateUploadState({
				error: true,
				message: 'File type must be .csv or .xlsx'
			});
			return false;
		}
		if (file.size > maxSize) {
			updateUploadState({
				error: true,
				message: 'File must be 5MB in size or less.'
			});
			return false;
		}

		updateUploadState({ error: false, message: '' });
		parseXlsxToJson(file, name);
	};

	const handleUploadFile = async e => {
		e.preventDefault();
		try {
			updateUploadState({
				submitting: true,
				error: false,
				message: 'Attempting to create new crews.'
			});

			let validUsers = await uploadState.rawData.filter( user => user.valid )
			validUsers = validUsers.map(user=> user.data);

			console.log(validUsers)

			let { data } = await insertUsers(validUsers);

			if (data) {
				updateUploadState({
					users: data,
					submitted: true,
					submitting: false,
					error: false,
					message: 'New crews created successfully.'
				});
			}
			
		} catch (error) {
			updateUploadState({
				submitted: false,
				submitting: false,
				error: true,
				message: 'Failed to create new crews.'
			})
		};
	};

	const [userForm, setUserForm] = useState({
		editing: false,
		readOnly: true,
		submitting: false,
		submitted: false,
		updated: false,
		error: false,
		validUserID: true,
		message: ''
	});

	const updateUserForm = payload => {
		setUserForm(currState => ({ ...currState, ...payload }));
	};

	const handleSubmit = async event => {
		event.preventDefault();
		try {
			updateUserForm({
				submitting: true,
				readOnly: true
			});
			let payload = {
				UserType: 'V'
			};
			Array.prototype.forEach.call(event.target.elements, element => {
				if (element.name !== '') {
					payload[`${element.name}`] = element.value;
				}
			});

			if (userForm.validUserID) {
				let { data } = await insertUsers([payload]);
				if (data) {
					sendEmail({recipient: payload, subject: 'New Credentials' })
					updateUserForm({
						editing: false,
						readOnly: false,
						submitting: false,
						submitted: true,
						error: false,
						message: 'User created successfully.'
					});


				} else {
					throw new Error('UserID is already taken.');
				}
			}
		} catch (error) {
			console.error(error);
			updateUserForm({
				submitting: false,
				readOnly: false,
				error: true,
				message: error
			});
		}
	};

	const handleUserID = async event => {
		const valid = await usernameValidation(event.target.value);
		let message = valid ? '' : 'UserID is already taken';
		updateUserForm({
			validUserID: valid,
			message
		});
	};

	const classes = dashboardStyles();

	const fileName = uploadState.file.name
	return (
		<>
			<Dashboard {...props} classes={classes} fullPage>
				<div>
					<XlsxTitle />
					<XlsxInstructions />

					<div
						style={{
							border: '1px solid #55555540',
							borderRadius: '5px',
							margin: '5px 0'
						}}
					>
						<form
							onSubmit={e => handleUploadFile(e)}
							style={{
								display: 'flex',
								flexDirection: 'column',
								flexWrap: 'nowrap',
								alignItems: 'center',
								justifyContent: 'space-around',
								margin: '5px'
							}}
							name='UploadDoc'
						>
							<div
								style={{
									width: '95%',
									borderBottom: '1px solid #55555540',
									margin: '5px 0'
								}}
							>
								{fileName && (
									<FormMessage type='waiting' marginTop='5px'>
										{fileName.length > 24
											? fileName.slice(0, 24) + '...'
											: fileName}
									</FormMessage>
								)}
								<label htmlFor={`xlsx-file-upload-button`}>
									<Input
										id={`xlsx-file-upload-button`}
										type='file'
										name='file'
										style={{ display: 'none' }}
										multiple={false}
										onChange={handleUploadChangeEvent}
									/>
									<Button
										style={{
											margin: '5px 0 15px 0'
										}}
										variant='contained'
										component='span'
										size='small'
										color='primary'
									>
										Choose File
									</Button>
								</label>
							</div>

							<div
								style={{
									height: '250px',
									width: '95%',
									borderBottom: '1px solid #55555540'
								}}
							>
								<h3
									style={{
										margin: '0 0 5px 0',
										padding: 0
									}}
								>
									User Data
								</h3>
								<div>
									<ul
										style={{
											listStyle: 'none',
											margin: 0,
											padding: 0
										}}
									>
										{uploadState.rawData.length > 0 &&
											uploadState.rawData.map((row, index) => {
												return (
													<li
														key={`user-data-row${index}`}
														style={{
															width: '100%',
															border: `1px solid ${
																row.valid ? '#55555540' : '#ED4337'
															}`,
															borderRadius: '5px'
														}}
													>
														{Object.keys(row.data).map((key, value) => {
															let firstKey = Object.keys(row.data)[0];
															let starter = firstKey !== key ? ' ' : '{ ';
															let lastKey = Object.keys(row.data).pop();
															let spacer = lastKey !== key ? ' ,' : ' }';

															return (
																<span>
																	{starter}
																	<b>{`${key}`}:</b> {row.data[`${key}`]}
																	{spacer}
																</span>
															);
														})}
													</li>
												);
											})}
									</ul>
								</div>
							</div>

							<div>
								<Button
									type='submit'
									style={{
										margin: '15px 0 15px 0'
									}}
									variant='contained'
									size='small'
									color='primary'
									disabled={!uploadState.file.name || uploadState.error}
								>
									Create Users
								</Button>
							</div>
						</form>

						<XlsxMessages {...uploadState} />
					</div>
				</div>

				<div>
					<h2>Create User Form</h2>
					<div>
						<form onSubmit={event => handleSubmit(event)}>
							<Grid container>
								<Grid item xs={12}>
									<TextField
										variant='outlined'
										label='Crew Code'
										name='CrewCode'
										required={true}
										margin='normal'
										size='small'
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant='outlined'
										label='User ID'
										name='UserID'
										onBlur={handleUserID}
										error={!userForm.validUserID}
										helperText={
											userForm.validUserID
												? ''
												: 'That UserID is already taken.'
										}
										required={true}
										margin='normal'
										size='small'
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant='outlined'
										label='Password'
										name='Password'
										required={true}
										margin='normal'
										size='small'
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant='outlined'
										label='Email'
										name='email'
										margin='normal'
										size='small'
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant='outlined'
										label='First Name'
										name='FirstName'
										margin='normal'
										size='small'
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant='outlined'
										label='Last Name'
										name='LastName'
										margin='normal'
										size='small'
									/>
								</Grid>
								<Grid item xs={12}>
									<Button variant='outlined' type='submit'>
										submit
									</Button>
								</Grid>
								<Grid item xs={12}>
									{userForm.submitting && (
										<FormMessage type='waiting' marginTop='5px'>
											Attempting to Create New User
										</FormMessage>
									)}
									{userForm.error && (
										<FormMessage type='error' marginTop='5px'>
											Failed to create new user
										</FormMessage>
									)}
									{userForm.submitted && (
										<FormMessage type='success' marginTop='5px'>
											New user created successfully
										</FormMessage>
									)}
								</Grid>
							</Grid>
						</form>
					</div>
				</div>
			</Dashboard>
		</>
	);
};

export default AddCrews;

const XlsxTitle = () => {
	return (
		<div
			style={{
				border: '1px solid #55555540',
				borderRadius: '5px',
				marginTop: '25px'
			}}
		>
			<h2 style={{ margin: '15px 0 0 0', padding: 0 }}>
				Create Multiple New Users
			</h2>
			<h5 style={{ margin: '0 0 15px 0', padding: 0 }}>
				By uploading an XLSX document.
			</h5>
		</div>
	);
};

const XlsxInstructions = () => {
	return (
		<div
			style={{
				textAlign: 'center',
				border: '1px solid #55555540',
				borderRadius: '5px',
				marginTop: '5px'
			}}
		>
			<h3 style={{ margin: '5px' }}>Instructions</h3>
			<ol
				style={{
					margin: '5px 0 15px 0',
					textAlign: 'left',
					fontSize: '0.90rem'
				}}
			>
				<li>
					Click the 'CHOOSE FILE' button to select an XLSX document that is 5MB
					in size or smaller to begin uploading new users.
				</li>
				<li>
					Uploading the XLSX document will be parsed to collect new users data.
				</li>
				<li>
					Each user record <b>must</b> have a 'CrewCode', 'UserID', & 'Password'.
				</li>
				<li>
					Click the 'CREATE USERS' button to begin the process of creating new
					users.
				</li>
				<li>Upon completion, you will receive a confirmation message.</li>
			</ol>
		</div>
	);
};


const XlsxMessages = props => {
	const { error, submitted, submitting, message, users } = props;

	let type = 'info';
	if (submitting)	type = 'waiting';
	if (error)			type = 'error';
	if (submitted)		type = 'success'

	return (
		<div>
			<FormMessage type={type} marginTop='5px'>
				{message}
			</FormMessage>

			{ users && (
				<FormMessage type='info'>
					Inserted {users.results.insertedIds.length} Users
				</FormMessage>
			)}
		</div>
	);
}
