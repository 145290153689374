import React from 'react';
import clsx from 'clsx';
import {
	AppBar,
	// eslint-disable-next-line
	Badge,
	IconButton,
	Toolbar,
	Typography
} from '@material-ui/core';
// eslint-disable-next-line
import { Menu, Notifications } from '@material-ui/icons';

import { useDrawer } from '../../context/drawer';

const TopBar = props => {
	const { classes, pageTitle } = props;
	const { drawerOpen, setDrawerOpen } = useDrawer();
	return (
		<>
			<AppBar
				position='absolute'
				className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
			>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='open drawer'
						onClick={() => setDrawerOpen()}
						className={clsx(
							classes.menuButton,
							drawerOpen && classes.menuButtonHidden
						)}
					>
						<Menu />
					</IconButton>
					<Typography
						component='h1'
						variant='h6'
						color='inherit'
						noWrap
						className={classes.title}
					>
						{pageTitle}
					</Typography>
					{/* <IconButton color='inherit'>
						<Badge badgeContent={0} color='secondary'>
							<Notifications />
						</Badge>
					</IconButton> */}
				</Toolbar>
			</AppBar>
		</>
	);
};

export default TopBar;
