import { StrictMode } from 'react';
import { render } from 'react-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { DrawerContextProvider } from './context/drawer';
import { UserContextProvider } from './context/user';
import './style/index.css';
import App from './App';
import reportWebVitals from './test/reportWebVitals';

const theme = createTheme({
	palette: {
		primary: {
			main: '#004E98'
		},
		secondary: {
			main: '#ff6700'
		},
		waiting: {
			main: '#555555'
		},
		success: {
			main: '#4BB543'
		},
		error: {
			main: '#ED4337'
		},
		background: {
			default: '#FFFFFF'
		},
		text: {
			primary:	'#333333',
			secondary:	'#383838'
		}
	}
});

render(
	<StrictMode>
		<DrawerContextProvider>
			<UserContextProvider>
				<MuiThemeProvider theme={theme}>
					<App />
				</MuiThemeProvider>
			</UserContextProvider>
		</DrawerContextProvider>
	</StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
