import { Input, NativeSelect } from '../components';
import { franchises } from '../misc';

const companyDetails = [
	{
		name: 'VendorCode',
		label: 'Crew Code',
		style: { maxWidth: '98%' },
		readOnly: true,
		disabled: true,
		gridProps: {
			xs: 12,
			lg: 6
		},
		Input
	},
	// {
	// 	name: 'Status',
	// 	label: 'Status',
	// 	style: { maxWidth: '98%' },
	// 	readOnly: true,
	//  disabled: true,
	// 	gridProps: {
	// 		xs: 12,
	// 		lg: 6
	// 	}
	// },
	{
		name: 'CompanyName',
		label: 'Name',
		style: { maxWidth: '98%' },
		readOnly: true,
		disabled: true,
		gridProps: {
			xs: 12,
			lg: 6
		},
		Input
	},
	{
		name: 'CompanyDBA',
		label: 'Doing Business As',
		style: { maxWidth: '98%' },
		gridProps: {
			xs: 12,
			lg: 6
		},
		Input
	},
	{
		name: 'Country',
		label: 'Country',
		style: { maxWidth: '98%' },
		readOnly: true,
		disabled: true,
		gridProps: {
			xs: 12,
			lg: 6
		},
		Input
	},
	{
		name: 'Franchise',
		label: 'Franchise',
		style: { maxWidth: '98%' },
		gridProps: {
			xs: 12,
			lg: 6
		},
		Input: NativeSelect,
		options: franchises
	},
	{
		name: 'Employees',
		label: '# of Employees',
		style: { maxWidth: '98%' },
		type: 'number',
		defaultValue: 0,
		gridProps: {
			xs: 12,
			lg: 6
		},
		Input
	},
	{
		name: 'SubContractsPercent',
		label: '% SubContracted',
		style: { maxWidth: '98%' },
		type: 'number',
		// defaultValue: 0,
		gridProps: {
			xs: 12,
			lg: 6
		},
		Input
	},
	{
		name: 'SetupDate',
		label: 'Year Started',
		style: { maxWidth: '98%' },
		readOnly: true,
		disabled: true,
		exclude: true,
		gridProps: {
			xs: 12,
			lg: 6
		},
		Input
	}
];

export default companyDetails;
