import { CheckSharp, CloseSharp } from '@material-ui/icons';
import { approveCrewOnboarding } from '../../../services/realm/employees';

const ApprovalForm = props => {
	let { row } = props;
	let {
		original: {
			user: {
				required: { generalInfo, companyInfo }
			}
		}
	} = row;

	let disabled = generalInfo === true && companyInfo === true ? false : true;

	const style = {
		display: 'flex',
		height: '100%',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	};

	const handleClick = () => {
		let payload = row.original;
		payload.user.required.smiReviewed = true;
		console.log(payload);
		let {data, error} = approveCrewOnboarding(payload)
		if (error){
			console.error(error)
		} else {
			console.log(data)
		}
	};

	return (
		<div style={style}>
			<button disabled={disabled} onClick={handleClick}>
				Approve
			</button>
		</div>
	);
};

// const HeaderCell = props => {
// 	const style = {
// 		margin: 'auto',
// 		padding: 'auto',
// 		fontSize: '14px'
// 	};

// 	return (
// 		<div id='test123456' style={style}>
// 			{props.Header}
// 		</div>
// 	);
// };

const StatusHeader = props => {
	const outerDivStyle = {
		display: 'flex',
		height: '100%',
		flexDirection: 'row',
		margin: '0px'
	};

	const innerDivStyle = {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center'
	};

	const textStyle = {
		fontSize: '12px',
		lineHeight: '12px',
		transform: 'rotate(-45deg)'
	};

	return (
		<div style={outerDivStyle}>
			<div style={innerDivStyle}>
				<p style={textStyle}>{props.Header}</p>
			</div>
		</div>
	);
};

const StatusCell = props => {
	const outerDivStyle = {
		display: 'flex',
		height: '100%',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'
	};

	const innerDivStyle = {
		margin: '0',
		padding: '0',
		height: '16px',
		width: '16px',
		// border: '1px solid #dee2e6',
		textAlign: 'center'
	};
	return (
		<div style={outerDivStyle}>
			<div style={innerDivStyle}>
				{props.value === true ? (
					<CheckSharp style={{ color: '#4BB543', fontSize: '14px' }} />
				) : (
					<CloseSharp style={{ color: '#ED4337', fontSize: '14px' }} />
				)}
			</div>
		</div>
	);
};

const crews = [
	{
		id: 1,
		Header: 'User Info',
		headerStyle: {
			backgroundColor: '#F5F5F5',
			border: '1px solid #E0E0E0',
			borderBottom: 'none'
		},
		columns: [
			{
				Header: 'Full Name',
				accessor: 'user.FullName',
				disableFilters: true,
				width: 100,
				headerStyle: {
					height: '48px',
					margin: 'auto',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#F5F5F5',
					borderLeft: '1px solid #E0E0E0',
					borderBottom: '1px solid #E0E0E0'
				}
			},
			{
				Header: 'Email',
				accessor: 'user.email',
				disableFilters: true,
				width: 100,
				headerStyle: {
					height: '48px',
					margin: 'auto',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#F5F5F5',
					borderRight: '1px solid #E0E0E0',
					borderBottom: '1px solid #E0E0E0'
				}
			}
		]
	},
	{
		Header: 'Company Info',
		headerStyle: {
			backgroundColor: '#F5F5F5',
			border: '1px solid #E0E0E0',
			borderBottom: 'none'
		},
		columns: [
			{
				Header: 'Name',
				headerStyle: {
					height: '48px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#F5F5F5',
					borderLeft: '1px solid #E0E0E0',
					borderBottom: '1px solid #E0E0E0'
				},
				accessor: 'vendor.CompanyName',
				disableFilters: true,
				width: 100
			},
			{
				Header: 'DBA',
				headerStyle: {
					height: '48px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#F5F5F5',
					borderBottom: '1px solid #E0E0E0'
				},
				accessor: 'vendor.CompanyDBA',
				disableFilters: true,
				width: 100
			},
			{
				Header: 'Address',
				headerStyle: {
					height: '48px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#F5F5F5',
					borderRight: '1px solid #E0E0E0',
					borderBottom: '1px solid #E0E0E0'
				},
				accessor: 'vendor.CompanyAddress',
				disableFilters: true,
				width: 100
			}
		]
	},
	{
		Header: 'Completion Statuses',
		headerStyle: {
			backgroundColor: '#F5F5F5',
			border: '1px solid #E0E0E0',
			borderBottom: 'none'
		},
		columns: [
			{
				Header: e => <StatusHeader {...e} Header='General Info' />,
				headerStyle: {
					height: '48px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#F5F5F5',
					borderLeft: '1px solid #E0E0E0',
					borderBottom: '1px solid #E0E0E0'
				},
				accessor: 'user.required.generalInfo',
				disableFilters: true,
				width: 25,
				maxWidth: 25,
				Cell: StatusCell
			},
			{
				Header: e => <StatusHeader {...e} Header='Company Info' />,
				headerStyle: {
					height: '48px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#F5F5F5',
					borderBottom: '1px solid #E0E0E0'
				},
				accessor: 'user.required.companyInfo',
				disableFilters: true,
				width: 25,
				maxWidth: 25,
				Cell: StatusCell
			},
			{
				Header: e => <StatusHeader {...e} Header='SMI Reviewed' />,
				headerStyle: {
					height: '48px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#F5F5F5',
					borderBottom: '1px solid #E0E0E0'
				},
				accessor: 'user.required.smiReviewed',
				disableFilters: true,
				width: 25,
				maxWidth: 25,
				Cell: StatusCell
			},
			{
				Header: e => <StatusHeader {...e} Header='Documents Signed' />,
				headerStyle: {
					height: '48px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: '#F5F5F5',
					borderRight: '1px solid #E0E0E0',
					borderBottom: '1px solid #E0E0E0'
				},
				accessor: 'user.required.signDocs',
				disableFilters: true,
				width: 25,
				maxWidth: 25,
				Cell: StatusCell
			}
		]
	},
	{
		Header: '',
		id: 'formTop',
		headerStyle: {
			height: '24px',
			backgroundColor: '#F5F5F5',
			border: '1px solid #E0E0E0',
			borderBottom: 'none'
		},
		columns: [
			{
				headerStyle: {
					height: '48px',
					backgroundColor: '#F5F5F5',
					border: '1px solid #E0E0E0',
					borderTop: 'none'
				},
				id: 'formBottom',
				disableFilters: true,
				width: 50,
				maxWidth: 50,
				Cell: e => <ApprovalForm {...e} />
			}
		]
	}
];

export default crews;
