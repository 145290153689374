//Dependencies
import { Box, Container, Grid } from '@material-ui/core';
//Components
import Copyright from '../Copyright';
import Tile from './Tile';

const Content = props => {
	const { classes, children, ...otherProps } = props;
	let i = 0;
	return (
		<>
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth='xl' className={classes.container}>
					<Grid container spacing={3}>
						{children && children.length > 1 ? (
							children.map(child => {
								i++;
								return <Tile {...otherProps} key={i} children={child} />;
							})
						) : (
							<Tile {...otherProps} xs={12} md={12} lg={12}>
								{children}
							</Tile>
						)}
					</Grid>
					<Box pt={4}>
						<Copyright />
					</Box>
				</Container>
			</main>
		</>
	);
};

export default Content;
