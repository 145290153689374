import React, {useState} from 'react';

import {InputBase, Tooltip, IconButton} from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { SearchOutlined, CloseOutlined} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		border: '1px solid #ced4da',
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25)
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto'
		}
	},
	searchIcon: {
		width: theme.spacing(7),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200
		}
	}
}));

const GlobalFilter = props => {
	const {preGlobalFilteredRows, state:{globalFilter}, setGlobalFilter} = props;

	const [search, setSearch] = useState(false);
	const classes = useStyles();
	const count = preGlobalFilteredRows.length;

	const handleClose= () => {
		setSearch(false);
		setGlobalFilter('');
	}

	return (
		<>
			{search?
				<>
					<div className={classes.search} >
						<div className={classes.searchIcon}>
							<Tooltip title='Search' >
								<IconButton aria-label='Search' >
									<SearchOutlined />
								</IconButton>
							</Tooltip>
						</div>
						<InputBase
							value={globalFilter|| ''}
							onChange={e => {
								setGlobalFilter(e.target.value || undefined);
							}}
							placeholder={`${count} records...`}
							classes={{
								root: classes.inputRoot,
								input: classes.inputInput
							}}
							inputProps={{ 'aria-label': 'search' }}
						/> 
					</div>
					<Tooltip title='Close'>
						<IconButton aria-label='Close' onClick={() => handleClose()}>
							<CloseOutlined />
						</IconButton>
					</Tooltip>
				</>
				:
				<Tooltip title='Search'>
					<IconButton aria-label='Search' onClick={() => setSearch(true)}>
						<SearchOutlined />
					</IconButton>
				</Tooltip>
			}
			
		</>
	);
};

export default GlobalFilter;
