import React, { useState } from 'react';
import {
	Button,
	CircularProgress,
	Select,
	TextField,
	FormControl,
	InputLabel
} from '@material-ui/core';
import Dashboard from '../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../components/dashboard/Dashboard.style.js';
import { updateUserPassword } from '../../services/realm/employees';
import { useCrewUsersContext } from '../../context/crews.js';
import { useEffect } from 'react';

const Form = props => {
	let { children, ...otherProps } = props;
	let style = {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		alignItems: 'center',
		justifyContent: 'space-around',
		margin: '5px'
	};

	return (
		<form {...otherProps} style={style}>
			{children}
		</form>
	);
};

let placeholderUser = { _id: '', UserID: 'Select a User' };
let initialState = { crews: [placeholderUser], successful: false };

const ResetPassword = props => {
	const { crewUsers } = useCrewUsersContext();
	const [state, setState] = useState(initialState);

	const updateState = payload => {
		setState(state => ({ ...state, ...payload }));
	};

	useEffect(() => {
		updateState({ crews: [placeholderUser, ...crewUsers] });
	}, [crewUsers]);

	const handleSubmit = async e => {
		e.preventDefault();
		let input1 = e.target[0];
		let input2 = e.target[1];
		if (input1.value !== '' && input2.value !== '') {
			let payload = {
				[`${input1.name}`]: input1.value,
				[`${input2.name}`]: input2.value
			};

			const response = await updateUserPassword(payload);
			if (!response.error) {
				updateState({ successful: true });
			}
		}
	};


	const classes = dashboardStyles();
	return (
		<Dashboard {...props} classes={classes} fullPage>
			<>
				<h2>Change Crew Password</h2>
				{state.crews.length > 1 ? (
					<div>
						<Form onSubmit={e => handleSubmit(e)}>
							<FormControl style={{marginTop: '14px'}}>
								<InputLabel shrink htmlFor='pw-reset'>
									Select a User
								</InputLabel>
								<Select native id='pw-reset' name='id'>
									{crewUsers.map(eachUser => (
										<option
											id={eachUser._id}
											key={eachUser._id}
											value={eachUser._id}
										>
											{eachUser.UserID}
										</option>
									))}
								</Select>
							</FormControl>
							<TextField
								style={{ marginTop: '14px' }}
								label='New Password'
								name='newPassword'
								value={state.newPassword}
							/>

							<Button
								type='submit'
								variant='contained'
								size='small'
								color='primary'
								style={{ marginTop: '28px' }}
							>
								Reset Password
							</Button>
						</Form>
						{state.successful ? <p>Password Saved</p> : <></>}
					</div>
				) : (
					<div>
						<CircularProgress />
					</div>
				)}
			</>
		</Dashboard>
	);
};

export default ResetPassword;
