//------------------------------- Dependencies -------------------------------//
import { Grid } from '@material-ui/core';
//-------------------------------- Components --------------------------------//
import { Title } from '../../../../components';

export const Section = props => {
	const { title, Button, children } = props;

	return (
		<div>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				alignItems='center'
			>
				<Grid item>
					<Title> {title}</Title>
				</Grid>
				<Grid item>
					{Button && <Button />}
				</Grid>
			</Grid>
			{children}
		</div>
	);
};

export default Section;
