import { Input, Button } from '@material-ui/core';

export const UploadInput = props => {
	const { id, onChange } = props;

	return (
		<div>
			<label htmlFor={id}>
				<Input
					id={id}
					type='file'
					name='file'
					style={{ display: 'none' }}
					multiple={false}
					onChange={onChange}
				/>
				<Button
					variant='contained'
					component='span'
					size='small'
					color='primary'
				>
					Choose File
				</Button>
			</label>
		</div>
	);
};

export default UploadInput;
