import Link from '../Link.js';
import Title from '../Text/Title';

const ToDoList = props => {
	const { pastDue, needSchedule, history } = props;

	return (
		<>
			<Title>To Do List</Title>
			<div>
				{needSchedule && (
					<Link onClick={() => history.push('/workOrders/schedule')}>
						{needSchedule} work orders need to be scheduled
					</Link>
				)}
			</div>
			<div>
				{pastDue && (
					<Link onClick={() => history.push('/workOrders/close')}>
						{pastDue} work orders are past due and need to be closed
					</Link>
				)}
			</div>
			<div>{!pastDue && !needSchedule && <Link>No tasks to do</Link>}</div>
		</>
	);
};

export default ToDoList;
