import { useCallback, useEffect, useState } from 'react';
import { useUser } from '../context/user';

const defaultCB = data => ( data )

const useTableDataCrew = (fetchData, callback = defaultCB ) => {
	const [ data, setData ] = useState([]);
	const { currentUser: { CrewCode } } = useUser();

	const updateData = useCallback(async (update) => {
		setData(currData=>{
			let newData = currData.map(curr =>{
				return curr._id === update._id ? update : curr;
			})
			return newData;
		})
	}, []);

	const handleData = useCallback(async () => {
		if (CrewCode) {
			let { data, error } = await fetchData(CrewCode);
			if (!error) {
				if (callback) {
					data = callback(data);
				}
				setData(data)
			} 
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ CrewCode ]);

	useEffect(() => {
		handleData();
		return () => {
			setData([]);
		}
	}, [handleData]);

	return [ data, updateData ];
};

export default useTableDataCrew;
