import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import { blueShades, orangeShades, greyShades } from './colors';

import Title from '../Text/Title';

export const CustomLabel = props => {
	const RADIAN = Math.PI / 180;
	const {
		payload: { value, name, fill },
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		percent
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g
			style={{
				border: '1px solid red',
				overflow: 'visible'
			}}
		>
			{/* Inner Circle */}
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			{/* Outer Circle */}
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>

			{/* pointer */}
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke={fill}
				fill='none'
			/>

			{/* dot @ end of the pointer */}
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />

			{(percent * 100).toFixed(2) > 3.5 ? (
				<>
					{/* open count */}
					<text
						x={ex + (cos >= 0 ? 1 : -1) * 12}
						y={ey}
						textAnchor={textAnchor}
						fill='#333'
					>
						{`${value} open`}
					</text>

					{/* service type */}
					<text
						x={ex + (cos >= 0 ? 1 : -1) * 12}
						y={ey}
						dy={18}
						textAnchor={textAnchor}
						fill='#999'
					>
						{`${name}`}
					</text>
				</>
			) : (
				<>
					<text
						x={ex + (cos >= 0 ? 1 : -1) * 12}
						y={ey}
						textAnchor={textAnchor}
						fill='#333'
						style={{ fontSize: '10px' }}
					>
						{`${value} open | ${name}`}
					</text>
				</>
			)}
		</g>
	);
};

export const PaddingAngle = props => {
	const { width, height, color, pieData, pieTitle } = props;
	const [colors, setColors] = useState(greyShades);

	useEffect(() => {
		switch (color.toLowerCase()) {
			case 'blue':
				return setColors(blueShades);
			case 'orange':
				return setColors(orangeShades);
			case 'grey':
				return setColors(greyShades);
			default:
				return { message: `${color} is not a supported color` };
		}
	}, [color]);

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around'
				// border: '1px solid black'
			}}
		>
			<div
				style={{
					height: 'auto'
					// border: '1px solid black'
				}}
			>
				<Title>{pieTitle}</Title>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-around',
					alignItems: 'center'
					// border: '1px solid black'
				}}
			>
				{pieData && (
					<PieChart width={width} height={height}>
						<Pie
							data={pieData}
							cx={width / 2}
							cy={height / 2}
							innerRadius={100}
							outerRadius={120}
							fill='#8884d8'
							paddingAngle={2}
							dataKey='value'
							nameKey='name'
							label={CustomLabel}
						>
							{pieData.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={colors[index % colors.length]}
								/>
							))}
						</Pie>
					</PieChart>
				)}
			</div>
		</div>
	);
};
