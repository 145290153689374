import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Input, Button, TextareaAutosize, Box} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { updateWOScheduleDate } from '../../services/realm/restrictedCrew';
import { useUser } from '../../context/user';

const UpdateWOSchedule = (props) => {
    // const { currentUser } = props;
    const { currentUser } = useUser();
    const [rawData, setRawData] = useState(null);
    const [workOrders, setWorkOrders] = useState(null);
    const [disabled, setDisabled] = useState(false)
    const [displayed, setDisplayed] = useState("blocked")

    const handleSubmit = (id) => {
        setWorkOrders(wos=>{
            return wos.map(wo=>{
                return wo.id === id ? { ...wo, submitted:true }: wo
            })
        })
    }

    const handleBack = () => {
        setWorkOrders(null)
        setDisabled(false)
        setDisplayed("blocked")
    }

    const handleStatus = (id, obj) => {
        setWorkOrders(wos=>{
            return wos.map(wo=>{
                return wo.id === id ? {...wo,...obj}: wo
            })
        })
    }

    const handleTableSubmit = async (e) => {
        setDisabled(true)
        setDisplayed("none")
        for (let workOrder of workOrders) {
            handleSubmit(workOrder.id)
            
            let payload = {
                    SMI_WO: workOrder.SMI_WO,
                    ScheduleDate: workOrder.ScheduleDate,
                    UserRecId: currentUser.RecId,
                    VendorCode: currentUser.CrewCode
            }
            const updatedWO = await updateWOScheduleDate(payload)
            if (updatedWO.error) {
                handleStatus(workOrder.id, {success:false,error:true})
            } else {
                handleStatus(workOrder.id, {success:true,error:false})
            }
        }

    }

    const handleRowDelete = (row) => {
        const newRows = workOrders.filter((order) => order.id !== row)
        setWorkOrders(newRows)
    }

    const handleCellOnChange = (e, row) => {
        const newRows = workOrders.map(order => {
            if (order.id === row) {
                return { ...order, [e.target.name]: e.target.value }
            }
            return order
        });
        setWorkOrders(newRows)
    }

    const handleClick = () => {
        setWorkOrders(convertData(rawData))
    }

    const handleOnChange = (text) => {
        setRawData(text)
    }

//     const isDate = (date) => {
//         return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
//       }    
      
//       let date = /(\d{1,4})([\/-])(\d{1,2})\2(\d{1,4})/
//       date.match(string)
// let wo = /(\d{7,})/
// wo.match(string)


    const convertData = (text) => {
        const rows = text.split('\n');
        const parsedRows = rows.filter(row => row !== '')
            .map((row, index) => {
                if (row.includes('\t')) {
                    const [SMI_WO, ScheduleDate] = row.split('\t')
                    const id = index
                    return { id, SMI_WO, ScheduleDate }
                }
                else {
                    const [SMI_WO, ScheduleDate] = row.split(' ')
                    const id = index
                    return { id, SMI_WO, ScheduleDate }
                }
            })
        return parsedRows

    }

    return (
        <>
                <div  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'nowrap',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    margin: '5px'
                                }}>
                    {workOrders ?
                        <div  style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            margin: '5px'
                        }}>
                                    <Button
                                        onClick={() => handleBack()}
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        style={{ marginTop: '10px' }}
                                    >
                                        Back
                                    </Button>
                                    <h2>Update Work Order Schedule</h2>
                                    <Box style={{overflow: 'scroll', 'overflow-x': 'hidden', height:300,width:500}}>
                                    <TableContainer >
                                        <Table style={{ width: 450}}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell> SMI_WO</TableCell>
                                                    <TableCell>Date</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                            style={{overflow: 'scroll'}}
                                                id='Table-body'
                                            >
                                                {workOrders.map((row) => (
                                                    <Entry
                                                     handleRowDelete={handleRowDelete}
                                                     handleCellOnChange={handleCellOnChange}
                                                     key={row.id}
                                                     row={row}
                                                     disabled={disabled}
                                                     displayed={displayed}
                                                     />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    </Box>
                                    <Button
                                        onClick={(e) => handleTableSubmit(e)}
                                        disabled={disabled}
                                        variant='contained'
                                        size='small'
                                        color='primary'
                                        style={{ marginTop: '10px' }}
                                        endIcon={<Send />}
                                    >
                                        Submit
                                    </Button>
                                </div>
                        :
                        <>
                            <h2
                            style={{alignItems: 'center'}}
                            >
                                Update Work Order Schedule</h2>
                            <form
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'nowrap',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    margin: '5px'
                                }}
                            >
                                <TextareaAutosize
                                    onChange={e => handleOnChange(e.target.value)}
                                    style={{
                                        height: 200,
                                        width: 400
                                    }}
                                ></TextareaAutosize>

                                <Button
                                    onClick={() => handleClick()}
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    style={{ marginTop: '10px' }}
                                >
                                    Verify
                                </Button>

                            </form>
                        </>

                    }
                </div>
        </>
    )
}

const Entry = (props) => {
    const { row, handleRowDelete, handleCellOnChange, disabled, displayed } = props
    const [display,setDisplay] = useState(null)
    useEffect(()=>{
        const { success, error} = row;
        let display = 0
        if(displayed !== "blocked"){
            if(!success && !error){
                display = 1
            }else if(success  && !error){
                display = 2
            }else if(!success && error){
                display = 3
            }
        }
        setDisplay(display)
    },[displayed,row])

    return (
        <TableRow
            id={`row-${row.id}`}
        >
            <TableCell scope="row">
                <Input
                    disabled={disabled}
                    name={"SMI_WO"}
                    value={row.SMI_WO}
                    onChange={(e) => handleCellOnChange(e,row.id)}
                />
            </TableCell>
            <TableCell>
                <Input
                    disabled={disabled}
                    name={"ScheduleDate"}
                    value={row.ScheduleDate}
                    onChange={(e) => handleCellOnChange(e,row.id)}
                />
            </TableCell>
            <td>
                {display === 0 && <DeleteIcon
                    //style={{ display: displayed }}
                    onClick={() => handleRowDelete(row.id)}
                />}
                
                {display === 1 && <CircularProgress/>}
                {display === 2 && <CheckIcon style={{color:'green'}}/>}
                {display === 3 && <CloseIcon style={{color:'red'}}/>}
            </td>
        </TableRow>
    )
}

export default UpdateWOSchedule
