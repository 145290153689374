import { useState } from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Page';
import CompanyInfoForm from './CompanyInfoForm';
import { 
    userUpdate, 
    vendorCreate 
} from '../../../services/realm/restrictedCrew';
import { useUser } from '../../../context/user';

const Company = props => {
	const { setCompanyInfo, history } = props;
	const { currentUser, updateUser } = useUser();
	const { CrewCode, id } = currentUser;
	const [warningMsg, setWarningMsg] = useState(null);

	const onSubmit = async vendor => {
		vendor.VendorCode = CrewCode;
        let payload = { vendor, UserID: currentUser.userName };
        // console.log('payload:',payload);
		const res = await vendorCreate(payload);
        // console.log('vendorCreate:', res);
		if (res) {
			setWarningMsg(null);
			let changes = {};
			changes['required.companyInfo'] = true;
			// changes.UserType = 'V'
			const { data } = await userUpdate({ id, changes });
			let user = currentUser;
			user.required = data.required;
			user.userType = data.userType;
			updateUser(user);
			setCompanyInfo(data.required.companyInfo);
			history.push('/');
		} else {
			setWarningMsg('Error submitting form');
		}
	};

	return (
		<Page>
			<Container
				component='div'
				style={{ display: 'flex', margin: 'auto', width: '600px' }}
			>
				<CompanyInfoForm
					{...props}
					warningMsg={warningMsg}
					setWarningMsg={setWarningMsg}
					onSubmit={onSubmit}
				/>
			</Container>
		</Page>
	);
};

export default Company;
