import { MultiSelectColumnFilter} from "../../../components/tables/filters/column";
import { StatusCell } from '../../../components/tables/cells/StatusCell';
import DownloadPDF from '../../../components/tables/cells/DownloadsPDF';
import { formatCurrency } from "../../../helpers/stringFormat";

const allWOs = [
	{
		// hidden column necessary for filtering
		Header: 'Client Name',
		accessor: 'clientName',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'Store Name',
		accessor: 'storeName',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'City',
		accessor: 'city',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'State',
		accessor: 'state',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'Zip',
		accessor: 'zip',
		disableFilters: true,
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'WO#',
		accessor: 'custWO',
		disableFilters: true,
		width: 100,
		Cell: DownloadPDF,
		textAlign: 'center'
	},
	{
		// hidden column necessary for filtering
		Header: 'SMI-WO#',
		accessor: 'smiWO',
		disableFilters: true,
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'Status',
		accessor: 'status',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center',
		Cell: e => StatusCell(e)
	},
	{
		// hidden column necessary for filtering //! NOW VISIBLE
		Header: 'Service Type',
		accessor: 'serviceType',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	// {  //! REMOVED AND REPLACED WITH SERVICE TYPE PER ROBYN
	// 	Header: 'Task',
	// 	accessor: 'task',
	// 	disableFilters: true,
	// 	width: 100
	// },
	{
		Header: 'Year',
		accessor: 'year',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'Month',
		accessor: 'month',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'Payout',
		accessor: 'payOut',
		disableFilters: true,
		width: 100,
		Cell: ({ value }) => formatCurrency(value),
		textAlign: 'center'
	},
	{
		Header: 'CheckNum',
		accessor: 'checkNum',
		disableFilters: true,
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'CheckDate',
		accessor: 'checkDate',
		disableFilters: true,
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'Scheduled',
		accessor: 'scheduledDate' || 'TempScheduleDate',
		disableFilters: true,
		width: 100,
		textAlign: 'center'
	}
];

export default allWOs;
