import app from './Realm';

export const usernameValidation = async username => {
	try {
		const { unique } = await app.currentUser.functions.usernameValidation({
			username
		});
		return unique;
	} catch (err) {
		return { error: 'Username is already taken' };
	}
};

export const userUpdate = async payload => {
	try {
		const data = await app.currentUser.functions.userUpdate(payload);
		return { data };
	} catch (err) {
		return { error: 'Failed to update user info' };
	}
};

export const vendorCreate = async payload => {
	try {
		const data = await app.currentUser.functions.vendorCreate(payload);
		return { data };
	} catch (err) {
		return { error: 'Failed to create new vendor' };
	}
};

export const updateWOScheduleDate = async payload => {
	try {
		const data = await app.currentUser.functions.updateWOScheduleDate(payload);
		return data;
	} catch (err) {
		return { error: 'Generic error' };
	}
};
