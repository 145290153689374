import { useState } from 'react';
import { scheduleWO } from '../../../services/realm/vendors.js';
import { TextField, Button, Box, Grid, Typography } from '@material-ui/core';
import { useUser } from '../../../context/user';
const inputs = [
	{
		name: 'scheduledDate',
		label: 'Scheduled Date',
		type: 'date',
		required: true
	},
	{
		name: 'startTime',
		label: 'Start Time',
		type: 'time',
		required: true
	},
	{
		name: 'managerName',
		label: 'Manager Name',
		type: 'text'
	}
];

const formatDate = date => {
	const [yyyy, mm, dd] = date.split('-');
	return `${mm}/${dd}/${yyyy}`;
};

const formatTime = time => {
	const [hh, mm] = time.split(':');
	const hH = parseInt(hh);
	const period = hH > 12 ? 'pm' : 'am';
	const HH = hH > 12 ? hH - 12 : hH;
	return `${HH < 10 ? '0' + HH : HH}:${mm}${period}`;
};

const messageColor = messageType => {
	switch (messageType) {
		case 'waiting':
			return '#555555';
		case 'success':
			return '#4BB543';
		case 'error':
			return '#ED4337';
		default:
			return '#OOOOOO';
	}
};


const InputWrapper = props => {
    const {children, ...other} = props;
    
    return(
        <Grid item {...other}>
			<div
				style={{
					// border: '1px solid red',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				{children}
			</div>
		</Grid>
    )
}

const FormInput = props => {
    const {xs, sm, md, lg, xl, ...other} = props;
    const wrapperProps = { xs, sm, md, lg, xl };
	return (
		<InputWrapper {...wrapperProps}>
			<TextField
				{...other}
				size='small'
				variant='standard'
				margin='normal'
				style={{ width: '150px' }}
				InputLabelProps={{ shrink: true }}
			/>
		</InputWrapper>
	);
};

const FormMessage = props => {
	const { children, strong, type } = props;
	return (
		<div>
			<Typography
				align='center'
				variant='subtitle2'
				style={{
					marginTop: '0',
					marginBottom: '1px',
					color: messageColor(type)
				}}
			>
				{strong ? <strong>{children}</strong> : children}
			</Typography>
		</div>
	);
};

export const ScheduleWOForm = props => {
	const { original: { smiWO, storeName } } = props.row || props;
	const { currentUser } = useUser();
	const { RecId, userName, CrewCode } = currentUser;

	const [formState, setFormState] = useState({
		submitting: false,
		submitted: false,
		updated: false,
		failed: false,
		scheduledDate: '',
		startTime: '',
		managerName: ''
	});

	const handleChangeEvent = event => {
		const { name, value } = event.target;
		setFormState({ ...formState, [name]: value });
	};

	const begin = { submitting: true, failed: false };
	const success = { submitting: false, submitted: true };
	const update = { submitting: false, updated: true };
	const error = { submitting: false, failed: true };

	const handleSubmit = async e => {
		e.preventDefault();
		setFormState({ ...formState, ...begin });

		const { scheduledDate, startTime, managerName } = formState;
		const [hours, minutes] = startTime.split(':');
		const formattedTime = new Date(scheduledDate);
		formattedTime.setHours(hours, minutes);

		const obj = {
			formattedTime,
			smiWO,
			vendorCode:CrewCode,
			UserID: userName,
			UserRecId: RecId,
			StoreName: storeName,
			scheduledDate: formatDate(scheduledDate),
			startTime: formatTime(startTime),
			managerName
		};

		const {data} = await scheduleWO(obj)

		if (data) {
			if (!submitted) {
				setFormState({ ...formState, ...success });
			} else {
				setFormState({ ...formState, ...update });
			}
		} else {
			setFormState({ ...formState, ...error });
		}
	};

	const { submitting, submitted, updated, failed } = formState;

    const wrapperProps = { xs: 12, sm: 6 };

	return (
		<>
			<Box  sx={{ flexGrow: 1 }}>
				<form onSubmit={e => handleSubmit(e)}>
						<Grid container>
							{inputs.map(input => {
								return (
									<FormInput
                                        {...wrapperProps}
										key={input.name}
										{...input}
										value={formState[input.name]}
										onChange={event => handleChangeEvent(event)}
									/>
								);
							})}
							<InputWrapper xs={12}>
								<Button
									size='small'
									type='submit'
									variant='contained'
									color='primary'
									// style={{ marginTop: '17px' }}
								>
									{submitted ? 'Update' : 'Submit'}
								</Button>
							</InputWrapper>
						</Grid>
				</form>
			</Box>
			{submitting && (
				<FormMessage strong type='waiting'>
					Submitting schedule for WO# {smiWO}...
				</FormMessage>
			)}
			{!submitting && submitted && !updated && !failed && (
				<FormMessage strong type='success'>
					Schedule submitted successfully for WO# {smiWO}
				</FormMessage>
			)}
			{!submitting && submitted && updated && !failed && (
				<FormMessage strong type='success'>
					Schedule updated successfully for WO# {smiWO}
				</FormMessage>
			)}
			{!submitting && failed && (
				<FormMessage strong type='error'>
					Failed to submit schedule for WO# {smiWO}
				</FormMessage>
			)}
		</>
	);
};
