import { NativeSelect } from '../components';
import { airports } from '../misc';

const airportCodes = [
	{
		name: 'airportCode',
		label: 'Airport Code',
		gridProps: {
			xs: 12
		},
		Input: NativeSelect,
		defaultValue: '',
		options: airports
	}
];

export default airportCodes;
