import { NativeSelect } from '../components';
import { serviceTypes } from '../misc';

const serviceFields = [
	{
		name: 'serviceType',
		label: 'Service Type',
		gridProps: {
			xs: 12
		},
		Input: NativeSelect,
		defaultValue: '',
		options: serviceTypes
	}
];

export default serviceFields;
