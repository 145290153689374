const weeklyActivity = {
	width: 1500,
	xAxisProps: {
		dataKey: 'x'
	},
	yAxises: [
		{
			yAxisId: 'y-axis',
			orientation: 'left',
			stroke: '#8884d8',
			label: {
				value: 'Activities',
				angle: -90,
				position: 'insideLeft'
			}
		}
	]
};

export default weeklyActivity;
