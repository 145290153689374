//------------------------------- Dependencies -------------------------------//
// eslint-disable-next-line
import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
//-------------------------------- Components --------------------------------//

import { useArray, useToggle } from '../../../../hooks';

import { EditButton, Section, SubmitButton, TextButton } from './index';

const detailsContainerStyle = {
	padding: '10px 0',
	margin: '5px 0'
};

export const Entries = props => {
	const {
		title,
		entries,
		fields,
		companyField,
		handleSubmit,
		TransType,
		label
	} = props;

	const [entriesData, entriesFn] = useArray(entries);

	const update = async (changes, callback) => {
		changes = changes.map((entry, index) => {
			entry.id = index;
			return entry;
		});
		let { data } = await handleSubmit(
			{ [`${companyField}`]: changes },
			TransType
		);
		let newEntries = data[`${companyField}`];
		if (Array.isArray(newEntries)) {
			entriesFn.set(newEntries);
			if (typeof callback === 'function') {
				callback();
			}
		}
	};

	const remove = payload => {
		let changes = entriesData.filter(data => data.id !== payload.id);
		update(changes);
	};

	const addEntry = () => {
		let obj = { id: entriesData.length };
		fields.forEach(field => {
			obj[`${field.name}`] = field.defaultValue;
		});
		entriesFn.push(obj, obj.id);
	};

	const isNew = payload => {
		return entries.find(con => con.id === payload.id) ? false : true;
	};

	const onSubmit = (payload, callback) => {
		let changes = entriesData.map(data =>
			data.id === payload.id ? payload : data
		);
		update(changes, callback);
	};

	useEffect(() => {
		entriesFn.set(entries);
		// eslint-disable-next-line
	}, [entries]);

	return (
		<Section
			title={title}
			Button={() => <TextButton onClick={() => addEntry()}>Add</TextButton>}
		>
			<Grid 
				container 
				style={detailsContainerStyle}
				spacing={1}
			>
				{entriesData &&
					entriesData.length > 0 &&
					entriesData.map((entry, index) => {
						let id = companyField + '-' + index;
						return (
							<Entry
								entry={entry}
								fields={fields}
								id={id}
								key={id}
								remove={remove}
								newEntry={isNew(entry)}
								handleSubmit={onSubmit}
								label={label ? `${label} ${index + 1}` : ''}
							/>
						);
					})}
			</Grid>
		</Section>
	);
};

const Entry = props => {
	// eslint-disable-next-line
	const { entry, fields, id, handleSubmit, remove, newEntry, label } = props;
	let [form, setForm] = useState(entry);

	let [editing, toggleEdit] = useToggle(newEntry);
	let [message, setMessage] = useState('');

	const handleChange = event => {
		const { name, value } = event.target;
		setForm(form => ({ ...form, [`${name}`]: value }));
	};

	const onSubmit = event => {
		event.preventDefault();
		handleSubmit(form, () => {
			setMessage('Updated Successfully.');
			toggleEdit();
		});
	};

	return (
		<Grid
			item
			style={{
				border: '1px solid #ccc',
				borderRadius: '5px',
				width: '100%',
				margin: 0,
				padding: 0
			}}
		>
			<Grid
				container
				direction='row'
				justifyContent='space-evenly'
				alignItems='flex-start'
				style={{
					minHeight: '16px'
				}}
			>
				<Grid item xs={4}>
					{label}
				</Grid>
				<Grid item xs={4}>
					{!editing && <EditButton onClick={() => toggleEdit()} />}
				</Grid>
				<Grid item xs={4}>
					{ !editing && (
						<TextButton onClick={() => remove(entry)}>Delete</TextButton>
					)}
				</Grid>
				
			</Grid>
			<form onSubmit={onSubmit}>
				<Grid container spacing={0.5}>
					{fields.map((field, index) => {
						const { Input, ...other } = field;
						let inputId = `${id}-${field.name}-${index}`;
						let defaultValue = form[field.name];
						return (
							<Input
								{...other}
								id={inputId}
								key={inputId}
								onChange={handleChange}
								InputProps={{
									readOnly: field.readOnly || !editing
								}}
								value={defaultValue}
							/>
						);
					})}
					<Grid container direction='row-reverse' style={{ minHeight: '16px' }}>
						<Grid item xs={4}>
							{(editing) && <SubmitButton />}
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							{message}
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default Entries;
