// eslint-disable-next-line
import { useState, useEffect, useCallback } from 'react';
import { Route } from 'react-router-dom';

import MainPage from '../pages/crew/unrestricted/MainPage';
import WorkOrders from '../pages/crew/unrestricted/WorkOrders';
import RequestForPrice from '../pages/crew/unrestricted/RequestForPrice';
import CrewProfile from '../pages/crew/Profile';
import allWOCols from '../pages/crew/columns/allWOs';
import closeWOCols from '../pages/crew/columns/closeWOs';
import openWOCols from '../pages/crew/columns/openWOs';
import scheduleWOCols from '../pages/crew/columns/scheduleWOs';
import rfpCols from '../pages/crew/columns/rfps';
import { useUser } from '../context/user';
import useTableData from '../hooks/useTableDataCrew';

import {
	countPastDueWOs,
	countWOsWithNoSchedule,
	getOpenServicesData,
	getVendor,
	getAllWOs,
	getOpenWOs,
	getUnscheduledWOs,
	getPastDueWOs,
	getRFPs
} from '../services/realm/vendors.js';

const dates = ['checkDate', 'scheduledDate'];
const times = ['startTime'];

const formatWOs = data => {
	return data.map(d => {
		dates.forEach(date => {
			if (d[`${date}`] === '01/01/1970') {
				d[`${date}`] = '';
			}
		});

		times.forEach(time => {
			if (d[`${time}`] === '00:00') {
				d[`${time}`] = '';
			}
		});
		return d;
	});
};

const CrewRoutes = props => {
	const { setVendor } = props;
	const {
		currentUser: { entityRecId, CrewCode }
	} = useUser();
	//------------------------- General Vendor Logic -------------------------//

	const handleVendor = useCallback(async () => {
		if (entityRecId) {
			const id = entityRecId;
			const { vendor, error } = await getVendor(id);
			if (!error) {
				setVendor(vendor);
			} else {
				console.error(error);
			}
		}
		// eslint-disable-next-line
	}, [entityRecId]);

	useEffect(() => {
		handleVendor();
	}, [handleVendor]);

	//--------------------------- Main Page Data -----------------------------//
	const [pastDue, setPastDue] = useState(null);
	const [needSchedule, setNeedSchedule] = useState(null);
	const [pieData, setPieData] = useState([]);

	const handleNeedSchedule = useCallback(async () => {
		if (CrewCode) {
			const { data, error } = await countWOsWithNoSchedule(CrewCode);
			if (!error) {
				setNeedSchedule(data);
			} else {
				console.error(error);
			}
		}
	}, [CrewCode]);

	const handlePastDue = useCallback(async () => {
		if (CrewCode) {
			const { data, error } = await countPastDueWOs(CrewCode);
			if (!error) {
				setPastDue(data);
			} else {
				console.error(error);
			}
		}
	}, [CrewCode]);

	const handlePieData = useCallback(async () => {
		if (CrewCode) {
			const { data, error } = await getOpenServicesData(CrewCode);
			if (!error) {
				const services = data.map(srv => {
					return (srv = { ...srv, value: parseInt(srv.value) });
				});
				setPieData(services);
			} else {
				console.error(error);
			}
		}
	}, [CrewCode]);

	//------------------------ Open Work Orders  Data ------------------------//

	const [openWOs, updateOpenWOs] = useTableData(getOpenWOs, data =>
		formatWOs(data)
	);

	//------------------------ All Work Orders  Data -------------------------//

	const [allWOs, updateAllWOs] = useTableData(getAllWOs, data =>
		formatWOs(data)
	);

	//---------------------- Schedule Work Orders  Data ----------------------//

	const [unscheduledWOs, updateUnscheduledWOs] =
		useTableData(getUnscheduledWOs);

	//----------------------- Close Work Orders  Data ------------------------//

	const [pastDueWOs, updatePastDueWOs] = useTableData(getPastDueWOs, data =>
		formatWOs(data)
	);

	const toolBar = { 
		canFilterRows: true, 
		canHideColumns: true,
		canExportToExcel: true,
		canGlobalFilter: true,
		canScheduleDateUpdate: true,
		tableTitle: 'Work Orders',
		docId: 'workOrders'
	}

	//--------------------------- All RFPs  Data -----------------------------//

	const [rfps, setRfps] = useState([]);

	const handleRFPs = useCallback(async () => {
		if (CrewCode) {
			const { data, error } = await getRFPs(CrewCode);
			if (!error) {
				setRfps(data);
			} else {
				console.error(error);
			}
		}
	}, [CrewCode]);

	return (
		<>
			<Route
				exact
				path='/'
				render={p => (
					<MainPage
						{...p}
						{...props}
						pageTitle='Home'
						pastDue={pastDue}
						pieData={pieData}
						handleNeedSchedule={handleNeedSchedule}
						handlePastDue={handlePastDue}
						handlePieData={handlePieData}
						needSchedule={needSchedule}
					/>
				)}
			/>
			<Route
				exact
				path='/rfp'
				render={p => (
					<RequestForPrice
						{...p}
						{...props}
						pageTitle='Request For Price'
						tableId='RequestForPrice'
						tableCols={rfpCols}
						tableData={rfps}
						handleRFPs={handleRFPs}
					/>
				)}
			/>
			<Route
				exact
				path='/workOrders/all'
				render={p => (
					<WorkOrders
						{...p}
						{...props}
						{...toolBar}
						pageTitle='All Work Orders'
						defaultHiddenColumns={['clientName', 'smiWO']}
						neverShowColumns={['clientName', 'smiWO']}
						columns={allWOCols}
						data={allWOs}
						updateData={updateAllWOs}
					/>
				)}
			/>
			<Route
				exact
				path='/workOrders/open'
				render={p => (
					<WorkOrders
						{...p}
						{...props}
						{...toolBar}
						pageTitle='Open Work Orders'
						defaultHiddenColumns={['clientName', 'smiWO']}
						neverShowColumns={['clientName', 'smiWO']}
						columns={openWOCols}
						data={openWOs}
						updateData={updateOpenWOs}
					/>
				)}
			/>
			<Route
				exact
				path='/workOrders/schedule'
				render={p => (
					<WorkOrders
						{...p}
						{...props}
						{...toolBar}
						pageTitle='Schedule Work Orders'
						defaultHiddenColumns={['city', 'state', 'clientName', 'smiWO']}
						neverShowColumns={['clientName', 'smiWO']}
						canHideColumns={false}
						columns={scheduleWOCols}
						data={unscheduledWOs}
						updateData={updateUnscheduledWOs}
						// handleWOs={handleNeedScheduleWOs}
					/>
				)}
			/>
			<Route
				exact
				path='/workOrders/close'
				render={p => (
					<WorkOrders
						{...p}
						{...props}
						{...toolBar}
						pageTitle='Close Work Orders'
						// tableTitle='Work Orders'
						defaultHiddenColumns={['city', 'state', 'clientName', 'smiWO']}
						neverShowColumns={['clientName', 'smiWO']}
						canHideColumns={false}
						columns={closeWOCols}
						data={pastDueWOs}
						updateData={updatePastDueWOs}
					/>
				)}
			/>
			<Route
				exact
				path='/profile'
				render={p => <CrewProfile {...p} {...props} pageTitle='Profile' />}
			/>
		</>
	);
};

export default CrewRoutes;
