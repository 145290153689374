import app from './Realm';

export const vendorGenerator = async () => {
	try {
		const data = await app.currentUser.functions.vendorGenerator();
		return { data };
	} catch (err) {
		return { error: 'Failed to retrieve work orders with no schedule' };
	}
};

export const findCrews = async () => {
	try {
		const data = await app.currentUser.functions.findCrews();
		return { data };
	} catch (err) {
		return { error: 'Failed to retrieve crews.' };
	}
};
//------------------------------- Transactions -------------------------------//

//used to retrieve all transactions.
export const transactionLogs = async () => {
	try {
		const data = await app.currentUser.functions.transactionLogs();
		return { data };
	} catch (err) {
		return { error: `Failed to retrieve Transaction Data: ${err}` };
	}
};

export const inboundTransactions = async () => {
	try {
		const data = await app.currentUser.functions.inboundTransactions();
		return { data };
	} catch (err) {
		return { error: `Failed to retrieve Transaction Data: ${err}` };
	}
};

export const outboundTransactions = async () => {
	try {
		const data = await app.currentUser.functions.outboundTransactions();
		return { data };
	} catch (err) {
		return { error: `Failed to retrieve Transaction Data: ${err}` };
	}
};

//-------------------------------- Login Logs --------------------------------//

//used to retrieve all transactions.
export const loginLogs = async () => {
	try {
		const data = await app.currentUser.functions.loginLogs();
		return { data };
	} catch (err) {
		return { error: `Failed to retrieve Login Data: ${err}` };
	}
};

export const getAllUsers = async () => {
	try {
		const data = await app.currentUser.functions.getAllUsers();
		return data;
	} catch (err) {
		return { error: 'Generic error' };
	}
};

export const updateUserPassword = async payload => {
	try {
		const data = await app.currentUser.functions.updateUserPassword(payload);
		return data;
	} catch (err) {
		return { error: 'Generic error' };
	}
};

//-------------------------------- Onboarding --------------------------------//

export const onboardingApprovalList = async payload => {
	try {
		const data = await app.currentUser.functions.onboardingApprovalList(
			payload
		);
		return { data };
	} catch (err) {
		return { error: 'Generic error' };
	}
};

export const approveCrewOnboarding = async payload => {
	try {
		const data = await app.currentUser.functions.approveCrewOnboarding(payload);
		return { data };
	} catch (err) {
		return { error: 'Generic error' };
	}
};

export const sendEmail = async payload => {
	try {
		const data = await app.currentUser.functions.sendEmail(payload);
		return { data };
	} catch (err) {
		return { error: 'Generic error' };
	}
};