import { useState, useEffect } from 'react';
import { closeWO } from '../../../services/realm/vendors';
import { TextField, Button, Typography, Grid} from '@material-ui/core';
import { useUser } from '../../../context/user';

const formInputs = [
	{
		name: 'invoiceNumber',
		label: 'INV #',
		type: 'text'
	},
	{
		name: 'invoiceAmount',
		label: 'INV Amount',
		type: 'number'
	},
	{
		name: 'completionDate',
		label: 'Completion Date',
		type: 'date',
		required: true
	}
];

const Container = props => {
	const { children, ...style } = props;

	return <div style={style}>{props.children}</div>;
};

const FlexRow = props => {
	const { children, ...other } = props;
	return (
		<Container {...other} display='flex' flexDirection='row'>
			{children}
		</Container>
	);
};

const FlexColumn = props => {
	const { children, ...other } = props;
	return (
		<Container {...other} display='flex' flexDirection='column'>
			{children}
		</Container>
	);
};

const messageColor = messageType => {
	switch (messageType) {
		case 'waiting':
			return '#555555';
		case 'success':
			return '#4BB543';
		case 'error':
			return '#ED4337';
		default:
			return '#OOOOOO';
	}
};

const InputWrapper = props => {
    const {children, ...other} = props;
    
    return(
        <Grid item {...other}>
			<div
				style={{
					// border: '1px solid red',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				{children}
			</div>
		</Grid>
    )
}

const FormInput = props => {
    // const {xs, sm, md, lg, xl} = props;
    // const wrapperProps = { xs, sm, md, lg, xl };
	return (
		<InputWrapper xs={4}>
			<TextField
				{...props}
				fullWidth
				size='small'
				variant='outlined'
				margin='normal'
				style={{ width: props.width || '150px', marginRight: '10px' }}
				InputLabelProps={{ shrink: true }}
			/>
		</InputWrapper>
	);
};

const FormMessage = props => {
	const { children, marginTop, strong, type } = props;
	return (
		<div>
			<Typography
				align='center'
				variant='subtitle2'
				style={{
					marginTop: marginTop || '0',
					marginBottom: '1px',
					color: messageColor(type)
				}}
			>
				{strong ? <strong>{children}</strong> : children}
			</Typography>
		</div>
	);
};

const formatDate = date => {
	const [yyyy, mm, dd] = date.split('-');
	return `${mm}/${dd}/${yyyy}`;
}

export const CloseWOForm = props => {
	const { original: { smiWO, storeName, lastUpload } } = props;
	const { currentUser } = useUser();
	const { RecId, userName, CrewCode } = currentUser;
	const [closeDisabled, setCloseDisabled] = useState(true);

	useEffect(() => {
		if (lastUpload) {
			setCloseDisabled(false);
		}
	}, [lastUpload]);

	const [formState, setFormState] = useState({
		submitting: false,
		submitted: false,
		updated: false,
		failed: false,
		invoiceNumber: '',
		invoiceAmount: '',
		completionDate: ''
	});

	const handleCloseWOChangeEvent = event => {
		const { name, value } = event.target;
		setFormState({ ...formState, [name]: value });
	};
	const begin = { submitting: true, failed: false };
	const success = { submitting: false, submitted: true };
	const update = {submitting: false, updated: true};
	const error = { submitting: false, failed: true };

	

	const handleSubmit = async e => {
		e.preventDefault();
		setFormState({ ...formState, ...begin });

		const { invoiceNumber, invoiceAmount, completionDate, submitted } =
			formState;

		const obj = {
			smiWO,
			vendorCode: CrewCode,
			UserID: userName,
			UserRecId: RecId,
			StoreName: storeName,
			invoiceNumber,
			invoiceAmount: parseFloat(invoiceAmount),
			completionDate: formatDate(completionDate)
		};

		// console.log(obj)
		

		const { data } = await closeWO(obj);

		if (data) {
			if (!submitted) {
				setFormState({ ...formState, ...success });
			} else {
				setFormState({ ...formState, ...update });
			}
		} else {
			setFormState({ ...formState, ...error });
		}
	};

	return (
		<>
			<FlexRow flexWrap='nowrap' justifyContent='space-evenly'>
				<FlexColumn
					flexWrap='nowrap'
					justifyContent='space-around'
					width='600px'
				>
					{closeDisabled && (
						<div style={{ marginTop: '4px' }}>
							<FormMessage strong type='waiting'>
								You must first upload a file before you are able to close this
								work order.
							</FormMessage>
						</div>
					)}
					<div>
						<form
							onSubmit={e => handleSubmit(e)}
							style={{
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'nowrap',
								justifyContent: 'space-around'
							}}
						>
							<Grid container>
								{formInputs.map(input => {
									return (
										<FormInput
											key={input.name}
											{...input}
											value={formState[input.name]}
											disabled={closeDisabled}
											onChange={event => handleCloseWOChangeEvent(event)}
										/>
									);
								})}

								<InputWrapper xs={12}>
									<span>
										<Button
											size='small'
											type='submit'
											variant='contained'
											color='primary'
											style={{ marginTop: '17px' }}
											disabled={closeDisabled}
										>
											{formState.submitted ? 'Update' : 'Submit'}
										</Button>
									</span>
								</InputWrapper>
							</Grid>
						</form>
					</div>
					{formState.submitting && (
						<FormMessage strong type='waiting'>
							Attempting to submit close request for WO# {smiWO}...
						</FormMessage>
					)}
					{!formState.submitting &&
						formState.submitted &&
						!formState.updated &&
						!formState.failed && (
							<FormMessage strong type='success'>
								WO# {smiWO} close request submitted successfully
							</FormMessage>
						)}
					{!formState.submitting &&
						formState.submitted &&
						formState.updated &&
						!formState.failed && (
							<FormMessage strong type='success'>
								WO# {smiWO} close request updated successfully
							</FormMessage>
						)}
					{!formState.submitting && formState.failed && (
						<FormMessage strong type='error'>
							Failed to submit close request for WO# {smiWO}
						</FormMessage>
					)}
				</FlexColumn>
			</FlexRow>
		</>
	);
};
