//------------------------------- Dependencies -------------------------------//

//-------------------------------- Components --------------------------------//
import { Grid } from '@material-ui/core';
import { TextButton } from './index';

const Form = props => {
	const {
		formContainerProps,
		formName,
		formGridProps,
		fields,
		formState,
		onSubmit,
		onInputChange
	} = props;

	return (
		<Grid
			container
			justifyContent='space-evenly'
			alignItems='flex-start'
			spacing={3}
		>
			<Grid item xs={12} {...formContainerProps}>
				<form onSubmit={onSubmit}>
					<Grid container spacing={0.5} {...formGridProps}>
						{fields.map((field, index) => {
							const { Input, ...other } = field;
							let inputId = `${formName}-${field.name}-${index}`;
							let defaultValue = formState.fields[field.name];
							return (
								<Input
									{...other}
									id={inputId}
									key={inputId}
									onChange={onInputChange}
									error={formState.error}
									InputProps={{
										readOnly: field.readOnly || formState.readOnly
									}}
									value={defaultValue}
								/>
							);
						})}
						{/* {fields.map(field => (
							<Input
								key={`${formName}-${field.name}`}
								id={`${formName}-${field.name}`}
								label={field.label}
								name={field.name}
								value={formState.fields[`${field.name}`]}
								onChange={onInputChange}
								error={formState.error}
								disabled={field.disabled}
								InputProps={{
									readOnly: field.readOnly || formState.readOnly
								}}
								style={field.style}
								gridProps={field.gridProps}
							/>
						))} */}
					</Grid>
					<Grid container style={{ marginTop: '10px' }} direction='row-reverse'>
						{formState.editing && (
							<Grid item>
								<TextButton type='submit'>Submit</TextButton>
							</Grid>
						)}
					</Grid>
				</form>
			</Grid>
			<Grid item xs={12}>
				{formState.message !== '' && formState.message}
			</Grid>
		</Grid>
	);
};

export default Form;
