import React from 'react'
import Dashboard from '../../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../../components/dashboard/Dashboard.style.js';
import Title from '../../../components/Text/Title.js';
import Link from '../../../components/Link.js';

const Task = ({onClick, children}) => {
    return(
        <div>
            <Link onClick={(e)=>onClick(e)}>
                {children}
            </Link>
        </div>
    )
}

const Home = props  => {
    // eslint-disable-next-line
    const {generalInfo, companyInfo, smiReviewed, paperwork, history} = props;

    const classes = dashboardStyles();
    return (
        <>
            <Dashboard
                {...props}
                classes={classes}
            >
                <div>
                    <Title>Registration Progress</Title>
                    {!generalInfo &&
                        <Task onClick={()=>{history.push('/registration/generalInfo')}}>
                            Submit General Information Form
                        </Task>
                    }
                    {!companyInfo &&
                        <Task onClick={()=>{history.push('/registration/companyInfo')}}>
                            Submit Company Information Form
                        </Task>
                    }
                    {generalInfo && companyInfo && !smiReviewed &&
                        <Task>
                            Your submission is currently under review
                        </Task>
                    }
                    {generalInfo && companyInfo && smiReviewed && !paperwork &&
                        <Task >
                            Submit signed onboarding packet
                        </Task>
                    }
                </div>
            </Dashboard>
        </>
    )
}

export default Home;