//------------------------------- Dependencies -------------------------------//
import React from 'react';
import clsx from 'clsx';

//-------------------------------- Components --------------------------------//
import TopBar from './TopBar';
import Drawer from './Drawer';
import Content from './Content';

const Dashboard = props => {
	const { pageTitle, ...otherProps } = props;
	const { classes } = otherProps;
	const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
	return (
		<div className={classes.root}>
			<TopBar
				pageTitle={pageTitle}
				classes={classes}
				fixedHeightPaper={fixedHeightPaper}
			/>
			<Drawer 
				classes={classes} 
				fixedHeightPaper={fixedHeightPaper} 
			/>
			<Content 
				{...otherProps} 
				fixedHeightPaper={fixedHeightPaper} 
			/>
		</div>
	);
};

export default Dashboard;
