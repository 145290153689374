import { Message } from '../index';

const Status = state => {
	const { submitting, submitted, failed, uploadError, message } = state;

	return (
		<>
			{ uploadError && (
				<Message strong type='error'>
					{uploadError}
				</Message>
			)}
			{submitting && (
				<Message strong type='waiting'>
					Attempting to upload File...
				</Message>
			)}
			{!submitting && submitted && !failed && (
				<Message strong type='success'>
					{message}
				</Message>
			)}
			{!submitting && failed && (
				<Message strong type='error'>
					{ message || 'Failed to upload File' }
				</Message>
			)}
		</>
	);
};



export default Status;
