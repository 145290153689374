import {Input, NativeSelect} from '../components'
import { contactMethods } from '../misc';


const contactFields = [
	{
		name: 'FirstName',
		label: 'First Name',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input,
		defaultValue: ''
	},
	{
		name: 'LastName',
		label: 'Last Name',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input,
		defaultValue: ''
	},
	{
		name: 'Honorific',
		label: 'Honorific',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input,
		defaultValue: ''
	},
	{
		name: 'Title',
		label: 'Title',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input,
		defaultValue: ''
	},
	{
		name: 'OfficePhone',
		label: 'Office Phone #',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input,
		defaultValue: ''
	},
	{
		name: 'OfficeExt',
		label: 'Office Ext. #',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input,
		defaultValue: ''
	},
	{
		name: 'CellPhone',
		label: 'Cell Phone #',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input,
		defaultValue: ''
	},
	{
		name: 'Fax',
		label: 'Fax #',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input,
		defaultValue: ''
	},
	{
		name: 'Email',
		label: 'Email Address',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input,
		defaultValue: ''
	},
	{
		name: 'PrefMethOfContact',
		label: 'Preferred Contact Method',
		style: {
			minWidth: '150px',
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input: NativeSelect,
		defaultValue: '',
		options: contactMethods
	},
	{
		name: 'ContactType',
		label: 'Contact Type',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input,
		defaultValue: ''
	}
	// {
	// 	name: 'IsPrimary',
	// 	label: 'Is Primary?',
	// style: {
	// 	maxWidth: '98%'
	// },
	// 	default: null,
	// 	gridProps: {
	// 		xs: 12,
	// 		md: 6
	// 	},
	// 	Input: NativeSelect
	// }
];

export default contactFields;