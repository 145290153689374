export const blueShades = [
	'#002140',
	'#00376a',
	'#004c95',
	'#0062bf',
	'#0078ea',
	'#158dff',
	'#40a2ff',
	'#6ab7ff',
	'#95cbff',
	'#bfe0ff'
];

export const orangeShades = [
	'#f86400',
	'#f06100',
	'#e95e00',
	'#e25b00',
	'#db5800',
	'#d35500',
	'#cc5200',
	'#c54f00',
	'#bd4d00',
	'#b64a00',
	'#af4700',
	'#a84400',
	'#a04100',
	'#993e00',
	'#923b00',
	'#8a3800',
	'#833500',
	'#7c3200',
	'#752f00',
	'#6d2c00',
	'#662900',
	'#5f2600',
	'#572300',
	'#502000',
	'#491d00',
	'#421a00',
	'#3a1800',
	'#331500',
	'#2c1200',
	'#240f00',
	'#1d0c00',
	'#160900',
	'#0f0600',
	'#070300',
	'#000000'
];

export const greyShades = [
	'#b5b5b5',
	'#afafaf',
	'#aaaaaa',
	'#a5a5a5',
	'#9f9f9f',
	'#9a9a9a',
	'#959595',
	'#8f8f8f',
	'#8a8a8a',
	'#858585',
	'#808080',
	'#7a7a7a',
	'#757575',
	'#707070',
	'#6a6a6a',
	'#656565',
	'#606060',
	'#5a5a5a',
	'#555555',
	'#505050',
	'#4a4a4a',
	'#454545',
	'#404040',
	'#3a3a3a',
	'#353535',
	'#303030',
	'#2b2b2b',
	'#252525',
	'#202020',
	'#1b1b1b',
	'#151515',
	'#101010',
	'#0b0b0b',
	'#050505',
	'#000000'
];
