export const types = [
	'',
	{
		label: 'Awning Cleaning',
		value: 'Awning Cleaning'
	},
	{
		label: 'Covid Cleaning',
		value: 'Covid Cleaning'
	},
	{
		label: 'DRS - Fire Restoration',
		value: 'DRS - Fire Restoration'
	},
	{
		label: 'DRS - Flood Remediation',
		value: 'DRS - Fire Restoration'
	},
	'DRS - Inspection & Testing',
	'DRS - Janitorial',
	'DRS - Mold Remediation',
	'DRS - Other',
	'DRS - Remediation Rebuild',
	'DRS - Storm Prep',
	'Flood Remediation',
	'Floor Cleaning',
	'High Dust Service',
	'Janitorial',
	'Other',
	'Power Washing Service',
	'Repairs',
	'Storm Preparedness',
	'Window Cleaning'
];

export default types;
