//------------------------------- Dependencies -------------------------------//
// eslint-disable-next-line
import { useState } from 'react';
import {
	FormControl,
	InputLabel,
	Select,
} from '@material-ui/core';
//-------------------------------- Components --------------------------------//
import {
	trackCrewUploads,
} from '../../../../services/realm/vendors';
import { uploadCrewFile } from '../../../../services/frApi';
import { useUser } from '../../../../context/user';

import {
	FlexContainer,
	UploadStatus,
	UploadButton,
	UploadInput
} from '../../../../components';

import {
	docTypes,
} from '../misc';

export const UploadFile = props => {
	const { uploads } = props;
	const { currentUser } = useUser();
	const { entityRecId, RecId, userName, CrewCode } = currentUser;

	const [uploadState, setUploadState] = useState({
		selected: false,
		submitting: false,
		submitted: false,
		updated: false,
		failed: false,
		file: {},
		message: ''
	});

	const handleUploadChangeEvent = event => {
		const { name, files } = event.target;
		const file = files[0];
		setUploadState({ ...uploadState, [name]: file });
	};

	const begin = {
		submitting: true,
		submitted: false,
		failed: false,
		message: ''
	};
	const success = { submitting: false, submitted: true, failed: false };
	const error = { submitting: false, submitted: false, failed: true };

	const handleUploadFile = async e => {
		e.preventDefault();
		setUploadState(uploadState => ({ ...uploadState, ...begin }));
		const file = e.target[0].files[0];
		const DocType = e.target[1].value;
		if (DocType !== '') {
			const data = await uploadCrewFile(
				file,
				CrewCode,
				RecId,
				entityRecId,
				userName,
				DocType
			);

			if (data) {
				setUploadState(uploadState => ({
					...uploadState,
					...success,
					message: data
				}));
				let payload = {
					query: { VendorCode: CrewCode }
				};
				let upload = {
					file: uploadState.file.name,
					DocType,
					message: data
				};
				if (uploads && uploads.length > 0) {
					payload.changes = { $push: { uploads: upload } };
				} else {
					payload.setting = { uploads: [upload] };
				}
				trackCrewUploads(payload);
			} else {
				setUploadState({
					...uploadState,
					...error,
					message: 'Error uploading file'
				});
			}
		} else {
			setUploadState({
				...uploadState,
				...error,
				message: 'Please Select a Document Type'
			});
		}
	};

	return (
		<>
			<FlexContainer row flexWrap='nowrap' justifyContent='space-evenly'>
				<FlexContainer
					col
					flexWrap='nowrap'
					justifyContent='space-around'
					minWidth='200px'
				>
					<form
						onSubmit={e => handleUploadFile(e)}
						style={{
							display: 'flex',
							flexDirection: 'column',
							flexWrap: 'nowrap',
							alignItems: 'center',
							justifyContent: 'space-around',
							margin: '10px 30px'
						}}
						name='UploadDoc'
					>
						<UploadInput
							id={`file-upload-${entityRecId}`}
							onChange={handleUploadChangeEvent}
						/>
						<FormControl style={{ marginTop: '28px' }}>
							<InputLabel shrink htmlFor='upload-crew-doc'>
								Document Type
							</InputLabel>
							<Select native id='upload-crew-doc' name='DocType'>
								{docTypes.map(({ value, label }, index) => (
									<option
										key={`upload-crew-doc-${label}-${index}`}
										value={value}
									>
										{label}
									</option>
								))}
							</Select>
						</FormControl>

						<UploadButton {...uploadState} />
					</form>

					<UploadStatus {...uploadState} />
				</FlexContainer>
			</FlexContainer>
		</>
	);
};
