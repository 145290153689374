import { useState } from 'react';
import { scheduleWO } from '../../../services/realm/vendors.js';
import { formatCurrency } from '../../../helpers/stringFormat';
import DownloadPDF from '../../../components/tables/cells/DownloadsPDF';
import { MultiSelectColumnFilter } from '../../../components/tables/filters/column';
import { StatusCell } from '../../../components/tables/cells/StatusCell';
import { TextField, Button, Typography } from '@material-ui/core';
import { useUser } from '../../../context/user';

const inputs = [
	{
		name: 'scheduledDate',
		label: 'Scheduled Date',
		type: 'date',
		required: true
	},
	{
		name: 'startTime',
		label: 'Start Time',
		type: 'time',
		required: true
	},
	{
		name: 'managerName',
		label: 'Manager Name',
		type: 'text'
	}
];

const formatDate = date => {
	const [yyyy, mm, dd] = date.split('-');
	return `${mm}/${dd}/${yyyy}`;
}

const formatTime = time => {
	const [hh, mm] = time.split(':');
	const hH = parseInt(hh);
	const period = hH > 12 ? 'pm' : 'am';
	const HH = hH > 12 ? hH - 12 : hH;
	return `${HH < 10 ? '0' + HH : HH }:${mm}${period}`;
}

const messageColor = messageType => {
	switch (messageType) {
		case 'waiting':
			return '#555555';
		case 'success':
			return '#4BB543';
		case 'error':
			return '#ED4337';
		default:
			return '#OOOOOO';
	}
};

const FormInput = props => {
	return (
		<TextField
			{...props}
			size='small'
			variant='outlined'
			margin='normal'
			style={{ width: '150px', marginRight: '10px' }}
			InputLabelProps={{ shrink: true }}
		/>
	);
};

const FormMessage = props => {
	const { children, strong, type } = props;
	return (
		<div>
			<Typography
				align='center'
				variant='subtitle2'
				style={{
					marginTop: '0',
					marginBottom: '1px',
					color: messageColor(type)
				}}
			>
				{strong ? <strong>{children}</strong> : children}
			</Typography>
		</div>
	);
};

const ScheduleForm = props => {
	const { original: { smiWO, storeName } } = props.row;
	const { currentUser } = useUser();
	const { RecId, userName, CrewCode } = currentUser;

	const [formState, setFormState] = useState({
		submitting: false,
		submitted: false,
		updated: false,
		failed: false,
		scheduledDate: '',
		startTime: '',
		managerName: ''
	});

	const handleChangeEvent = event => {
		const { name, value } = event.target;
		setFormState({ ...formState, [name]: value });
	};

	const begin = {submitting: true, failed: false};
	const success = {submitting: false, submitted: true};
	const update = {submitting: false, updated: true};
	const error = {submitting: false, failed: true};

	const handleSubmit = async e => {
		e.preventDefault();
		setFormState({ ...formState, ...begin });

		const { scheduledDate, startTime, managerName } = formState;
		const [hours, minutes] = startTime.split(':');
		const formattedTime = new Date(scheduledDate);
		formattedTime.setHours(hours, minutes);

		const obj = {
			formattedTime,
			smiWO,
			vendorCode: CrewCode,
			UserID: userName,
			UserRecId: RecId,
			StoreName: storeName,
			scheduledDate: formatDate(scheduledDate),
			startTime: formatTime(startTime),
			managerName
		};

		const {data} = await scheduleWO(obj)

		if (data){
			if ( !submitted ){
				setFormState({ ...formState, ...success });
			} else {
				setFormState({ ...formState, ...update });
			}
		} else {
			setFormState({ ...formState, ...error });
		}
	};

	const { submitting, submitted, updated, failed } = formState;
	return (
		<>
			<div>
				<form onSubmit={e => handleSubmit(e)}>
					{inputs.map(input => {
						return (
							<FormInput
								key={input.name}
								{...input}
								value={formState[input.name]}
								onChange={event => handleChangeEvent(event)}
							/>
						);
					})}
					<Button
						size='small'
						type='submit'
						variant='contained'
						color='primary'
						style={{ marginTop: '17px' }}
					>
						{submitted ? 'Update' : 'Submit'}
					</Button>
				</form>
			</div>
			{ submitting && 
				<FormMessage strong type='waiting'>
					Submitting schedule for WO# {smiWO}...
				</FormMessage>
			}
			{ !submitting && submitted && !updated && !failed &&
				<FormMessage strong type='success'>
					Schedule submitted successfully for WO# {smiWO}
				</FormMessage>
			}
			{ !submitting && submitted && updated && !failed &&
				<FormMessage strong type='success'>
					Schedule updated successfully for WO# {smiWO}
				</FormMessage>
			}
			{ !submitting && failed && 
				<FormMessage strong type='error'>
					Failed to submit schedule for WO# {smiWO}
				</FormMessage>
			}
		</>
	);
};
const scheduleWOs = [
	{
		Header: 'MO',
		accessor: 'month',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 40,
		textAlign: 'center'
	},
	{
		Header: 'StoreName',
		accessor: 'storeName',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 150,
		textAlign: 'center'
	},
	{
		Header: 'Location',
		accessor: 'location',
		disableFilters: true,
		width: 350,
	},
	{
		Header: 'WO#',
		accessor: 'custWO',
		disableFilters: true,
		width: 100,
		textAlign: 'center',
		Cell: DownloadPDF
	},
	{
		Header: 'SMI-WO#',
		accessor: 'smiWO',
		disableFilters: true,
		width: 95,
		textAlign: 'center'
	},
	{
		Header: 'Status',
		accessor: 'status',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 95,
		textAlign: 'center',
		Cell: e=> StatusCell(e)
	},
	{
		// hidden column necessary for filtering //! NOW VISIBLE
		Header: 'Service Type',
		accessor: 'serviceType',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	// {  //! REMOVED AND REPLACED WITH SERVICE TYPE PER ROBYN
	// 	Header: 'Task',
	// 	accessor: 'task',
	// 	disableFilters: true,
	// 	width: 95
	// },
	{
		Header: 'Payout',
		accessor: 'payOut',
		disableFilters: true,
		width: 75,
		Cell: ({ value }) => formatCurrency(value),
		textAlign: 'center'
	},
	{
		Header: 'Store Phone#',
		accessor: 'storePhone',
		disableFilters: true,
		width: 100,
		textAlign: 'center'
	},
	{
		Header: '',
		id: 'form',
		width: 525,
		disableFilters: true,
		Cell: e => ScheduleForm(e),
		textAlign: 'center'
	}
];

export default scheduleWOs;
