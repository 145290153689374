import { MultiSelectColumnFilter } from '../../../components/tables/filters/column';
import {StatusCell} from '../../../components/tables/cells/StatusCell';
import DownloadPDF from '../../../components/tables/cells/DownloadsPDF';
import { civilianTime, formatCurrency } from '../../../helpers/stringFormat';

const openWOs = [
	{
		// hidden column necessary for filtering
		Header: 'Client Name',
		accessor: 'clientName',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'StoreName',
		accessor: 'storeName',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 150,
		textAlign: 'center'
	},
	{
		// hidden column necessary for filtering
		Header: 'City',
		accessor: 'city',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		// hidden column necessary for filtering
		Header: 'State',
		accessor: 'state',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 50,
		textAlign: 'center'
	},
	{
		Header: 'Zip',
		accessor: 'zip',
		disableFilters: true,
		width: 50,
		textAlign: 'center'
	},
	{
		Header: 'WO#',
		accessor: 'custWO',
		disableFilters: true,
		Cell: DownloadPDF,
		width: 50,
		textAlign: 'center'
	},
	{
		// hidden column necessary for filtering
		Header: 'SMI-WO#',
		accessor: 'smiWO',
		disableFilters: true,
		width: 95,
		textAlign: 'center'
	},
	{
		Header: 'Status',
		accessor: 'status',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 95,
		textAlign: 'center',
		Cell: e => StatusCell(e)
	},
	{
		// hidden column necessary for filtering
		Header: 'Service Type',
		accessor: 'serviceType',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'Payout',
		accessor: 'payOut',
		disableFilters: true,
		width: 75,
		Cell: ({ value }) => formatCurrency(value),
		textAlign: 'center'
	},
	{
		Header: 'MO',
		accessor: 'month',
		Filter: MultiSelectColumnFilter,
		filter: 'includesSome',
		width: 40,
		textAlign: 'center'
	},
	{
		Header: 'Store Phone#',
		accessor: 'storePhone',
		disableFilters: true,
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'Schedule Date',
		accessor: 'scheduledDate' || 'TempScheduleDate',
		disableFilters: true,
		width: 100,
		textAlign: 'center'
	},
	{
		Header: 'Time',
		accessor: 'startTime' || 'TempStartTime',
		disableFilters: true,
		width: 100,
		Cell: ({ value }) => civilianTime(value),
		textAlign: 'center'
	}
];

export default openWOs;
