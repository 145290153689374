const fs14 = {
	fontSize: '14px',
	fontWeight: '400',
	margin: 0,
	padding: 0,
	color: '#000'
};

const fs11 = {
	fontSize: '11px',
	fontWeight: '500',
	margin: 0,
	padding: 0,
	lineHeight: '11px',
	color: '#666'
};

export const StatusCell = props => {
	const {
		original: { status, paperworkSubmitted }
	} = props.row;

	return (
		<>
			<p style={fs14}>{status}</p>
			{paperworkSubmitted && <p style={fs11}>Paperwork Submitted</p>}
		</>
	);
};


export default StatusCell;