// eslint-disable-next-line
import { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';

import ClientMainPage from '../pages/client/MainPage';
// import FourOFour from '../pages/errors/FourOFour';

const ClientRoutes = props => {
	<>
		<Route 
            exact 
            path='/' 
            render={p => <ClientMainPage {...p} {...props} /> } 
        />
        {/* <Route path="*" component={FourOFour} /> */}
	</>;
};

export default ClientRoutes