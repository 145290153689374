//------------------------------- Dependencies -------------------------------//
import { useEffect } from 'react';

//-------------------------------- Components --------------------------------//
import Dashboard from '../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../components/dashboard/Dashboard.style.js';
import Table from '../../components/tables/EnhancedTable';

const DataTable = props => {
	const { handleData, columns, data, ...otherProps } = props;

	useEffect(() => {
		if (handleData) {
			handleData(data);
			return () => {
				handleData([]);
			};
		}
	}, [handleData, data]);

	const classes = dashboardStyles();
	return (
		<Dashboard {...otherProps} fullPage={true} classes={classes}>
			<Table
				{...otherProps}
				defaultHiddenColumns={[]}
				neverShowColumns={[]}
				columns={columns}
				data={data}
			/>
		</Dashboard>
	);
};

export default DataTable;
