import { Grid, Paper } from '@material-ui/core';

const Tile = props => {
	const {
		fixedHeightPaper,
		fullPage,
		height = 500,
		children,
		xs = 12,
		md = 6,
		lg = 6
	} = props;
	const style = fullPage ? { height: '85vh' } : { height: height };
	return (
		<Grid item xs={xs} md={md} lg={lg}>
			<Paper className={fixedHeightPaper} style={style}>
				{children}
			</Paper>
		</Grid>
	);
};

export default Tile;
