import { Input, NativeSelect } from '../components';
import { stateAbbreviations } from '../misc';

const operationsAddress = [
	{
		name: 'OP_Attention',
		label: 'Attention',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12
		},
		Input
	},
	{
		name: 'OP_Addr1',
		label: 'Addr1',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12
		},
		Input
	},
	{
		name: 'OP_Addr2',
		label: 'Addr2',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12
		},
		Input
	},
	{
		name: 'OP_City',
		label: 'City',
		style: {
			maxWidth: '97.5%'
		},
		gridProps: {
			xs: 12,
			sm: 6,
			lg: 4
		},
		Input
	},
	{
		name: 'OP_State',
		label: 'State',
		style: {
			minWidth: '125px',
			maxWidth: '97.5%'
		},
		gridProps: {
			xs: 12,
			sm: 6,
			lg: 4
		},
		Input: NativeSelect,
		options: stateAbbreviations
	},
	{
		name: 'OP_Zip',
		label: 'Zip',
		style: {
			minWidth: '75px',
			maxWidth: '97.5%'
		},
		gridProps: {
			xs: 12,
			sm: 6,
			lg: 2
		},
		Input
	},
	{
		name: 'OP_ZipPlus4',
		label: '+4',
		style: {
			minWidth: '65px',
			maxWidth: '97.5%'
		},
		gridProps: {
			xs: 12,
			sm: 6,
			lg: 2
		},
		Input
	}
];

export default operationsAddress;
