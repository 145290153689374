// import { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { useUser } from '../context/user';

import Access from '../pages/auth/Access'
import Login from '../pages/auth/Login';
import { RecoverPage, ResetPage } from '../pages/user';
import CrewRoutes from './CrewRoutes';
import NewCrewRoutes from './NewCrewRoutes';
import ClientRoutes from './ClientRoutes';
import EmployeeRoutes from './EmployeeRoutes';
import FourOFour from '../pages/errors/FourOFour';

import { CrewUsersContextProvider } from '../context/crews';

const Routes = props => {
	const { authorization, logged } = useUser();

	return (
		<Router>
			<Switch>
				{logged === false && authorization === undefined && (
					<>
						<Route exact path='/' render={p => <Login {...p} {...props} />} />
						<Route
							exact
							path='/registration/:invitation'
							render={p => <Access {...p} {...props} />}
						/>
						<Route
							exact
							path='/recover'
							render={p => <RecoverPage {...p} {...props} />}
						/>
						<Route
							exact
							path='/reset-password'
							render={p => <ResetPage {...p} {...props} />}
						/>
						<Route
							exact
							path='/reset-password/:request_id'
							render={p => <ResetPage {...p} {...props} />}
						/>
					</>
				)}

				{authorization === 'V' && (
					<CrewRoutes {...props} authorization={authorization} />
				)}
				{authorization === 'C' && (
					<ClientRoutes {...props} authorization={authorization} />
				)}
				{authorization === 'S' && (
					<CrewUsersContextProvider>
						<EmployeeRoutes {...props} authorization={authorization} />
					</CrewUsersContextProvider>
				)}
				{authorization === 'N' && (
					<NewCrewRoutes {...props} authorization={authorization} />
				)}
				<Route path='*' component={FourOFour} />
			</Switch>
		</Router>
	);
};

export default Routes;
