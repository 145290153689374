const franchises = [
	'',
	'911 Restoration',
	'Fish Window Cleaning',
	'Jani-King',
	'Jan-Pro',
	'Office Pride',
	'Paul Davis',
	'PuroClean',
	'Restoration1',
	'Servpro',
	'Stanley Steemer',
	'System4',
	'WMF Restoration'
];

export default franchises;
