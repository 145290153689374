import { Input, NativeSelect } from '../components';
import { equipmentTypes } from '../misc';

const equipmentFields = [
	{
		name: 'equipmentType',
		label: 'Equipment Type',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
		Input: NativeSelect,
		defaultValue: '',
		options: equipmentTypes
	},
	{
		name: 'ownedAmount',
		label: 'ownedAmount',
		style: {
			maxWidth: '98%'
		},
		gridProps: {
			xs: 12,
			md: 6
		},
        type: 'number',
		Input,
		defaultValue: 0
	}

];

export default equipmentFields;
