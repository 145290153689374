//------------------------------- Dependencies -------------------------------//
// eslint-disable-next-line
import { useCallback, useEffect, useState } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
//-------------------------------- Components --------------------------------//
import { Title, H1 } from '../../components';
import Dashboard from '../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../components/dashboard/Dashboard.style';

import { vendorCompanyDetails, crewUpdate } from '../../services/realm/vendors';

import { useUser } from '../../context/user';
// import { useArray, useToggle } from '../../hooks'

import {
	airportFields,
	companyDetails,
	companyBilling,
	contactFields,
	equipmentFields,
	militaryBaseFields,
	operationsAddress,
	otherNMCFields,
	serviceFields,
	specializedCapabilities,
	thirdPartySystemFields,
	tradeLicenseFields
} from './Profile/Forms';

import {
	EditButton,
	Entries,
	Form,
	Section,
	UploadFile
} from './Profile/components';

import { newDownloads } from './Profile/misc';

// const dataLists = [

// ];

const isTrue = value => {
	let truths = [1, '1', true, 'true', 'TRUE'];
	return truths.includes(value);
};

const ProfileForm = props => {
	const { title, onEdit, ...formProps } = props;

	return (
		<Section
			title={title}
			Button={() => <EditButton onClick={() => onEdit()} />}
		>
			<Form {...formProps} />
		</Section>
	);
};

const Profile = props => {
	const { currentUser } = useUser();
	const [company, setCompany] = useState({});

	let defaultStatuses = {
		editing: false,
		readOnly: true,
		submitting: false,
		submitted: false,
		updated: false,
		failed: false,
		error: false,
		message: ''
	};

	const defaultFormState = (formFields, statuses = defaultStatuses) => {
		let fields = {};
		formFields.forEach(field => {
			if (!field.defaultValue) {
				field.defaultValue = '';
			}
			fields[`${field.name}`] = field.defaultValue;
		});
		return { fields, ...statuses };
	};

	//---------------------------- Company Details ---------------------------//
	let [companyFormFields] = useState(companyDetails);

	const [companyFormState, setCompanyFormState] = useState(() =>
		defaultFormState(companyFormFields)
	);

	const updateCompanyState = payload => {
		setCompanyFormState(currState => ({ ...currState, ...payload }));
	};

	const updateCompanyForm = event => {
		setCompanyFormState(currState => ({
			...currState,
			fields: {
				...currState.fields,
				[`${event.target.name}`]: event.target.value
			}
		}));
	};

	const submitCompanyForm = async event => {
		event.preventDefault();
		try {
			updateCompanyState({
				submitting: true,
				readOnly: true
			});

			let fields = {};

			companyFormFields.forEach(field => {
				if (!field.exclude) {
					let value = companyFormState.fields[`${field.name}`];

					if (value === undefined || value === null) {
						value = '';
					}
					fields[`${field.name}`] = value;
				}
			});

			const { data } = await handleSubmit(fields);

			if (data) {
				updateCompanyState({
					editing: false,
					submitting: false,
					submitted: true,
					error: false,
					message: 'Company details updated successfully.'
				});
			}
		} catch (error) {
			console.error(error);
			updateCompanyState({
				submitting: false,
				error: true,
				inputColor: 'error',
				message: 'Failed to update company details.'
			});
		}
	};

	//---------------------------- Company Billing ---------------------------//
	let [billingFormFields] = useState(companyBilling);

	const [billingFormState, setBillingFormState] = useState(() =>
		defaultFormState(billingFormFields)
	);

	const updateBillingFormState = payload => {
		setBillingFormState(currState => ({ ...currState, ...payload }));
	};

	const updateBillingForm = event => {
		setBillingFormState(currState => ({
			...currState,
			fields: {
				...currState.fields,
				[`${event.target.name}`]: event.target.value
			}
		}));
	};

	const submitBillingForm = async event => {
		event.preventDefault();
		try {
			updateBillingFormState({
				submitting: true,
				readOnly: true
			});

			const { data } = await handleSubmit(billingFormState.fields);

			if (data) {
				updateBillingFormState({
					editing: false,
					submitting: false,
					submitted: true,
					error: false,
					message: 'Billing information updated successfully.'
				});
			}
		} catch (error) {
			console.error(error);
			updateBillingFormState({
				submitting: false,
				error: true,
				inputColor: 'error',
				message: 'Failed to update billing information.'
			});
		}
	};

	//-------------------------- Operations Billing --------------------------//
	let [operationsFormFields] = useState(operationsAddress);

	const [operationsFormState, setOperationsFormState] = useState(() => {
		return defaultFormState(operationsFormFields);
	});

	const updateOperationsFormState = payload => {
		setOperationsFormState(currState => ({ ...currState, ...payload }));
	};

	// eslint-disable-next-line
	const updateOperationsForm = event => {
		setOperationsFormState(currState => ({
			...currState,
			fields: {
				...currState.fields,
				[`${event.target.name}`]: event.target.value
			}
		}));
	};

	const submitOperationsForm = async event => {
		event.preventDefault();
		try {
			updateOperationsFormState({
				submitting: true,
				readOnly: true
			});

			const { data } = await handleSubmit(operationsFormState.fields);

			if (data) {
				updateOperationsFormState({
					editing: false,
					submitting: false,
					submitted: true,
					error: false,
					message: 'Operations information updated successfully.'
				});
			}
		} catch (error) {
			console.error(error);
			updateOperationsFormState({
				submitting: false,
				error: true,
				inputColor: 'error',
				message: 'Failed to update operations information.'
			});
		}
	};

	const setDefaultValues = (data, fields, setFormState) => {
		fields.forEach(field =>
			setFormState(currentState => ({
				...currentState,
				fields: {
					...currentState.fields,
					[`${field.name}`]: data[`${field.name}`]
				}
			}))
		);
	};

	const { vendor } = props;

	const getCompany = useCallback(async () => {
		const { vendorId } = vendor;
		const { data } = await vendorCompanyDetails(vendorId);
		if (data && data.VendorCode === currentUser.CrewCode) {
			if (!data.contacts) {
				handleSubmit({ contacts: [] }, 'CrewContactsUpdate');
			}
			if (!data.serviceTypes) {
				handleSubmit({ serviceTypes: [] }, 'CrewServiceTypesUpdate');
			}
			if (!data.specializedCapabilities) {
				handleSubmit(
					{ specializedCapabilities: [] },
					'CrewSpecializedCapabilitiesUpdate'
				);
			}
			if (!data.equipmentOwned) {
				handleSubmit({ equipmentOwned: [] }, 'CrewEquipmentOwnedUpdate');
			}
			if (!data.thirdPartySystems) {
				handleSubmit({ thirdPartySystems: [] }, 'CrewThirdPartySystemsUpdate');
			}
			if (!data.tradeLicenses) {
				handleSubmit({ tradeLicenses: [] }, 'CrewTradeLicensesUpdate');
			}
			if (!data.airportCodes) {
				handleSubmit({ airportCodes: [] }, 'CrewAirportCodesUpdate');
			}
			if (!data.militaryBases) {
				handleSubmit({ militaryBases: [] }, 'CrewMilitaryBasesUpdate');
			}
			if (!data.otherNMCs) {
				handleSubmit({ otherNMCs: [] }, 'CrewOtherNMCsUpdate');
			}
		}
		data.EntSmi = isTrue(data.EntSmi);
		data.EntDrs = isTrue(data.EntDrs);
		setCompany(data);
		setDefaultValues(data, companyFormFields, setCompanyFormState);
		setDefaultValues(data, billingFormFields, setBillingFormState);
		setDefaultValues(data, operationsFormFields, setOperationsFormState);
		// eslint-disable-next-line
	}, [
		vendor
		// companyFormFields,
		// billingFormFields,
		// operationsFormFields
	]);

	useEffect(() => {
		getCompany();
	}, [getCompany]);

	//------------------------------ UI Context ------------------------------//
	const classes = dashboardStyles();

	const { _id, uploads } = company;

	let uploadedDocTypes = [];
	if (uploads && uploads.length > 0) {
		uploads.forEach(doc => {
			uploadedDocTypes.push(doc.DocType);
		});
	}

	const handleSubmit = async (changes, TransType = 'CrewUpdate') => {
		let payload = {
			query: { VendorCode: currentUser.CrewCode },
			changes,
			other: {
				_id,
				UserRecId: currentUser.RecId,
				UserID: currentUser.userName,
				CrewCode: currentUser.CrewCode,
				TransType
			}
		};
		let { data } = await crewUpdate(payload);

		return { data };
	};

	const expired = dateString => {
		let today = new Date();
		let expiration = new Date(dateString);
		return today.valueOf() > expiration.valueOf();
	};

	const valid = date => {
		let isValid = false;
		if (date instanceof Date){
			isValid = true;
		} else if (typeof date === 'string'){
			let d = new Date( date ); 
			if (d instanceof Date && !isNaN(d)){
				isValid = true;
			}
			
		}
		return isValid
	}


	return (
		<>
			<Dashboard {...props} classes={classes}>
				<ProfileForm
					title='Company Details'
					formName='company-details'
					onEdit={() => updateCompanyState({ editing: true, readOnly: false })}
					fields={companyFormFields}
					formState={companyFormState}
					onSubmit={submitCompanyForm}
					onInputChange={updateCompanyForm}
				/>

				<div>
					<Title> Blank Documents</Title>
					<Grid container style={detailsContainerStyle}>
						<>
							{newDownloads.map(({ file, label, docType }, index) => (
								<DownloadLink
									key={`file-download-${index}`}
									href={`${resources}${file}`}
									download={file}
									uploaded={uploadedDocTypes.includes(docType)}
								>
									{label}
								</DownloadLink>
							))}
						</>
					</Grid>

					<Typography variant='caption'>
						Please note as of July 2022, SMI | DRS has consolidated the
						following forms for your convenience: Code of Conduct, Crew
						Information, Certificate of Insurance, Subcontractor Agreement. If
						you are uploading an updated form please use the consolidated
						versions above.
					</Typography>

					<div style={{ width: '100%', borderBottom: '1px solid #ccc' }} />
					<div>
						<Title>Upload</Title>
						<UploadFile {...props} uploads={uploads} />
					</div>
				</div>

				<ProfileForm
					title='Mailing/Remit To Address'
					formName='business-address'
					onEdit={() =>
						updateBillingFormState({ editing: true, readOnly: false })
					}
					fields={billingFormFields}
					formState={billingFormState}
					onSubmit={submitBillingForm}
					onInputChange={updateBillingForm}
				/>

				<ProfileForm
					title='Operations Address'
					formName='operations-address'
					onEdit={() =>
						updateOperationsFormState({ editing: true, readOnly: false })
					}
					fields={operationsFormFields}
					formState={operationsFormState}
					onSubmit={submitOperationsForm}
					onInputChange={updateOperationsForm}
				/>

				<Entries
					title='Contacts'
					label='Contact'
					entries={company.contacts}
					fields={contactFields}
					companyField='contacts'
					handleSubmit={handleSubmit}
					TransType='CrewContactsUpdate'
				/>

				<Entries
					title='Service Types'
					// label='Service Type'
					entries={company.serviceTypes}
					fields={serviceFields}
					companyField='serviceTypes'
					handleSubmit={handleSubmit}
					TransType='CrewServiceTypesUpdate'
				/>

				<Entries
					title='Specialized Capabilities'
					// label= 'Specialized Capability'
					entries={company.specializedCapabilities}
					fields={specializedCapabilities}
					companyField='specializedCapabilities'
					handleSubmit={handleSubmit}
					TransType='CrewSpecializedCapabilitiesUpdate'
				/>

				<Entries
					title='Equipment Owned'
					// label='Equipment'
					entries={company.equipmentOwned}
					fields={equipmentFields}
					companyField='equipmentOwned'
					handleSubmit={handleSubmit}
					TransType='CrewEquipmentOwnedUpdate'
				/>

				<Entries
					title='Third Party Systems'
					// label='Third Party System'
					entries={company.thirdPartySystems}
					fields={thirdPartySystemFields}
					companyField='thirdPartySystems'
					handleSubmit={handleSubmit}
					TransType='CrewThirdPartySystemsUpdate'
				/>

				<Entries
					title='Trade Licenses'
					// label='Trade License'
					entries={company.tradeLicenses}
					fields={tradeLicenseFields}
					companyField='tradeLicenses'
					handleSubmit={handleSubmit}
					TransType='CrewTradeLicensesUpdate'
				/>

				<Entries
					title='Airport Codes'
					// label= 'Airport'
					entries={company.airportCodes}
					fields={airportFields}
					companyField='airportCodes'
					handleSubmit={handleSubmit}
					TransType='CrewAirportCodesUpdate'
				/>

				<Entries
					title='Military Bases'
					// label='Military Base'
					entries={company.militaryBases}
					fields={militaryBaseFields}
					companyField='militaryBases'
					handleSubmit={handleSubmit}
					TransType='CrewMilitaryBasesUpdate'
				/>

				<Entries
					title='Other NMC'
					// label='Military Base'
					entries={company.otherNMCs}
					fields={otherNMCFields}
					companyField='otherNMCs'
					handleSubmit={handleSubmit}
					TransType='CrewOtherNMCsUpdate'
				/>

				<div>
					<Title>Subcontractor Agreement(s)</Title>
					<Grid
						container
						justifyContent='space-around'
						style={detailsContainerStyle}
					>
						{company !== {} && company.EntSmi && (
							<Grid item>
								<Detail>
									SMI Subcontractor Agreement Received:{' '}
									{valid(company.ContractReceived) ? '✔' : '❌'}
								</Detail>
								<Detail>Date Signed: {company.ContractReceived}</Detail>
							</Grid>
						)}
						{company !== {} && company.EntDrs && (
							<Grid item>
								<Detail>
									DRS Subcontractor Agreement Received:{' '}
									{valid(company.ContractReceivedD) ? '✔' : '❌'}
								</Detail>
								<Detail>Date Signed: {company.ContractReceivedD}</Detail>
							</Grid>
						)}
						<Grid item xs={12}>
							<Typography variant='caption'>
								*If your agreement was signed over four years from today's date
								please upload a current copy.
							</Typography>
						</Grid>
					</Grid>
				</div>

				<div>
					<Title>Code of Conduct</Title>
					<Grid
						container
						justifyContent='space-around'
						style={detailsContainerStyle}
					>
						<Grid item>
							<Detail>
								Vendor Code Of Conduct Received:{' '}
								{valid(company.VendorCodeOfConduct) ? '✔' : '❌'}
							</Detail>
							<Detail>
								Date Signed: {company.VendorCodeOfConduct || 'N/A'}
							</Detail>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption'>
								*If your agreement was signed over four years from today's date
								please upload a current copy.
							</Typography>
						</Grid>
					</Grid>
				</div>

				<div>
					<Title>Workers Comp</Title>
					<Grid
						container
						justifyContent='space-around'
						style={detailsContainerStyle}
					>
						{company !== {} && company.EntSmi && (
							<Grid item>
								<Detail>
									SMI Workers Comp:{' '}
									{isTrue(company.HasWkComp) && !expired(company.WkCompExpire)
										? '✔'
										: '❌'}
								</Detail>
								<Detail>Expires: {company.WkCompExpire || 'N/A'}</Detail>
								<Detail>Limit: {`${company.WKComp_Limit}` || 'N/A'}</Detail>
								<Detail>
									Listed as Addl Insured:{' '}
									{isTrue(company.WkComp_AddlIns) ? '✔' : '❌'}
								</Detail>
							</Grid>
						)}
						{company !== {} && company.EntDrs && (
							<Grid item>
								<Detail>
									DRS Workers Comp:{' '}
									{isTrue(company.HasWkCompD) && !expired(company.WkCompExpireD)
										? '✔'
										: '❌'}
								</Detail>
								<Detail>Expires: {company.WkCompExpireD || 'N/A'}</Detail>
								<Detail>Limit: {`${company.WKComp_LimitD}` || 'N/A'}</Detail>
								<Detail>
									Listed as Addl Insured:{' '}
									{isTrue(company.WkComp_AddlInsD) ? '✔' : '❌'}
								</Detail>
							</Grid>
						)}
					</Grid>
				</div>

				<div>
					<Title>General Liability</Title>
					<Grid
						container
						justifyContent='space-around'
						style={detailsContainerStyle}
					>
						{company !== {} && company.EntSmi && (
							<Grid item>
								<Detail>
									SMI General Liability:{' '}
									{isTrue(company.HasGenLiab) && !expired(company.GenLiabExpire)
										? '✔'
										: '❌'}
								</Detail>
								<Detail>Expires: {company.GenLiabExpire}</Detail>
								<Detail>Limit: {`${company.GenLiab_Limit}`}</Detail>
								<Detail>
									Listed as Addl Insured:{' '}
									{isTrue(company.GenLiab_AddlIns) ? '✔' : '❌'}
								</Detail>
							</Grid>
						)}
						{company !== {} && company.EntDrs && (
							<Grid item>
								<Detail>
									DRS General Liability:{' '}
									{isTrue(company.HasGenLiabD) &&
									!expired(company.GenLiabExpireD)
										? '✔'
										: '❌'}
								</Detail>
								<Detail>Expires: {company.GenLiabExpireD}</Detail>
								<Detail>Limit: {`${company.GenLiab_LimitD}`}</Detail>
								<Detail>
									Listed as Addl Insured:{' '}
									{isTrue(company.GenLiab_AddlInsD) ? '✔' : '❌'}
								</Detail>
							</Grid>
						)}
					</Grid>
				</div>

				<div>
					<Title>Auto Liability</Title>
					<Grid
						container
						justifyContent='space-around'
						style={detailsContainerStyle}
					>
						{company !== {} && company.EntSmi && (
							<Grid item>
								<Detail>
									SMI Auto Liability:{' '}
									{isTrue(company.HasAutoLiab) &&
									!expired(company.AutoLiabExpire)
										? '✔'
										: '❌'}
								</Detail>
								<Detail>Expires: {company.AutoLiabExpire}</Detail>
								<Detail>Limit:{`${company.AutoLiab_Limit}`}</Detail>
								<Detail>
									Listed as Addl Insured:{' '}
									{isTrue(company.AutoLiab_AddlIns) ? '✔' : '❌'}
								</Detail>
							</Grid>
						)}
						{company !== {} && company.EntDrs && (
							<Grid item>
								<Detail>
									DRS Auto Liability:{' '}
									{isTrue(company.HasAutoLiabD) &&
									!expired(company.AutoLiabExpireD)
										? '✔'
										: '❌'}
								</Detail>
								<Detail>Expires: {company.AutoLiabExpireD}</Detail>
								<Detail>Limit:{`${company.AutoLiab_LimitD}`}</Detail>
								<Detail>
									Listed as Addl Insured:{' '}
									{isTrue(company.AutoLiab_AddlInsD) ? '✔' : '❌'}
								</Detail>
							</Grid>
						)}
					</Grid>
				</div>

				<div>
					<Title>Pollution Insurance</Title>
					<Grid
						container
						justifyContent='space-around'
						style={detailsContainerStyle}
					>
						{company !== {} && company.EntSmi && (
							<Grid item>
								<Detail>
									SMI Pollution Insurance:{' '}
									{isTrue(company.HasPolIns) &&
									!expired(company.PollutionExpire)
										? '✔'
										: '❌'}
								</Detail>
								<Detail>Expires: {company.PollutionExpire}</Detail>
								<Detail>Limit: {`${company.Pollution_Limit}`}</Detail>
								<Detail>
									Listed as Addl Insured:{' '}
									{isTrue(company.Pollution_AddlIns) ? '✔' : '❌'}
								</Detail>
							</Grid>
						)}
						{company !== {} && company.EntDrs && (
							<Grid item>
								<Detail>
									DRS Pollution Insurance:{' '}
									{isTrue(company.HasPolInsD) &&
									!expired(company.PollutionExpireD)
										? '✔'
										: '❌'}
								</Detail>
								<Detail>Expires: {company.PollutionExpireD}</Detail>
								<Detail>Limit: {`${company.Pollution_LimitD}`}</Detail>
								<Detail>
									Listed as Addl Insured:{' '}
									{isTrue(company.Pollution_AddlInsD) ? '✔' : '❌'}
								</Detail>
							</Grid>
						)}
					</Grid>
				</div>

				<div>
					<Title>Professional Insurance</Title>
					<Grid
						container
						justifyContent='space-around'
						style={detailsContainerStyle}
					>
						{company !== {} && company.EntSmi && (
							<Grid item>
								<Detail>
									SMI Professional Insurance:{' '}
									{isTrue(company.HasProfIns) && !expired(company.ProfInsExpire)
										? '✔'
										: '❌'}
								</Detail>
								<Detail>Expires: {company.ProfInsExpire}</Detail>
								<Detail>Limit: {`${company.ProfIns_Limit}`}</Detail>
								<Detail>
									Listed as Addl Insured:{' '}
									{isTrue(company.Prof_AddlIns) ? '✔' : '❌'}
								</Detail>
							</Grid>
						)}

						{company !== {} && company.EntDrs && (
							<Grid item>
								<Detail>
									DRS Professional Insurance:{' '}
									{isTrue(company.HasProfInsD) &&
									!expired(company.ProfInsExpireD)
										? '✔'
										: '❌'}
								</Detail>
								<Detail>Expires: {company.ProfInsExpireD}</Detail>
								<Detail>Limit: {`${company.ProfIns_LimitD}`}</Detail>
								<Detail>
									Listed as Addl Insured:{' '}
									{isTrue(company.Prof_AddlInsD) ? '✔' : '❌'}
								</Detail>
							</Grid>
						)}
					</Grid>
				</div>
			</Dashboard>
		</>
	);
};

export default Profile;

const smiFrApiURL = process.env.REACT_APP_SMI_FR_API;
const resources = `${smiFrApiURL}/resources/`;

const DownloadLink = props => {
	const { children, href, download, uploaded } = props;

	return (
		<Detail>
			<Link href={href} download={download} underline='hover'>
				{children}
				{'	'}
				{uploaded ? '✔' : ''}
			</Link>
		</Detail>
	);
};

const detailsContainerStyle = {
	padding: '10px 25px',
	margin: '5px 12px'
};

const Detail = props => {
	let { children, label, value, ...other } = props;
	return (
		<Grid item xs={12} {...other}>
			<H1 variant='body1' align='left'>
				{children}
			</H1>
		</Grid>
	);
};
