import app from './Realm';

//used to retrieve the user's vendor id, code, name.
export const getVendor = async id => {
	try {
		const vendor = await app.currentUser.functions.getVendor({ id: id });
		if (vendor.code) {
			return { vendor: vendor };
		} else {
			return { error: 'No Match Found' };
		}
	} catch (err) {
		return { error: `Failed to retrieve Vendor Details: ${err}` };
	}
};

//------------------------------ Home Page Data ------------------------------//

// used to retrieve data related to open work orders for pie graph on home page.
export const getOpenServicesData = async vendorCode => {
	try {
		const data = await app.currentUser.functions.getOpenServicesData({
			vendorCode
		});
		return { data: data };
	} catch (err) {
		return { error: `Failed to retrieve Open Services Data: ${err}` };
	}
};

// used to get count on all work orders with no schedule for to do list.
export const countWOsWithNoSchedule = async vendorCode => {
	try {
		const data = await app.currentUser.functions.countWOsWithNoSchedule({
			vendorCode
		});
		return { data: data[0].needSchedule };
	} catch (err) {
		return { error: 'Failed to retrieve work orders with no schedule' };
	}
};

// used to get count on all past due work orders for to do list.
export const countPastDueWOs = async vendorCode => {
	try {
		const data = await app.currentUser.functions.countPastDueWOs({
			vendorCode
		});
		return { data: data[0].pastDue };
	} catch (err) {
		return { error: 'Failed to retrieve past due WOs' };
	}
};

//! currently not being used
export const getOpenWOCount = async vendorCode => {
	try {
		const data = await app.currentUser.functions.getOpenWOCount({ vendorCode });
		return { data: data };
	} catch (err) {
		return { error: `Failed to retrieve Open WO Count: ${err}` };
	}
};

//---------------------------- Work Orders Data -----------------------------//

//used to retrieve all work orders for a vendor.
export const getAllWOs = async vendorCode => {
	try {
		const data = await app.currentUser.functions.getAllWOs({ vendorCode });
		return { data: data };
	} catch (err) {
		return { error: `Failed to retrieve All WO Data: ${err}` };
	}
};

// used to retrieve open work orders for a vendor.
export const getOpenWOs = async vendorCode => {
	try {
		const data = await app.currentUser.functions.getOpenWOs({ vendorCode });
		return { data: data };
	} catch (err) {
		return { error: `Failed to retrieve Open WO Data: ${err}` };
	}
};

// used to retrieve past due work orders for a vendor.
export const getPastDueWOs = async vendorCode => {
	try {
		const data = await app.currentUser.functions.getPastDueWOs({ vendorCode });
		return { data: data };
	} catch (err) {
		return { error: `Failed to retrieve past due WO Data: ${err}` };
	}
};

// used to retrieve unscheduled work orders for a vendor.
export const getUnscheduledWOs = async vendorCode => {
	try {
		const data = await app.currentUser.functions.getUnscheduledWOs({
			vendorCode
		});
		return { data: data };
	} catch (err) {
		return { error: `Failed to retrieve unscheduled WO Data: ${err}` };
	}
};


//---------------------------- Work Orders Update ----------------------------//

export const scheduleWO = async payload => {
	try {
		const data = await app.currentUser.functions.scheduleWO(payload);
		return { data };
	} catch (err) {
		return { error: 'Failed to update WO:' };
	}
};

export const closeWO = async payload => {
	try {
		const data = await app.currentUser.functions.closeWO(payload);
		return { data: data };
	} catch (err) {
		return { error: 'Failed to update WO:' };
	}
};

//-------------------------- Request For Price Data --------------------------//

//used to retrieve all RFPS for a vendor.
export const getRFPs = async vendorCode => {
	try {
		const data = await app.currentUser.functions.getRFPs({
			vendorCode
		});
		return { data: data };
	} catch (err) {
		return { error: 'Failed to retrieve RFPS:' };
	}
};

//------------------------- Request For Price Update -------------------------//

// used to submit/update a vendor's RFP.
export const updateRFPs = async ({
	RecId,
	Comment,
	CostPerService,
	Status
}) => {
	try {
		const data = await app.currentUser.functions.updateRFPs({
			RecId,
			Comment,
			CostPerService,
			Status
		});
		return { data: data };
	} catch (err) {
		return { error: 'Failed to update RFPS:' };
	}
};

//---------------------------------- Notes -----------------------------------//

export const newNote = async payload => {
	try {
		const data = await app.currentUser.functions.newNote(payload);
		return data;
	} catch (err) {
		return { Error: 'Failed to Send New Note.' };
	}
};

//---------------------------------- Notes -----------------------------------//

export const trackUploads = async payload => {
	try {
		const data = await app.currentUser.functions.trackUploads(payload);
		return data;
	} catch (err) {
		return { Error: 'Failed to track upload.' };
	}
};

export const trackCrewUploads = async payload => {
	try {
		const data = await app.currentUser.functions.trackCrewUploads(payload);
		return data;
	} catch (err) {
		return { Error: 'Failed to track upload.' };
	}
};

export const insertUsers = async file => {
	try {
		const data = await app.currentUser.functions.insertUsers(file);
		return { data };
	} catch (error) {
		return { error };
	}
};

//---------------------------------- Profile ---------------------------------//

export const vendorCompanyDetails = async vendorId => {
	try {
		const data = await app.currentUser.functions.vendorCompanyDetails(vendorId);
		return { data };
	} catch (error) {
		return { error };
	}
};

export const crewUpdate = async payload => {
	try {
		const data = await app.currentUser.functions.crewUpdate(payload);
		return { data };
	} catch (error) {
		return { error };
	}
};
