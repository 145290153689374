import React from 'react'
import Dashboard from '../../components/dashboard/Dashboard.js';
import { dashboardStyles } from '../../components/dashboard/Dashboard.style.js';

const EmployeeMainPage = props  => {

 


    const classes = dashboardStyles();
    return (
        <>
            <Dashboard
                {...props}
                classes={classes}
            >
            
            </Dashboard>
        </>
    )
}

export default EmployeeMainPage
