import { useState, useCallback } from 'react';
import { Route } from 'react-router-dom';
import {
	inboundTransactions,
	outboundTransactions
} from '../services/realm/employees';
import Transactions from '../pages/employee/Transactions';
import { inboundTransCols, outboundTransCols } from '../pages/employee/columns';

const TransactionRoutes = props => {
	const { path } = props;
	//---------------------------- Transactions ------------------------------//
	const [inboundTrans, setInboundTrans] = useState([]);
	const [outboundTrans, setOutboundTrans] = useState([]);

	const handleInboundTrans = useCallback(async () => {
		const { data, error } = await inboundTransactions();
		if (!error) {
			setInboundTrans(data);
		} else {
			console.error(error);
		}
	}, []);

	const handleOutboundTrans = useCallback(async () => {
		const { data, error } = await outboundTransactions();
		if (!error) {
			setOutboundTrans(data);
		} else {
			console.error(error);
		}
	}, []);

	const toolBar = {
		canExportToExcel: true
	};

	return (
		<>
			<Route
				exact
				path={`${path}/inbound`}
				render={p => (
					<Transactions
						{...p}
						{...props}
						{...toolBar}
						pageTitle='To Portal'
						data={inboundTrans}
						columns={inboundTransCols}
						handleData={handleInboundTrans}
					/>
				)}
			/>
			<Route
				exact
				path={`${path}/outbound`}
				render={p => (
					<Transactions
						{...p}
						{...props}
						{...toolBar}
						pageTitle='From Portal'
						data={outboundTrans}
						columns={outboundTransCols}
						handleData={handleOutboundTrans}
					/>
				)}
			/>
		</>
	);
};

export default TransactionRoutes;
