import TransactionDetails from './components/TransactionDetails';
import { civilianTime } from '../../../../helpers/stringFormat';

const outbound = [
	// {
	// 	Header: 'Id',
	// 	accessor: 'UserRecId',
	// 	disableFilters: true,
	// 	width: 100
	// },
	{
		Header: 'Crew',
		accessor: 'CrewCode',
		disableFilters: true,
		width: 100
	},
	{
		Header: 'User',
		accessor: 'UserID',
		disableFilters: true,
		width: 100
	},
	{
		Header: 'Transaction',
		columns: [
			{
				Header: 'Date',
				accessor: 'TransDate',
				disableFilters: true,
				width: 100,
				textAlign: 'center'
			},
			{
				Header: 'Time',
				accessor: 'TransTime',
				disableFilters: true,
				width: 100,
				textAlign: 'center',
				Cell: ({ value }) => civilianTime(value)
			},
			{
				Header: 'Type',
				accessor: 'TransType',
				disableFilters: true,
				width: 100,
				textAlign: 'center'
			},
			{
				Header: 'Details',
				accessor: 'TransDetails',
				disableFilters: true,
				width: 200,
				textAlign: 'left',
				Cell: ({ cell }) => TransactionDetails(cell),
			}
		]
	},
	{
		Header: 'Pulled By SMI DB',
		columns: [
			{
				Header: 'Date',
				accessor: 'PulledDate',
				disableFilters: true,
				width: 100,
				textAlign: 'center'
			},
			{
				Header: 'Time',
				accessor: 'PulledTime',
				disableFilters: true,
				width: 100,
				textAlign: 'center',
				Cell: ({ value }) => civilianTime(value)
			}
		]
	}
];

export default outbound;
