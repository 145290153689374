import { useState } from 'react';
import clsx from 'clsx';
import { Toolbar, Typography } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import GlobalFilter from './GlobalFilter';
import { Excel } from './Downloads';
import Columns from './Columns';
import Filters, { ColumnFilters } from './Filters';
import { WOScheduleDateUpdate } from './UpdateWOScheduleDate';

const useToolbarStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(1)
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
			  },
	spacer: {
		flex: '1 1 100%'
	},
	actions: {
		color: theme.palette.text.secondary
	},
	title: {
		flex: '0 0 auto'
	}
}));

const TableToolbar = props => {
	const {
		canFilterRows,
		canHideColumns,
		canExportToExcel,
		canGlobalFilter,
		canScheduleDateUpdate,
		tableTitle,
		numSelected,

		rows
	} = props;
	const [filtersOpen, setFiltersOpen] = useState(false);
	const data = rows.map(({ values }) => values);

	const classes = useToolbarStyles();
	return (
		<>
			<Toolbar
				className={clsx(classes.root, {
					[classes.highlight]: numSelected > 0
				})}
			>
				<Typography className={classes.title} variant='h6' id='tableTitle'>
					{tableTitle}
				</Typography>

				<div className={classes.spacer} />
				{canScheduleDateUpdate && <WOScheduleDateUpdate {...props} />}
				{canGlobalFilter && <GlobalFilter {...props} />}
				{canExportToExcel && <Excel {...props} data={data} />}
				{canHideColumns && <Columns {...props} />}
				{canFilterRows && (
					<Filters {...props} setFiltersOpen={setFiltersOpen} />
				)}
			</Toolbar>
			{filtersOpen && (
				<div>
					<ColumnFilters {...props} />
				</div>
			)}
		</>
	);
};

export default TableToolbar;
